import { transfer, trx, konto, createKontoID } from "./Log";
import { cardOutline, analytics, fileTrayStackedOutline, libraryOutline, shapesOutline, briefcaseOutline, walkOutline, bulbOutline, fastFoodOutline, airplaneOutline, storefrontOutline, cashOutline } from "ionicons/icons";
import { STR_Expense, STR_FooterInvoice, STR_FooterInvoiceDue, STR_FooterReceipt } from "../lang/en";
import { user } from "../components/AppContextProvider";
import { kurir } from "./DataFunctions";

export const MEKUYAREK = "BCA 650.013.5121 a.n. Mulyanti";
export const BIASREK = "BCA 7843.878.999 a.n. PT Media Garuda Kontraktor";
export const MEGAKONREK = "BCA 7843.878.999 a.n. PT Media Garuda Kontraktor";

export const MEKUYAID = "mekuya";
export const SHOPEE_EXPORT = "sipbuyerid";

export const ROOT = "King";
export const OFFLINE = "Poco";
export const DEV = "admin";
export const DEVP = "Mm207372.";
export const USER_SAVED = 'lastUserLogin';


export const DROPSHIPTAG = '*';

const DEFAULTCAT = '';
export const ALL = 'ALL';
export const CATBG = 'BG';
export const CATBACC = 'BGACC';
export const CATXDD = 'XDDESIGN';
export const CATR = 'RANDOM';
export const CATHM = 'HM';
export const CATBABY = 'BABY';
export const CATDE = 'DE';
export const CATBIAS = 'BIAS';
export const CATMEDIS = "MEDIS";
export const CATCHX = "DICE";

export const TOKOPEDIA = "tokopedia";
export const SHOPEE = "shopee";
export const BUKALAPAK = "bukalapak";
export const BIAS = "Bias";
export const MEGAKON = "MEGAKON";
export const LAZADA = "Lazada";

export const DEFAULTSHOP = ["mekuya", BIAS, MEGAKON];
//export const shopkonto = [TOKOPEDIA, SHOPEE, BUKALAPAK, BIAS, LAZADA, "TikTok"];

export const RUPIAH = 0;
export const EURO = 1;
export const RUPIAHZ = 2;

var RP = new Intl.NumberFormat('id-ID', {
  style: 'currency',
  currency: 'IDR',
  maximumFractionDigits: 2,
  minimumFractionDigits: 0
});

var EUR = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
  maximumFractionDigits: 2,
  minimumFractionDigits: 0
});

export function formatCurrency(currency: number, amount: number) {
  if (currency !== RUPIAHZ && (!amount || amount === 0)) return '';
  else if (currency === EURO) {
    return EUR.format(amount);
  }
  else {
    return RP.format(amount);
  }
}

const itemKeys = ["id", "Unit", "ProductName", "Quantity", "PurchasePrice", "SalePrice", "Reserve", "AdditionalSalesPrices", "Info", "Category"];

export interface courier {
  _id: string;
  startsWith: string[];
  cashless: boolean;
}

export interface Invoice {
  _id: string;
  logo: Blob;
  company: Person;
  website: string;
  telp: string;
  bankaccount: string;
  dateDue: string;
  customer: Person;
  note: string;
  footnote: string[];
  sale: Sale;
}

export interface Note {
  _id: string;
  ionitem: JSX.Element[];
  img: Blob;
  title: string;
  subtitle: string;
  content: string;
  ionfooter: JSX.Element
}

export function initNote() {
  return {
    _id: '',
    ionitem: null as unknown as JSX.Element[],
    img: null as unknown as Blob,
    title: '',
    subtitle: '',
    content: '',
    ionfooter: null as unknown as JSX.Element
  };
}

export function initInvoice() {
  return {
    _id: '',
    logo: null as unknown as Blob,
    company: initPerson(),
    website: '',
    telp: '',
    bankaccount: '',
    dateDue: '',
    customer: initPerson(),
    note: '',
    footnote: [STR_FooterReceipt, STR_FooterInvoiceDue],
    sale: initSale()
  };
}

export interface Gheim {
  _id: string;
  loc: string;
  userid: string;
  pwd: string;
  email: string;
  telp: string;
  info: string
}

export function initGheim() {
  return { _id: '', loc: '', userid: '', pwd: '', email: '', telp: '', info: '' };
}

export function cloneGheim(item: Gheim) {
  return { _id: '', loc: item.loc, userid: '', pwd: '', email: item.email, telp: item.telp, info: item.info };
}

export function initKurir() {
  return [{
    _id: "AnterAja",
    startsWith: [],
    cashless: true
  }, {
    _id: "SiCepat",
    startsWith: [],
    cashless: true
  }, {
    _id: "J&T",
    startsWith: [],
    cashless: true
  }, {
    _id: "JNE",
    startsWith: [],
    cashless: false
  }, {
    _id: "Wahana",
    startsWith: [],
    cashless: false
  }, {
    _id: "Grab",
    startsWith: [],
    cashless: true
  }, {
    _id: "Gojek",
    startsWith: [],
    cashless: true
  }, {
    _id: "Pos",
    startsWith: [],
    cashless: false
  }, {
    _id: "Ninja",
    startsWith: [],
    cashless: true
  }, {
    _id: "TIKI",
    startsWith: [],
    cashless: false
  }, {
    _id: "LionParcel",
    startsWith: [],
    cashless: true
  }, {
    _id: "ID Express",
    startsWith: [],
    cashless: true
  }];
}

export function setKurirFromResi(resi: string, sale: Sale) {
  for (var i = 0; i < kurir.length; i++) {
    if (!kurir[i].startsWith) continue;
    for (var j = 0; j < kurir[i].startsWith.length; j++) {
      if (resi.startsWith(kurir[i].startsWith[j])) return sale.shipment = kurir[i]._id;
    }
  }
  return sale.shipment = '';
}
/*
  if (resi.startsWith('JP') || resi.startsWith('TJNT-') || resi.startsWith('JB') || resi.startsWith('JX') || resi.startsWith('JJ')) sale.shipment = "J&T";
  else if (resi.startsWith('CM') || resi.startsWith('TJR') || resi.startsWith('JT') || resi.startsWith('JY') || resi.startsWith('TJE') || resi.startsWith('TJX') || resi.startsWith('TJC') || resi.startsWith('TLJE') || resi.startsWith("TLJX") || resi.startsWith("TLJR")) sale.shipment = "JNE";
  else if (resi.startsWith('TWHN-')) sale.shipment = "Wahana";
  else if (resi.startsWith('TKSC-')) sale.shipment = "SiCepat";
  else if (resi.startsWith('TKLP-')) sale.shipment = "LionParcel";
  else if (resi.startsWith('TPXL-')) sale.shipment = "Paxel";
  else if (resi.startsWith('SHPE')) sale.shipment = "Pos Reguler";
  else if (resi.startsWith('SHP') || resi.startsWith('TKNX')) sale.shipment = "Ninja";
  else if (resi.startsWith('GK')) sale.shipment = "Gojek";
  else if (resi.startsWith('IDS')) sale.shipment = "ID Express";
  else if (resi.startsWith('SPXID')) sale.shipment = "Shopee Express";
  else if (resi.startsWith('FAFT')) sale.shipment = "Alfatrex";
  else if (resi.startsWith('SP')) sale.shipment = 'Indopaket';
  else if (resi.startsWith('66')) sale.shipment = "TIKI";
  else if (resi.startsWith('222')) sale.shipment = "REX-10";
  else if (resi.startsWith('LX')) sale.shipment = 'LEX ID';
  else if (resi.startsWith('ID') && !isNumeric(resi[resi.length - 1])) sale.shipment = "SPX Instant";
  else if ((resi.startsWith('ID') && resi.length === 15) || resi.startsWith("IN-") || !isNumeric(resi.substring(resi.length - 1, resi.length)) && !(resi.startsWith('TK')) || (resi.startsWith("SD-"))) sale.shipment = "Grab";
  else if (resi.length === 12) sale.shipment = "SiCepat";
  else sale.shipment = "AnterAja"; */

export interface UserL {
  _id: string;
  pwd: string;
  info: string;
  isMGProvided: string[],
  profitMG: number,
  books: [{ name: string, cats: string[] }],
  savedList: [{ name: string, list: Sale[] }],
  shopkonto: string[],
}

export interface Sale {
  _id: string;
  items: SalesItem[];
  date: string;
  shop: string;
  client: Person;
  total: number;
  fee: number;
  discount: number;
  info: string;
  paid: string | null;
  profit: number;
  shipment: string;
  shipmentNo: string;
  shipping: number;
  dropshipper: string;
  print: boolean;
  safepack: boolean;
  dp: number;
  note: string;
  status: string;
  staff: string;
  cod: number;
}

export interface Shop {
  _id: string;
  currency: number;
  name: string;
  fee: number;
  balance: number;
  trx: trx[];
  //in: trx[];
  //out: trx[];
  transfer: transfer[];
}

export interface SalesItem {
  item: Item;
  quantity: number;
  price: number;
  profit: number;
}

export interface Item {
  _id: string;
  ProductName: string;
  Quantity: number;
  PurchasePrice: number;
  SalePrice: number;
  Reserve: number;
  AdditionalSalesPrices: string;
  Info: string;
  Category: string;
  Image: Blob;
  MSRPPrice: number;
  description: string;
  dimensionP: number;
  dimensionL: number;
  dimensionT: number;
  weight: number;
}

export interface Person {
  _id: string;
  name: string;
  telp: string;
  address: string;
  info: string;
  staff: string;
}

export interface Category {
  _id: string;
  name: string;
  img: string;
  count: number;
}

export function initItem() {
  return {
    _id: '',
    ProductName: '',
    Quantity: 0,
    PurchasePrice: 0,
    SalePrice: 0,
    Reserve: 0,
    AdditionalSalesPrices: '',
    Info: '',
    Category: DEFAULTCAT,
    Image: null as unknown as Blob,
    MSRPPrice: 0,
    description: '',
    dimensionP: 0,
    dimensionL: 0,
    dimensionT: 0,
    weight: 0
  };
}

export function cloneItem(item: Item) {
  return {
    _id: item._id + 'copy',
    ProductName: item.ProductName + ' copy',
    Quantity: item.Quantity,
    PurchasePrice: item.PurchasePrice,
    SalePrice: item.SalePrice,
    Reserve: item.Reserve,
    AdditionalSalesPrices: item.AdditionalSalesPrices,
    Info: item.Info,
    Category: item.Category,
    Image: item.Image,
    MSRPPrice: item.MSRPPrice,
    description: item.description,
    dimensionP: item.dimensionP,
    dimensionL: item.dimensionL,
    dimensionT: item.dimensionT,
    weight: item.weight
  };
}

export function initSale(): Sale {
  return {
    _id: '',
    items: [] as SalesItem[],
    date: (new Date()).toISOString(),
    shop: "mekuya",
    client: initPerson(),
    total: 0,
    fee: 0,
    discount: 0,
    info: '',
    paid: null,
    profit: 0,
    shipment: '',
    shipmentNo: '',
    shipping: 0,
    dropshipper: '',
    print: true,
    safepack: false,
    dp: 0,
    note: '',
    status: "Not Paid",
    staff: '',
    cod: 0
  }
}

function cloneSalesItem(sitem: SalesItem) {
  return {
    item: sitem.item,
    quantity: sitem.quantity,
    price: sitem.price,
    profit: sitem.profit
  }
}

function cloneArray(a: SalesItem[], b: SalesItem[]) {
  for (var i = 0; i < a.length; i++) {
    b.push(cloneSalesItem(a[i]));
  }
  return b;
}

export function cloneSale(sale: Sale): Sale {
  return {
    _id: sale._id,
    items: cloneArray(sale.items, []),
    date: sale.date,
    shop: sale.shop,
    client: sale.client,
    total: sale.total,
    fee: sale.fee,
    discount: sale.discount,
    info: sale.info,
    paid: sale.paid,
    profit: sale.profit,
    shipment: sale.shipment,
    shipmentNo: sale.shipmentNo,
    shipping: sale.shipping,
    dropshipper: sale.dropshipper,
    print: true,
    safepack: sale.safepack,
    dp: sale.dp,
    note: sale.note,
    status: sale.status,
    staff: user.staff,
    cod: sale.cod
  }
}

export function initPerson() {
  return {
    _id: '',
    name: '',
    telp: '',
    address: '',
    info: '',
    staff: '',
  }
}

export function initShops(): Shop[] {
  return [
    {
      _id: DEFAULTSHOP[0],
      currency: RUPIAH,
      name: "Mekuya Games",
      fee: 0,
      balance: 0,
      trx: [],
      //in: [],
      //out: [],
      transfer: []
    }, {
      _id: TOKOPEDIA,
      currency: RUPIAH,
      name: "Tokopedia",
      fee: 0.01,
      balance: 0,
      trx: [],
      //in: [],
      //out: [],
      transfer: []
    }, {
      _id: SHOPEE,
      currency: RUPIAH,
      name: "Shopee",
      fee: 0.01,
      balance: 0,
      trx: [],
      //in: [],
      //out: [],
      transfer: []
    }, {
      _id: BUKALAPAK,
      currency: RUPIAH,
      name: "Bukalapak",
      fee: 0.03,
      balance: 0,
      trx: [],
      //in: [],
      //out: [],
      transfer: []
    }, {
      _id: BIAS,
      currency: RUPIAH,
      name: BIAS,
      fee: 0,
      balance: 0,
      trx: [],
      //in: [],
      //out: [],
      transfer: []
    }, {
      _id: MEGAKON,
      currency: RUPIAH,
      name: MEGAKON,
      fee: 0,
      balance: 0,
      trx: [],
      //in: [],
      //out: [],
      transfer: []
    }, {
      _id: "ebay",
      currency: EURO,
      name: "ebay",
      fee: 0.1,
      balance: 0,
      trx: [],
      //in: [],
      //out: [],
      transfer: []
    }];
}

export function initKonten(): konto[] {
  return [
    {
      _id: createKontoID(STR_Expense),
      currency: RUPIAH,
      name: STR_Expense,
      icon: cashOutline,
      balance: 0,
      trx: [],
      //in: [],
      //out: [],
      transfer: [],
      calculate: false
    },
    {
      _id: createKontoID("bca"),
      currency: RUPIAH,
      name: "BCA",
      icon: cardOutline,
      balance: 0,
      trx: [],
      //in: [],
      //out: [],
      transfer: [],
      calculate: true
    },
    {
      _id: createKontoID("koins"),
      currency: RUPIAH,
      name: "Koins",
      icon: analytics,
      balance: 0,
      trx: [],
      //in: [],
      //out: [],
      transfer: [],
      calculate: true
    },
    {
      _id: createKontoID("abeng"),
      currency: RUPIAH,
      name: "Abeng - Mekuya",
      icon: storefrontOutline,
      balance: 0,
      trx: [],
      //in: [],
      //out: [],
      transfer: [],
      calculate: true
    },
    {
      _id: createKontoID('PT Media Garuda Kontraktor'),
      currency: RUPIAH,
      name: "Bias BCA 7841.6171.89 - PT Media Garuda Kontraktor",
      icon: cardOutline,
      balance: 0,
      trx: [],
      //in: [],
      //out: [],
      transfer: [],
      calculate: true
    }
  ];
}

export function initCategory() {
  return [
    {
      _id: ALL,
      name: "ALL",
      img: fileTrayStackedOutline,
      count: 0
    },
    {
      _id: CATBG,
      name: "Board Game",
      img: libraryOutline,
      count: 0
    },
    {
      _id: CATBACC,
      name: "Boardgame Accessories",
      img: shapesOutline,
      count: 0
    },
    {
      _id: CATXDD,
      name: "XD Design",
      img: briefcaseOutline,
      count: 0
    },
    {
      _id: CATR,
      name: "Random",
      img: walkOutline,
      count: 0
    },
    {
      _id: CATHM,
      name: "Produksi",
      img: bulbOutline,
      count: 0
    },
    {
      _id: CATBABY,
      name: "Baby Stuff",
      img: fastFoodOutline,
      count: 0
    },
    {
      _id: CATDE,
      name: "DE",
      img: airplaneOutline,
      count: 0
    }
  ];
}
