import React from 'react';
import { IonList } from '@ionic/react';
import { Sale, SalesItem } from '../functions/DataModel';
import SalesItemCard from './SalesItemCard';

interface ContainerProps {
    mode : number;
    sales: SalesItem[];
    sale: Sale
}

export const SalesList: React.FC<ContainerProps> = ({ mode, sales, sale }) => {

   return (
        <IonList>
            {sales.map((item, idx) => <SalesItemCard key={idx} mode={mode} salesItem={item} sale={sale} />)}
        </IonList>
    );
}