import { IonHeader, IonToolbar, IonButton, IonIcon, IonTitle, IonContent, IonList, IonLabel, IonSegment, IonSegmentButton, IonFooter, IonAlert, IonItem, IonInput, IonAvatar, IonModal, IonCheckbox, IonSelect, IonSelectOption } from "@ionic/react"
import React, { useState, useRef } from "react";
import { close, printOutline, trash, add, checkboxOutline, cashOutline, analytics, cardOutline, logoEuro, logoUsd, manOutline, walletOutline, womanOutline, storefrontOutline, constructOutline, mailOutline, gitCompareOutline, bagCheckOutline, listOutline, alertOutline, pencil, checkmarkSharp, bookOutline, imagesOutline, fishOutline, iceCreamOutline, diceSharp, calendarOutline, bicycleOutline, airplaneOutline, bandageOutline, americanFootballOutline, atOutline, beerOutline, bedOutline, cameraOutline, bulbOutline, colorPaletteOutline, cutOutline, fastFoodOutline, flaskOutline, gameControllerOutline, giftOutline, golfOutline, hourglassOutline, keyOutline, trailSignOutline, shirtOutline, partlySunnyOutline, rainyOutline, cloudyNightOutline, sunnyOutline, alarmOutline, trashOutline, carSport, cartOutline, pawOutline, leafOutline, peopleOutline, musicalNotesOutline, heartOutline, starOutline, snow, flowerOutline, infiniteOutline, libraryOutline, medicalOutline, ribbonOutline, umbrellaOutline, walkOutline, happyOutline, sad, calculator, hammerOutline, restaurantOutline, buildOutline, call, heart, briefcaseSharp, languageOutline, handLeftOutline, handRightOutline, accessibility, briefcaseOutline, fileTrayStackedOutline, shapesOutline } from "ionicons/icons";
import SalesCard, { REMOVE } from "./SalesCard";
import { STR_Empty, STR_with, STR_Quantity, STR_without, STR_RemoveSaleMsg, STR_CANCEL, STR_OK, STR_RemovePersonMsg, STR_Category, STR_Remove, STR_Name, STR_tel, STR_Address, STR_Info, STR_UserName, STR_addPerson, STR_addCategory, STR_print, STR_Labels, STR_amount, STR_Account, STR_Add, STR_EUR, STR_RP, STR_Expense, STR_AddPO, STR_clear, STR_All, STR_mprint, STR_NeedPrintAll, STR_User, STR_Edit, STR_ConfirmDelete, STR_Pwd8, STR_Pwd, STR_New, STR_LoginUser, STR_ErrorNull, STR_LoginPwd, STR_UserNameNotAvailable } from "../lang/en";
import PersonCard from "./PersonCard";
import { LIST_SALES, LIST_PURCHASES, LIST_PERSON, LIST_CATALOGQ, LIST_CATALOG, removePerson, LIST_CAT, categories, removeCategory, removeSale, containsClient, saveClient, addCategory, getTitle, getList, toPrint, LIST_EXPENSE, printDateS, LIST_KONTO, printDateAvatar, LIST_PO, removePO, addPO, printSalesDone, getListShop, clearPO, isAdmin, getUserTrx, toggleMPSales, salesHistory, mprint, clearMPrint, toggleMPrint, toSList, LIST_BIASSALES, po, biassalesHistory, LIST_KURIR, sale, inventoryID, LIST_INVENTORY, makeBiasCategory, getListShopSale, isBias, setAll, printDateMonth, LIST_USER, getUser, MD5, removeUser, addUser, shops, getCurrentUserShopKonto, togglecalculateshop } from "../functions/DataFunctions";
import ItemCard from "./ItemCard";
import { Sale, Person, Category, initPerson, Item, formatCurrency, RUPIAH, UserL, ROOT, OFFLINE, BIAS, TOKOPEDIA, SHOPEE, BUKALAPAK, LAZADA } from "../functions/DataModel";
import { updateAccount, updateCategory, updateUser } from "../functions/LocalStorage";
import { generateCatalog, generateShipmentLabel, printcontent, printHeader, printList, printMultipleLabels } from "../functions/Output";
import { List, updateList } from "./List";
import { addExpense, deleteKonto, EXPENSE, isdeletableKonto, konto, profitBG, setKontoCalc, trx } from "../functions/Log";
import LogModal from "./LogModal";
import { user } from "./AppContextProvider";
import { updateTransactionTab } from "../pages/Tab2";
import { userNameNotAvailable, users } from "./Login";

interface ContainerProps {
    type: number;
    shop: string
}

var toRemove: null | Sale | Person | Category | konto = null;

export function setToRemove(obj: any) {
    toRemove = obj;
}

export const MONTHRANGE = getMonthRange();

function getMonthRange() {
    let range: Date[] = [];
    for (var i = 0; i < 12; i++) {
        let n = new Date();
        n.setMonth(n.getMonth() - i);
        range.push(n);
    }
    return range;
}

const ListModal: React.FC<ContainerProps> = ({ type, shop }) => {

    const icons = [cardOutline, cashOutline, walletOutline, manOutline, womanOutline, logoUsd, logoEuro, analytics, storefrontOutline, constructOutline, mailOutline, gitCompareOutline, fishOutline, iceCreamOutline, diceSharp, calendarOutline, bicycleOutline, airplaneOutline, bandageOutline, americanFootballOutline, atOutline, beerOutline, bedOutline, cameraOutline, bulbOutline, bookOutline, colorPaletteOutline, cutOutline, fastFoodOutline, flaskOutline, gameControllerOutline, giftOutline, golfOutline, hourglassOutline, keyOutline, trailSignOutline, shirtOutline, partlySunnyOutline, rainyOutline, cloudyNightOutline, sunnyOutline, alarmOutline, trashOutline, carSport, cartOutline, pawOutline, leafOutline, peopleOutline, musicalNotesOutline, heart, heartOutline, starOutline, snow, flowerOutline, medicalOutline, infiniteOutline, libraryOutline, ribbonOutline, umbrellaOutline, walkOutline, happyOutline, sad, calculator, hammerOutline, restaurantOutline, buildOutline, call, briefcaseSharp, languageOutline, handLeftOutline, handRightOutline, accessibility, briefcaseOutline, fileTrayStackedOutline, shapesOutline];
    const curr = [STR_RP, STR_EUR];
    const ICON = 0;
    const CURRENCY = 1;

    const [reload, setReload] = useState(false);

    const [wStock, setWStock] = useState('0');
    const updateListB = useRef(null);
    const [showYNAlert, setShowYNAlert] = useState({ user: getUser(user.staff), todo: -1 });
    const [show, setShow] = useState(undefined as trx | undefined);
    const relModal = useRef(null);
    const [iconPicker, setIconPicker] = useState({ konto: null as konto | null, cat: null as Category | null, event: undefined as undefined | Event, type: ICON });
    const [withFoto, setWithFoto] = useState(false);
    const [withdetails, setWithdetails] = useState(false);
    const [range, setRange] = useState(0);

    function closeModal(e: React.MouseEvent) {
        const modal = e.currentTarget.parentElement?.parentElement?.parentElement?.parentElement?.parentElement;
        (modal as HTMLIonModalElement).dismiss();
        updateTransactionTab();
    }

    function showEmpty(list: Sale[] | Person[] | Item[] | Category[] | trx[] | konto[] | UserL[]) {
        return list.length > 0 ? '' : <div className="placeholder">{STR_Empty}</div>;
    }

    function clickTrx(e: React.MouseEvent<HTMLIonItemElement, MouseEvent>, trx: trx) {
        e.stopPropagation();
        setShow(trx);
    }

    function fill(mitem: Item | Sale | Person | trx | UserL, idx: number) {
        switch (type) {
            case LIST_SALES: return <SalesCard key={idx} sale={mitem as Sale} type={REMOVE} rel={updateListB} po={false} />;
            case LIST_BIASSALES: return <SalesCard key={idx} sale={mitem as Sale} type={REMOVE} rel={updateListB} po={false} />;
            case LIST_PURCHASES: return <SalesCard key={idx} sale={mitem as Sale} type={REMOVE} rel={updateListB} po={false} />;
            case LIST_PO: return <SalesCard key={idx} sale={mitem as Sale} type={REMOVE} rel={updateListB} po={true} />;
            case LIST_EXPENSE: {
                var trx = mitem as trx;
                return (
                    <IonItem key={idx} button onClick={e => clickTrx(e, trx)}>
                        <IonAvatar slot="start">
                            {printDateAvatar(trx.date)}
                        </IonAvatar>
                        <IonLabel className="fleft fulltext">
                            <h2>{trx.note}</h2>
                            <h3>{printDateS(trx.date)}</h3>
                        </IonLabel>
                        <IonLabel className={trx.type > 0 ? "fright available" : "fright warning"}>{formatCurrency(RUPIAH, trx.amount)}</IonLabel>
                    </IonItem>
                );
            }
            case LIST_PERSON: return <PersonCard key={idx} person={mitem as Person} rel={updateListB} sale={undefined} />;
            case LIST_CATALOGQ: {
                let item = mitem as Item;
                return <ItemCard key={idx} uRef={null} item={item} isShowing={item.Quantity - item.Reserve === 0} type={LIST_CATALOGQ} sale={sale} />;
            }
            case LIST_CATALOG: {
                let item = mitem as Item;
                return <ItemCard key={idx} uRef={null} item={item} isShowing={wStock === '0' ? item.Quantity - item.Reserve > 0 : true} type={wStock === '0' ? LIST_CATALOG : LIST_CATALOGQ} sale={sale} />;
            }
            case LIST_USER: {
                var user = mitem as UserL;
                return (
                    <IonItem key={idx}>
                        <IonButton slot="end" hidden={user._id === ROOT || user._id === OFFLINE || user._id === BIAS} className="endButton no-print" onClick={() => setShowYNAlert({ user: user, todo: LIST_USER + 100 })}><IonIcon icon={pencil} /></IonButton>
                        <IonButton slot="end" hidden={user._id === ROOT || user._id === OFFLINE || user._id === BIAS} className="endButton no-print" onClick={() => setShowYNAlert({ user: user, todo: LIST_USER + 500 })}><IonIcon icon={trash} /></IonButton>
                        <IonAvatar slot="start">
                            {user._id === ROOT ? '*' : user._id === OFFLINE ? 'OFF' : user._id === BIAS ? <img alt={BIAS.toUpperCase()} src={"assets/img/" + BIAS + ".png"}></img> : ''}
                        </IonAvatar>
                        <IonLabel className="fleft fulltext">
                            <h2>{user._id}</h2>
                            <h3>{user.info}</h3>
                        </IonLabel>
                        <IonLabel className={"fright danger"}>{user.pwd}</IonLabel>
                    </IonItem>
                );
            }
            default: return [];
        }
    }

    function changeCatName(cat: Category, button: HTMLIonButtonElement) {
        let input = button.previousElementSibling as HTMLIonInputElement;
        if (input.disabled) {
            input.disabled = false;
            let ic = button.firstElementChild as HTMLIonIconElement;
            ic.icon = checkmarkSharp;
            ic.color = "success";
            input.focus();
        }
        else {
            cat.name = input.value as string;
            updateCategory(cat);
            input.disabled = true;
            let ic = button.firstElementChild as HTMLIonIconElement;
            ic.icon = pencil;
            ic.color = "white";
        }
    }

    function changeKontoName(konto: konto, button: HTMLIonButtonElement) {
        let input = button.previousElementSibling as HTMLIonInputElement;
        if (input.disabled) {
            input.disabled = false;
            let ic = button.firstElementChild as HTMLIonIconElement;
            ic.icon = checkmarkSharp;
            ic.color = "success";
            input.focus();
        }
        else {
            konto.name = input.value as string;
            updateAccount(konto);
            input.disabled = true;
            let ic = button.firstElementChild as HTMLIonIconElement;
            ic.icon = pencil;
            ic.color = "white";
        }
    }

    function getNeedPrintButton() {
        let userData = { trx: [] as Sale[], toPrint: [] as Sale[] };
        if (type === LIST_PO) {
            userData = getUserTrx(getListShop(shop, po), user.staff);
        }
        else if (type === LIST_BIASSALES) {
            userData = getUserTrx(biassalesHistory, user.staff);
        }
        else if (type === LIST_SALES) {
            userData = getUserTrx(salesHistory, user.staff);
        }
        return <IonButton hidden={!(userData.toPrint.length > 0)} disabled={type === LIST_BIASSALES && !isBias()} slot="end" color="warning" onClick={e => { e.stopPropagation(); toggleMPSales(userData.toPrint, true, !(e.currentTarget.firstElementChild as HTMLIonCheckboxElement).checked); closeModal(e) }}><IonCheckbox className="marginlr" hidden={!isAdmin() || (type === LIST_BIASSALES && !isBias())} slot="start" checked={true} onClick={e => e.stopPropagation()} />{userData.toPrint.length}</IonButton>;
    }

    function getContent() {
        var items = getList(type);
        switch (type) {
            case LIST_SALES: return <List data={items} fill={fill} />;
            case LIST_BIASSALES: return <List data={items} fill={fill} />;
            case LIST_PURCHASES: return <List data={items} fill={fill} />;
            case LIST_PO: {
                items = getListShopSale(shop, items as Sale[]);
                return <List data={items} fill={fill} />;
            }
            case LIST_EXPENSE: return <List data={items} fill={fill} />;
            case LIST_PERSON: return <List data={items} fill={fill} />;
            case LIST_CATALOGQ: return (
                <>
                    <br />
                    <List data={items} fill={fill} />
                </>
            );
            case LIST_CATALOG: return (
                <>
                    <IonSegment className="marginb no-print" value={wStock}>
                        <IonSegmentButton value='1' onClick={() => { setAll(true); setWStock('1'); updateList(); }}><IonLabel>{STR_with} {STR_Quantity}</IonLabel></IonSegmentButton>
                        <IonSegmentButton value='0' onClick={() => { setAll(true); setWStock('0'); updateList(); }}><IonLabel>{STR_without} {STR_Quantity}</IonLabel></IonSegmentButton>
                    </IonSegment>
                    <List data={items} fill={fill} />
                </>
            );
            case LIST_CAT: return (
                <>
                    {showEmpty(items)}
                    <IonList>
                        {(items as Category[]).map((cat, idx) => <IonItem key={idx} className="middle">
                            <IonAvatar slot="start" onClick={(e: any) => { e.persist(); setIconPicker({ konto: null, cat: cat, event: e, type: ICON }) }}><IonIcon size="large" icon={cat.img}></IonIcon></IonAvatar>
                            <IonLabel position="stacked">{cat._id}</IonLabel>
                            <IonInput className="inputE" disabled={true} placeholder={STR_Category} value={cat.name} />
                            <IonButton slot="end" onClick={e => { changeCatName(cat, e.currentTarget as HTMLIonButtonElement) }}><IonIcon icon={pencil} /></IonButton>
                            <IonButton slot="end" onClick={() => { setToRemove(cat); (updateListB.current as unknown as HTMLButtonElement).click() }}><IonIcon icon={trash} /></IonButton>
                        </IonItem>)}
                    </IonList>
                </>
            );
            case LIST_KONTO: return (
                <>
                    {showEmpty(items)}
                    <IonList>
                        {(items as konto[]).map((k, idx) => <IonItem key={idx} className="middle">
                            <IonAvatar slot="start" className="marginR8" onClick={(e: any) => { e.persist(); setIconPicker({ konto: k, cat: null, event: e, type: ICON }) }}><IonIcon size="large" icon={k.icon}></IonIcon></IonAvatar>
                            <IonCheckbox slot="start" checked={k.calculate} onClick={() => setKontoCalc(k, !k.calculate)} />
                            <IonButton slot="start" shape="round" fill="outline" onClick={(e: any) => { e.persist(); setIconPicker({ konto: k, cat: null, event: e, type: CURRENCY }) }}>{curr[k.currency]}</IonButton>
                            <IonInput disabled={true} value={k.name} />
                            <IonButton slot="end" onClick={e => { changeKontoName(k, e.currentTarget as HTMLIonButtonElement) }}><IonIcon icon={pencil} /></IonButton>
                            {isdeletableKonto(k._id) ?
                                <IonButton onClick={() => { setToRemove(k); (updateListB.current as unknown as HTMLButtonElement).click() }}><IonIcon icon={trash} /></IonButton>
                                : ''}
                        </IonItem>)}
                    </IonList>
                    <IonList>
                        {shops.map((s, idx) => <IonItem key={idx} className="middle">
                            <IonAvatar className="marginR8"><img alt={s._id.toUpperCase()} src={"assets/img/" + s._id + ".png"}></img></IonAvatar>
                            <IonCheckbox slot="start" checked={getCurrentUserShopKonto().includes(s._id)} onClick={() => togglecalculateshop(s._id)} />
                            <IonLabel>{s.name}</IonLabel>
                        </IonItem>)}
                    </IonList>
                </>
            );
            case LIST_USER: return <List data={items} fill={fill} />;
            default: return '';
        }
    }

    function isSale() {
        if (toRemove != null && 'date' in toRemove) {
            return true;
        }
        else return false;
    }

    function isPerson() {
        if (toRemove != null && 'telp' in toRemove) {
            return true;
        }
        else return false;
    }

    function isKonto() {
        if (toRemove != null && 'trx' in toRemove) {
            return true;
        }
        else return false;
    }

    function getMessage() {
        switch (showYNAlert.todo) {
            case LIST_PERSON: return STR_addPerson;
            case LIST_CAT: return STR_addCategory;
            case LIST_SALES: return toPrint.length > 0 ? toPrint.length + ' ' + STR_Labels : STR_mprint;
            case LIST_EXPENSE: return STR_Add + ' ' + STR_Expense + ':';
            case LIST_KURIR: return STR_NeedPrintAll;
            case LIST_PO: return STR_RemoveSaleMsg;
            case LIST_PO + 100: return STR_AddPO;
            case LIST_PO + 500: return STR_clear + ' ' + STR_All + '?';
            case LIST_USER: return STR_New + ' ' + STR_User + ' : ';
            case LIST_USER + 100: return STR_Edit + ' ' + STR_User;
            case LIST_USER + 500: return STR_ConfirmDelete + ' ' + STR_User + '?';
            default: return isSale() ? STR_RemoveSaleMsg : isPerson() ? STR_RemovePersonMsg : isKonto() ? STR_Remove + ' ' + STR_Account + ' <b>' + (toRemove as konto)?.name + '</b>?' : STR_Remove + ' ' + STR_Category + ' <b>' + (toRemove as Category)?.name + '</b>?';
        }
    }

    function setIconCat(icon: string, cat: Category) {
        cat.img = icon;
        updateCategory(cat);
        setIconPicker({ konto: null, cat: null, event: undefined, type: ICON });
    }

    function setIcon(icon: string, konto: konto) {
        konto.icon = icon;
        updateAccount(konto);
        setIconPicker({ konto: null, cat: null, event: undefined, type: ICON });
    }

    function setCurrency(currency: string, konto: konto) {
        konto.currency = curr.indexOf(currency);
        setIconPicker({ konto: null, cat: null, event: undefined, type: ICON });
    }

    function printCatalog(list: Item[]) {
        generateCatalog(list);
        //window.print();
    }

    return (
        <>
            <IonHeader className="no-print">
                <IonToolbar>
                    <IonButton slot="start" onClick={e => closeModal(e)}><IonIcon slot="icon-only" icon={close} /></IonButton>
                    {type === LIST_PO ? '' : getNeedPrintButton()}
                    {type === LIST_PO && isAdmin() ?
                        <>
                            <IonButton slot="end" onClick={() => setShowYNAlert({ user: showYNAlert.user, todo: LIST_PO + 500 })}><IonIcon slot="icon-only" icon={trash} /></IonButton>
                            <IonButton slot="end" onClick={() => setShowYNAlert({ user: showYNAlert.user, todo: LIST_KURIR })}><IonIcon slot="icon-only" icon={printOutline} /><IonIcon slot="icon-only" icon={alertOutline} /></IonButton>
                            <IonButton slot="end" onClick={() => setShowYNAlert({ user: showYNAlert.user, todo: LIST_PO + 100 })}><IonIcon slot="icon-only" icon={bagCheckOutline} /></IonButton>
                            <IonButton slot="end" onClick={() => generateShipmentLabel(getListShop(shop, getList(type) as Sale[]), withdetails, false, '', false)}><IonCheckbox slot="start" checked={withdetails} onClick={e => { e.stopPropagation(); setWithdetails(!withdetails) }} />
                                <IonIcon slot="icon-only" icon={listOutline} /><IonLabel>{getListShop(shop, getList(type) as Sale[]).length}</IonLabel></IonButton>
                        </> : ''}
                    {type === LIST_PERSON || type === LIST_CAT || type === LIST_EXPENSE || LIST_USER ? <IonButton slot="end" onClick={() => setShowYNAlert({ user: showYNAlert.user, todo: type })}><IonIcon slot="icon-only" icon={add} /></IonButton> : ''}
                    {type === LIST_SALES ?
                        <IonButton hidden={!isAdmin()} slot="end" className={mprint ? "npaid" : "paid"} onClick={() => setShowYNAlert({ user: showYNAlert.user, todo: type })}>
                            <IonIcon slot="icon-only" icon={checkboxOutline} /><IonLabel hidden={toPrint.length === 0}>{toPrint.length}</IonLabel></IonButton> : ''}
                    {type === LIST_SALES && toSList.length > 0 ?
                        <IonButton slot="end" onClick={() => { generateShipmentLabel(toSList, false, true, user.staff, false).then(() => setReload(!reload)) }}>
                            <IonIcon slot="icon-only" icon={listOutline} />
                            {toSList.length > 0 ? <IonLabel>{toSList.length}</IonLabel> : ''}</IonButton> : ''}
                    {type === LIST_CAT || type === LIST_KONTO || type === LIST_PO || type === LIST_EXPENSE || type === LIST_USER || !isAdmin() ? '' :
                        <>
                            <IonSelect slot="end" hidden={type !== LIST_SALES && type !== LIST_BIASSALES} interface="action-sheet" value={range} onIonChange={e => { e.stopPropagation(); setRange(e.detail.value) }}>
                                {MONTHRANGE.map((d, idx) => <IonSelectOption key={idx} value={MONTHRANGE.indexOf(d)}>{printDateMonth(d.toISOString())}</IonSelectOption>)}
                            </IonSelect>
                            <IonButton slot="end" onClick={() => printList(getList(type), getTitle(type), type === LIST_CATALOG ? Number.parseInt(wStock) : 1, withFoto, MONTHRANGE[range])}>
                                <IonCheckbox hidden={type !== LIST_CATALOG && type !== LIST_CATALOGQ} slot="start" checked={withFoto} onClick={e => { e.stopPropagation(); setWithFoto(!withFoto) }} />
                                <IonIcon hidden={type !== LIST_CATALOG && type !== LIST_CATALOGQ} icon={imagesOutline} />
                                <IonIcon slot="icon-only" icon={printOutline} />
                            </IonButton></>
                    }
                    {type === LIST_CATALOG ? <IonButton slot="end" onClick={() => printcontent("printregion")}><IonIcon slot="icon-only" icon={printOutline} /></IonButton> : ''}
                    {false && type === LIST_CATALOG ? <IonButton slot="end" onClick={() => printCatalog(getList(type) as Item[])}><IonIcon slot="icon-only" icon={bookOutline} /></IonButton> : ''}
                    <IonTitle>{getTitle(type)}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonHeader className="hidden print">
                {printHeader(null)}
            </IonHeader>
            <IonContent>
                <IonButton ref={updateListB} hidden onClick={() => { updateTransactionTab(); toRemove === null ? setReload(!reload) : setShowYNAlert({ user: showYNAlert.user, todo: type + 1000 }) }} />
                {getContent()}
            </IonContent>
            <IonModal ref={relModal} isOpen={show !== undefined} swipeToClose={true} backdropDismiss={true} onDidDismiss={() => { setShow(undefined); updateList() }}>
                <LogModal trxtype={EXPENSE} txf={show} copy={false} rel={relModal} />
            </IonModal>
            <IonFooter>
                <IonModal
                    //event={iconPicker.event}
                    isOpen={iconPicker.konto !== null || iconPicker.cat !== null}
                    onDidDismiss={() => setIconPicker({ konto: null, cat: null, event: undefined, type: ICON })}
                ><IonList>
                    {iconPicker.type === ICON ?
                        iconPicker.konto !== null ?
                            icons.map((icon, idx) => <IonButton key={idx} slot="icon-only" onClick={() => setIcon(icon, iconPicker.konto!)}><IonIcon icon={icon} size="large" /></IonButton>)
                            :
                            icons.map((icon, idx) => <IonButton key={idx} slot="icon-only" onClick={() => setIconCat(icon, iconPicker.cat!)}><IonIcon icon={icon} size="large" /></IonButton>)
                        :
                        curr.map((currency, idx) => <IonButton key={idx} fill="outline" onClick={() => setCurrency(currency, iconPicker.konto!)}>{currency}</IonButton>)
                    }
                </IonList></IonModal>
                <IonAlert isOpen={showYNAlert.todo > -1} onDidDismiss={() => setShowYNAlert({ user: getUser(user.staff), todo: -1 })} message={getMessage()}
                    inputs={showYNAlert.todo === LIST_USER ?
                        [
                            {
                                name: 'id',
                                placeholder: STR_User + ' ' + STR_Name,
                            },
                            {
                                name: 'info',
                                placeholder: STR_Info + ' ' + showYNAlert.user?.info,
                            },
                            {
                                name: 'pwd',
                                placeholder: STR_Pwd8,
                            }
                        ]
                        : showYNAlert.todo === LIST_USER + 100 ?
                            [
                                {
                                    name: 'info',
                                    placeholder: STR_Info + ' ' + showYNAlert.user?.info,
                                },
                                {
                                    name: 'pwd',
                                    placeholder: STR_Pwd8,
                                }
                            ]
                            : showYNAlert.todo === LIST_PERSON ?
                                [
                                    {
                                        name: 'username',
                                        placeholder: STR_UserName,
                                    },
                                    {
                                        name: 'name',
                                        placeholder: STR_Name,
                                    },
                                    {
                                        name: 'tel',
                                        placeholder: STR_tel,
                                    },
                                    {
                                        name: 'adr',
                                        placeholder: STR_Address,
                                    },
                                    {
                                        name: 'info',
                                        placeholder: STR_Info,
                                    }]
                                : showYNAlert.todo === LIST_CAT ?
                                    [
                                        {
                                            name: 'name',
                                            placeholder: STR_Name,
                                        }
                                    ]
                                    : showYNAlert.todo === LIST_EXPENSE ?
                                        [
                                            {
                                                name: 'name',
                                                placeholder: STR_Info,
                                            },
                                            {
                                                name: 'amount',
                                                placeholder: STR_amount,
                                                type: 'number',
                                                min: 0,
                                            }
                                        ]
                                        : []
                    }
                    buttons={showYNAlert.todo === LIST_SALES && toPrint.length > 0 ?
                        [
                            {
                                text: STR_clear + ' ' + STR_All,
                                handler: () => {
                                    clearMPrint();
                                }
                            }, {
                                text: STR_CANCEL,
                                role: 'cancel',
                                cssClass: 'secondary',
                            },
                            {
                                text: STR_print,
                                handler: () => {
                                    printMultipleLabels();
                                }
                            }
                        ]
                        : [
                            {
                                text: STR_CANCEL,
                                role: 'cancel',
                                cssClass: 'secondary'
                            }, {
                                text: STR_OK,
                                handler: (data) => {
                                    try {
                                        if (showYNAlert.todo === LIST_PERSON) {
                                            let p = initPerson();
                                            let key = data.username;
                                            if (key !== '' && !containsClient(key)) {
                                                p._id = key;
                                                p.name = data.name;
                                                p.telp = data.tel;
                                                p.address = data.adr;
                                                p.info = data.info;
                                                p.staff = user.staff;
                                                saveClient(p);
                                            }
                                        }
                                        else if (showYNAlert.todo === LIST_CAT) {
                                            let neuCategory = {
                                                _id: inventoryID === LIST_INVENTORY ? data.name.replaceAll(' ', '').toUpperCase() : makeBiasCategory(data.name.replaceAll(' ', '').toUpperCase()),
                                                name: data.name,
                                                img: '',
                                                count: 0
                                            }
                                            addCategory(neuCategory);
                                        }
                                        else if (showYNAlert.todo === LIST_KURIR) {
                                            printSalesDone(getListShop(shop, getList(type) as Sale[]), true, '', '');
                                        }
                                        else if (showYNAlert.todo === LIST_EXPENSE) {
                                            addExpense(data.name, data.amount);
                                        }
                                        else if (showYNAlert.todo === LIST_SALES) {
                                            toggleMPrint();
                                        }
                                        else if (showYNAlert.todo === LIST_PO + 100) {
                                            addPO(getListShop(shop, getList(type) as Sale[]));
                                        }
                                        else if (showYNAlert.todo === LIST_PO + 500) {
                                            clearPO(getListShop(shop, getList(type) as Sale[]))
                                        }
                                        else if (showYNAlert.todo === LIST_PO + 1000) {
                                            let sale = toRemove as Sale;
                                            removePO(sale._id);
                                            setToRemove(null);
                                        }
                                        else if (showYNAlert.todo === LIST_USER) {
                                            let userdata = {
                                                _id: data.id,
                                                pwd: data.pwd,
                                                info: data.info,
                                                isMGProvided: [] as string[],
                                                profitMG: profitBG,
                                                books: [] as unknown as [{ name: string, cats: string[] }],
                                                savedList: [] as unknown as [{ name: string, list: Sale[] }],
                                                shopkonto: [TOKOPEDIA, SHOPEE, BUKALAPAK, BIAS, LAZADA, "TikTok"]
                                            };
                                            if (userdata._id === '') {
                                                alert(STR_LoginUser + ' ' + STR_ErrorNull);
                                            }
                                            else if (data.pwd.length < 8) {
                                                alert(STR_LoginPwd + ' ' + STR_ErrorNull + ' & ' + STR_Pwd8);
                                            }
                                            else if (!userNameNotAvailable(userdata._id)) {
                                                userdata.pwd = MD5(userdata.pwd);
                                                addUser(users, userdata);
                                            }
                                            else {
                                                alert(STR_UserNameNotAvailable);
                                            }
                                        }
                                        else if (showYNAlert.todo === LIST_USER + 100 && showYNAlert.user) {
                                            showYNAlert.user.info = data.info;
                                            if (data.pwd.length > 7) {
                                                showYNAlert.user.pwd = MD5(data.pwd);
                                                alert(STR_New + ' ' + STR_Pwd + ' : ' + data.pwd);
                                            }
                                            updateUser(showYNAlert.user);
                                        }
                                        else if (showYNAlert.todo === LIST_USER + 500) {
                                            removeUser(users, showYNAlert.user!._id);
                                        }
                                        else {
                                            if (isSale()) {
                                                let sale = toRemove as Sale;
                                                removeSale(sale);
                                                updateTransactionTab();
                                            }
                                            else if (isPerson()) {
                                                let person = toRemove as Person;
                                                removePerson(person._id);
                                            }
                                            else if (isKonto()) {
                                                let k = toRemove as konto;
                                                deleteKonto(k._id);
                                            }
                                            else if (toRemove) {
                                                let c = toRemove as Category;
                                                removeCategory(categories, c._id);
                                            }
                                            setToRemove(null);
                                        }
                                        updateTransactionTab();
                                        updateList();
                                        setReload(!reload);
                                    } catch (error) {
                                        alert(error);
                                    }
                                }
                            }
                        ]}></IonAlert>
            </IonFooter>
        </>
    )
}

export default ListModal;