import React, { useState, useRef } from "react";
import { IonButton, IonLabel, IonInput, IonHeader, IonToolbar, IonIcon, IonTitle, IonContent, IonFooter, IonDatetime, IonItem, IonTextarea, IonSelect, IonSelectOption, IonCheckbox, IonAlert, IonModal } from "@ionic/react";
import { close, checkmark, trash, copyOutline, addOutline, repeat } from "ionicons/icons";
import { INCOME, TRANSFER, EXPENSE, transfer, MainKonto, RDNKonto, trx, updateTxf, addTxf, koinsbuy, koinssell, isTransfer, getTrfFromTrx, getKonto, deleteTxf, saleNoteForTxf, RUNKonto } from "../functions/Log";
import { STR_Expense, STR_Income, STR_Transfer, STR_Note, STR_Account, STR_To, STR_Fee, STR_Remove, STR_CANCEL, STR_OK, STR_RemoveTransfer, STR_RemoveSaleMsg, STR_Copy, STR_NOW, STR_Sale, STR_Add, STR_DrawFund, STR_ShopeePay } from "../lang/en";
import { BUKALAPAK, formatCurrency, LAZADA, RUPIAH, Sale, SHOPEE, TOKOPEDIA } from "../functions/DataModel";
import { getCurrentUserShopKonto, getShop, konten, salesHistory } from "../functions/DataFunctions";
import { List } from "./List";
import SalesCard, { SELECT } from "./SalesCard";

interface ContainerProps {
    trxtype: number;
    txf: trx | transfer | null | undefined;
    copy: boolean;
    rel: any;
}

var selectedSale: Sale | null = null;
export function setSelectedSale(sale: Sale | null) {
    selectedSale = sale;
}

const LogModal: React.FC<ContainerProps> = ({ trxtype, txf, copy, rel }) => {

    const [type, setType] = useState(trxtype);

    const [showYNAlert, setShowYNAlert] = useState(null as unknown as trx);
    const [show, setShow] = useState(false);

    const [addSale, setAddSale] = useState(false);

    const [toShopeePay, setToShopeePay] = useState(false);

    const relModal = useRef(null);

    const isNew = txf === null;

    const initdate = !isNew && txf ? txf.date : new Date().toISOString();
    const initkonto = !isNew && txf ? "to" in txf ? txf.from : txf.account : MainKonto;
    const initkonto1 = !isNew && txf ? "to" in txf ? txf.to : RDNKonto : RDNKonto;
    const initRp = !isNew && txf ? txf.amount : 0;
    const initNote = !isNew && txf ? txf.note : '';
    const initFee = !isNew && txf ? "to" in txf ? txf.fee : 0 : 0;

    const [date, setDate] = useState(initdate);
    const [konto, setKonto] = useState(initkonto);
    const [konto1, setKonto1] = useState(initkonto1);
    const [rp, setRp] = useState(initRp);
    const [note, setNote] = useState(initNote);
    const [fee, setFee] = useState(initFee);

    const [koinsfee, setKoinsFee] = useState(true);

    const amountI = useRef(null);
    const noteI = useRef(null);

    const neu = init();

    const updateIDE = useRef(null);

    function init(): transfer | trx {
        if (type === TRANSFER) {
            return {
                date: date,
                from: konto,
                to: konto1,
                amount: rp,
                fee: fee,
                note: note
            };
        }
        else {
            return {
                date: date,
                amount: rp,
                account: konto,
                note: note,
                type: type
            };
        }
    }

    function closeModal() {
        (rel.current as HTMLIonModalElement).dismiss();
    }

    function getTitle(type: number) {
        switch (type) {
            case EXPENSE: return STR_Expense;
            case INCOME: return STR_Income;
            case TRANSFER: return STR_Transfer;
            default: break;
        }
    }

    function add() {
        if (neu.amount === 0 || neu.note === '') {
            if (neu.amount === 0) (amountI.current as unknown as HTMLInputElement).style.border = "1px solid red";
            if (neu.note === '') (noteI.current as unknown as HTMLInputElement).style.border = "1px solid red";
            return;
        }
        switch (type) {
            case EXPENSE: {
                let t = neu as trx;
                t.type = EXPENSE;
                if (t.account === RDNKonto && koinsfee) {
                    t.note = t.note + " [" + formatCurrency(RUPIAH, t.amount) + "|" + formatCurrency(RUPIAH, koinsbuy * t.amount) + "]";
                    t.amount = t.amount + (koinsbuy * t.amount);
                }
                addTxf(neu, true);
                break;
            }
            case INCOME: {
                let t = neu as trx;
                t.type = INCOME;
                if (t.account === RDNKonto && koinsfee) {
                    t.note = t.note + " [" + formatCurrency(RUPIAH, t.amount) + "|" + formatCurrency(RUPIAH, koinssell * t.amount) + "]";
                    t.amount = t.amount - (koinssell * t.amount);
                }
                addTxf(neu, true);
                break;
            }
            case TRANSFER: {
                if (neu.note === '') return;
                addTxf(neu, true);
                if (toShopeePay) {
                    let sp = {
                        date: neu.date,
                        amount: neu.amount,
                        account: (neu as transfer).to,
                        note: STR_ShopeePay,
                        type: EXPENSE
                    };
                    addTxf(sp, true);
                    setToShopeePay(false);
                }
                break;
            }
            default: break;
        }
        closeModal();
    }

    function update() {
        if (neu.amount === 0 || neu.note === '') {
            if (neu.amount === 0) (amountI.current as unknown as HTMLInputElement).style.border = "1px solid red";
            if (neu.note === '') (noteI.current as unknown as HTMLInputElement).style.border = "1px solid red";
            return;
        }
        if (txf && txf !== null) {
            updateTxf(txf, neu, true);
        }
        closeModal();
    }

    function setAmount(value: string) {
        let n = Number.parseFloat(value);
        if (isNaN(n) || n < 0) {
            (amountI.current as unknown as HTMLInputElement).style.border = "1px solid red";
            n = 0;
        }
        else {
            (amountI.current as unknown as HTMLInputElement).style.border = "none";
        }
        setRp(n);
    }

    function setDesc(value: string) {
        if (value === '') (noteI.current as unknown as HTMLInputElement).style.border = "1px solid red";
        else (noteI.current as unknown as HTMLInputElement).style.border = "none";
        setNote(value);
    }

    function fillTrx(type: number) {
        const k = getKonto(konto);
        return (
            <>
                <IonInput ref={amountI} className="fullwidth large center" type="number" min="0" step='500' value={rp} onIonChange={e => { setAmount(e.detail.value!); neu.amount = Number.parseFloat(e.detail.value!) }}><IonLabel>{formatCurrency(k ? k.currency : RUPIAH, rp)}</IonLabel></IonInput>
                <IonButton className='floatingNow' size='small' fill="outline" onClick={() => { setDate(new Date().toISOString()); neu.date = new Date().toISOString() }}><IonLabel>{STR_NOW}</IonLabel></IonButton>
                <IonDatetime className={type === INCOME ? "fullwidth center bgGreen" : type === EXPENSE ? "fullwidth center bgRed" : "fullwidth center bg"} displayFormat="DD-MM-YYYY (DDD) HH:mm" placeholder="Select Date" value={date} onIonChange={e => { setDate(e.detail.value!); neu.date = e.detail.value! }}></IonDatetime>
                {"to" in neu ?
                    <IonItem className="fullwidth center">
                        <IonSelect value={konto} interface="action-sheet" onIonChange={e => { setKonto(e.detail.value!); neu.from = e.detail.value! }}>
                            {konten.map((k, idx) => <IonSelectOption key={idx} value={k._id}>{k.name}</IonSelectOption>)}
                            {getCurrentUserShopKonto().map((k, idx) => k ? <IonSelectOption key={idx} value={k}>{getShop(k)?.name}</IonSelectOption> : '')}
                        </IonSelect>
                        <IonLabel>{STR_To}</IonLabel>
                        <IonSelect value={konto1} interface="action-sheet" onIonChange={e => { setKonto1(e.detail.value!); neu.to = e.detail.value! }}>
                            {konten.map((k, idx) => <IonSelectOption key={idx} value={k._id}>{k.name}</IonSelectOption>)}
                            {getCurrentUserShopKonto().map((k, idx) => k ? <IonSelectOption key={idx} value={k}>{getShop(k)?.name}</IonSelectOption> : '')}
                        </IonSelect>
                    </IonItem>
                    :
                    <IonItem>
                        <IonLabel>{STR_Account}</IonLabel>
                        <IonSelect value={konto} interface="action-sheet" onIonChange={e => { setKonto(e.detail.value!); neu.account = e.detail.value! }}>
                            {konten.map((k, idx) => <IonSelectOption key={idx} value={k._id}>{k.name}</IonSelectOption>)}
                            {getCurrentUserShopKonto().map((k, idx) => k ? <IonSelectOption key={idx} value={k}>{getShop(k)?.name}</IonSelectOption> : '')}
                        </IonSelect>
                        {(isNew || copy) && konto === RDNKonto ?
                            <><IonCheckbox className="marginlr" checked={koinsfee} onClick={e => { e.stopPropagation(); setKoinsFee(!koinsfee) }} /><IonLabel>{STR_Fee}</IonLabel></>
                            : ''}
                    </IonItem>
                }
                {"to" in neu ?
                    <IonItem>
                        <IonLabel position="stacked">{STR_Fee}</IonLabel>
                        <IonInput type="number" min="0" step='500' value={neu.fee} onIonChange={e => { setFee(Number.parseFloat(e.detail.value!)); neu.fee = Number.parseFloat(e.detail.value!) }} />
                    </IonItem>
                    : ''}
                <IonItem>
                    <IonLabel position="stacked">{STR_Note}</IonLabel>
                    <IonTextarea ref={noteI} className="fullwidth" rows={6} required value={note} onIonChange={e => { setDesc(e.detail.value!); neu.note = e.detail.value! }}></IonTextarea>
                </IonItem>
            </>
        );
    }

    function getQuestion(trx: trx) {
        if (trx && isTransfer(trx)) return STR_RemoveTransfer;
        else return STR_RemoveSaleMsg;
    }

    function fill(s: Sale, idx: number) {
        return <SalesCard key={idx} sale={s} type={SELECT} rel={updateIDE} po={false} />;
    }

    function addSaleID() {
        try {
            if (selectedSale && selectedSale !== null) {
                let el = noteI.current! as HTMLIonTextareaElement
                let s = (el.value as string).length !== 0 ? ' ' : '';
                el.value += s + saleNoteForTxf(selectedSale._id);
                setSelectedSale(null);
                (relModal.current! as HTMLIonModalElement).dismiss();
            }
        }
        catch (error) { }
    }

    function toggletrxtype(t: trx) {
        if (t.type === INCOME) {
            t.type = EXPENSE;
            setType(EXPENSE);
        }
        else {
            t.type = INCOME;
            setType(INCOME);
        }
    }

    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonButton slot="start" onClick={() => closeModal()}><IonIcon slot="icon-only" icon={close} /></IonButton>
                    <IonTitle>{copy ? STR_Copy + ' ' : ''}{getTitle(type)}</IonTitle>
                    {'type' in neu ? <>
                        <IonButton slot="end" onClick={e => { e.stopPropagation(); toggletrxtype(neu) }}><IonIcon slot="icon-only" icon={repeat} /></IonButton>
                    </> : ''}
                    {isNew || copy ? '' :
                        <>
                            <IonButton slot="end" onClick={e => { e.stopPropagation(); setShowYNAlert(txf as trx) }}><IonIcon slot="icon-only" icon={trash} /></IonButton>
                            <IonButton slot="end" onClick={e => { e.stopPropagation(); setShow(true) }}><IonIcon slot="icon-only" icon={copyOutline} /></IonButton>
                        </>
                    }
                    {isNew && type === TRANSFER ? <>
                        <IonButton slot="end" onClick={() => { setKonto(BUKALAPAK); setKonto1(MainKonto); setNote(STR_DrawFund + ' ' + BUKALAPAK.toUpperCase()) }}><img alt='' className="icon" src="assets/img/bukalapak.png"></img></IonButton>
                        <IonButton slot="end" onClick={() => { setKonto(TOKOPEDIA); setKonto1(MainKonto); setNote(STR_DrawFund + ' ' + TOKOPEDIA.toUpperCase()) }}><img alt='' className="icon" src="assets/img/tokopedia.png"></img></IonButton>
                        <IonButton slot="end" onClick={() => { setKonto(SHOPEE); setKonto1(RUNKonto); setNote(STR_DrawFund + ' ' + SHOPEE.toUpperCase()) }}><img alt='' className="icon" src="assets/img/shopee.png"></img><IonCheckbox className="marginlr" checked={toShopeePay} onClick={e => { e.stopPropagation(); setToShopeePay(!toShopeePay) }} />SPay</IonButton>
                        <IonButton slot="end" onClick={() => { setKonto(LAZADA); setKonto1(MainKonto); setNote(STR_DrawFund + ' ' + LAZADA.toUpperCase()) }}><img alt='' className="icon" src="assets/img/Lazada.png"></img></IonButton>
                    </> : ''}
                    <IonButton slot="end" onClick={() => setAddSale(true)}><IonIcon slot="icon-only" icon={addOutline} /></IonButton>
                    <IonButton slot="end" onClick={() => (txf === null || copy) ? add() : update()}><IonIcon slot="icon-only" icon={checkmark} /></IonButton>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {fillTrx(type)}
            </IonContent>
            <IonFooter>
                <IonModal ref={relModal} isOpen={show} swipeToClose={true} backdropDismiss={true} onDidDismiss={() => { setShow(false); closeModal() }}>
                    <LogModal trxtype={type} txf={txf} copy={true} rel={relModal} />
                </IonModal>
                <IonAlert isOpen={showYNAlert != null} onDidDismiss={() => setShowYNAlert(null as unknown as trx)} header={showYNAlert?.type === INCOME ? STR_Remove + ' ' + STR_Income : STR_Remove + ' ' + STR_Expense} message={getQuestion(showYNAlert)}
                    buttons={[
                        {
                            text: STR_CANCEL,
                            role: 'cancel',
                            cssClass: 'secondary',
                        }, {
                            text: STR_OK,
                            handler: () => {
                                try {
                                    if (isTransfer(showYNAlert)) {
                                        let trf = getTrfFromTrx(showYNAlert);
                                        deleteTxf(trf!, true);
                                    }
                                    else deleteTxf(showYNAlert, true);
                                    closeModal();
                                } catch (error) {
                                    alert(error);
                                }
                            }
                        }
                    ]} />

                <IonModal ref={relModal} isOpen={addSale} swipeToClose={true} backdropDismiss={true} onDidDismiss={() => setAddSale(false)}>
                    <IonHeader>
                        <IonToolbar>
                            <IonButton slot="start" onClick={() => setAddSale(false)}><IonIcon slot="icon-only" icon={close} /></IonButton>
                            <IonTitle>{STR_Add + ' ' + STR_Sale}</IonTitle>
                            <IonButton hidden ref={updateIDE} slot="start" onClick={() => addSaleID()}><IonIcon slot="icon-only" icon={close} /></IonButton>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent>
                        <List data={salesHistory} fill={fill} />
                    </IonContent>
                </IonModal>
            </IonFooter>
        </>
    )
}

export default LogModal;