import React, { useRef, useState } from 'react';
import { IonAlert, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonFooter, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonTextarea, isPlatform } from "@ionic/react";
import { initNote, Note } from "../functions/DataModel";
import { getImgURL, noteExists, printDate, removeNote } from '../functions/DataFunctions';
import { checkmark, close, imageOutline, pencil, trash } from 'ionicons/icons';
import { usePhotoGallery } from '../hooks/usePhotoGallery';
import { getBLOB, openNoteImage, removeNoteImage, saveNote } from '../functions/LocalStorage';
import { STR_CANCEL, STR_ConfirmDelete, STR_Description, STR_ERROR, STR_ErrorItemExists, STR_ErrorNull, STR_JSXElement, STR_Key, STR_Neu, STR_Note, STR_OK, STR_Remove, STR_Subtitle, STR_Title } from '../lang/en';
import { user } from './AppContextProvider';
import { setselectedNote } from '../pages/Notes';
import { updateList } from './List';

interface ContainerProps {
  note: Note,
  rel: any
}

export const noteImageID = 'nimg';

export const NoteCard: React.FC<ContainerProps> = ({ note, rel }) => {

  const neu = note._id === '';

  const [error, setError] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [show, setShow] = useState(false);

  const [edit, setEdit] = useState(note._id === '');

  const inputItem = useRef(null);
  const inputSubtitle = useRef(null);
  const inputTitle = useRef(null);
  const inputContent = useRef(null);
  const inputFooter = useRef(null);

  const { takePhoto, takePic } = usePhotoGallery();

  function takePicture(n: number) {
    switch (n) {
      case 0: {
        takePhoto().then(photo => {
          if (photo && photo.base64) {
            getBLOB(photo.base64).then(file => openNoteImage(file, note));
          }
        }).catch(err => alert(JSON.stringify(err)));
        break;
      }
      case 1: {
        takePic().then(photo => {
          if (photo && photo.base64) {
            getBLOB(photo.base64).then(file => openNoteImage(file, note));
          }
        }).catch(err => alert(JSON.stringify(err)));
        break;
      }
      default: break;
    }

  }

  function saveImage(n: number) {
    let degree = (n % 4) * 90;

    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext('2d');

    var imgC = document.getElementById(noteImageID) as HTMLImageElement;

    let img = new Image();
    img.src = imgC?.src;

    img.onload = function () {
      if (ctx) {
        var cw = img.width, ch = img.height, cx = 0, cy = 0;

        //   Calculate new canvas size and x/y coorditates for image
        switch (degree) {
          case 90:
            cw = img.height;
            ch = img.width;
            cy = img.height * (-1);
            break;
          case 180:
            cx = img.width * (-1);
            cy = img.height * (-1);
            break;
          case 270:
            cw = img.height;
            ch = img.width;
            cx = img.width * (-1);
            break;
        }

        //  Rotate image            
        canvas.width = cw;
        canvas.height = ch;
        ctx.rotate(degree * Math.PI / 180);

        ctx.drawImage(img, cx, cy, canvas.width, canvas.height);
      }
      canvas.toBlob(function (blob) { openNoteImage(blob, note) });
    };
  }

  function onclickSubmit(e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) {
    e.stopPropagation();
    if (neu || edit) {
      let l = (inputTitle.current! as HTMLIonInputElement).value as string;
      let u = (inputContent.current! as HTMLIonInputElement).value as string;

      if (note._id === '') {
        if (l === '' || u === '') {
          note.title = l;
          note.content = u;
          return setError(STR_ErrorNull);
        }
        else if ((neu || edit) && noteExists(l, u)) {
          note.title = l;
          note.content = u;
          return setError(STR_ERROR + ' : ' + STR_Note + STR_ErrorItemExists);
        }
        else note._id = user.staff + '|' + (new Date()).toISOString();
      }

      note.title = l;
      note.content = u;
      note.subtitle = (inputSubtitle.current! as HTMLIonInputElement).value as string;
      //note.ionitem = (inputItem.current! as HTMLIonInputElement).value as string;
      //note.ionfooter = (inputFooter.current! as HTMLIonInputElement).value as string;
      saveNote(note);
      setError('');
      setEdit(!edit);
      updateList();
      setselectedNote(initNote());
      rel.current.click();
    }
    else {
      setEdit(!edit);
    }
  }

  function printNoteID(id: string) {
    let s = id.split('|');
    return s[0] + ' | ' + printDate(s[1]);
  }

  return (
    <IonCard>
      <IonItem>
        <IonLabel>{note._id === '' ? STR_Neu : printNoteID(note._id)}</IonLabel>
        <IonButton hidden={neu || !edit || (edit && note.img !== null)} fill="clear" onClick={() => takePicture(1)}>
          {isPlatform('hybrid') ? '' : <input type="file" accept="image/*" onChange={e => { openNoteImage(e.target.files ? e.target.files.item(0) : null, note); e.currentTarget.value = ''; updateList() }} onClick={e => e.stopPropagation()} />}
          <IonIcon icon={imageOutline} slot="icon-only"></IonIcon>
        </IonButton>
        {note.img !== null ? <IonButton fill="clear" onClick={() => setShow(true)}>
          <IonIcon icon={imageOutline} slot="icon-only"></IonIcon>
        </IonButton> : ''}
        {edit && note.img !== null ? <IonButton fill="clear" onClick={() => removeNoteImage(note)}>
          <IonIcon icon={close} slot="icon-only"></IonIcon>
        </IonButton> : ''}
        <IonButton slot="end" className="endButton no-print" color={neu || edit ? "success" : "primary"} onClick={e => onclickSubmit(e)}><IonIcon icon={neu || edit ? checkmark : pencil} /></IonButton>
        <IonButton hidden={neu || edit} className="endButton no-print" slot="end" onClick={e => {
          e.stopPropagation();
          setConfirm(true);
        }}><IonIcon slot="icon-only" icon={trash} /></IonButton>
      </IonItem>
      <IonItem hidden={error === ''} className='clearboth fullwidth warning center smaller'>{error!}</IonItem>
      {false && (neu || edit) ? <IonTextarea ref={inputItem} value={note.ionitem ? note.ionitem.toString() : ''} placeholder={STR_JSXElement}></IonTextarea> : note.ionitem && note.ionitem !== null ? <IonItem>{note.ionitem}</IonItem> : ''}
      {note.img && note.img !== null ? <img id={noteImageID} src={getImgURL(note.img)} alt='' /> : ''}
      <IonCardHeader>
        {neu || edit ? <IonInput ref={inputTitle} value={note.title} placeholder={STR_Title}></IonInput> : <IonCardTitle>{note.title}</IonCardTitle>}
        {neu || edit ? <IonInput ref={inputSubtitle} value={note.subtitle} placeholder={STR_Subtitle}></IonInput> : note.subtitle === '' ? '' : <IonCardSubtitle>{note.subtitle}</IonCardSubtitle>}
      </IonCardHeader>
      {neu || edit ? <IonTextarea rows={10} ref={inputContent} value={note.content} placeholder={STR_Description}></IonTextarea> : <IonCardContent><pre>{note.content}</pre></IonCardContent>}
      {false && (neu || edit) ? <IonTextarea ref={inputFooter} value={note.ionfooter ? note.ionfooter.toString() : ''} placeholder={STR_JSXElement}></IonTextarea> : note.ionfooter && note.ionfooter !== null ? <IonFooter>{note.ionfooter}</IonFooter> : ''}
      <IonModal isOpen={show} cssClass='printModal' swipeToClose={true} backdropDismiss={true} onDidDismiss={() => setShow(false)}>
        <img id={noteImageID} src={getImgURL(note.img)} alt='' />
      </IonModal>
      <IonAlert isOpen={confirm} onDidDismiss={() => setConfirm(false)} header={STR_Remove} message={STR_ConfirmDelete + STR_Key + '?'}
        buttons={[
          {
            text: STR_CANCEL,
            role: 'cancel',
            cssClass: 'secondary',
          }, {
            text: STR_OK,
            handler: () => {
              try {
                removeNote(note);
                updateList();
                rel.current.click();
              } catch (error) {
                alert(error);
              }
            }
          }
        ]}></IonAlert>
    </IonCard>
  );
};