import React, { useRef, useState } from 'react';
import { IonItem, IonInput, IonTextarea, IonButton, IonIcon, AlertInput, IonAlert } from '@ionic/react';
import { MEKUYAID, Person, Sale, SHOPEE, SHOPEE_EXPORT } from '../functions/DataModel';
import { STR_Info, STR_Name, STR_tel, STR_Address, STR_CANCEL, STR_OK } from '../lang/en';
import { updatePerson, updateSale } from '../functions/LocalStorage';
import { addOutline, checkmark, pencil, personOutline, print, trash } from 'ionicons/icons';
import { setToRemove } from './ListModal';
import { printShipment } from '../functions/Output';
import { user } from './AppContextProvider';
import { getPersonID, isAdmin } from '../functions/DataFunctions';
import { users } from './Login';
import { formatShopee } from '../pages/Tab2';

interface ContainerProps {
  person: Person;
  rel: any;
  sale: Sale | undefined;
}

const PersonCard: React.FC<ContainerProps> = ({ person, rel, sale }) => {

  const [edit, setEdit] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const inputName = useRef(null);
  const inputTel = useRef(null);
  const inputAdr = useRef(null);
  const inputInfo = useRef(null);

  var res = { name: person.name, telp: person.telp, address: person.address, info: person.info };

  function changeAtr(atr: number, text: string) {
    switch (atr) {
      case 0: {
        res.name = text;
        break;
      }
      case 1: {
        if (text.startsWith('62')) {
          text = '0' + text.substring(2);
          (inputTel.current! as HTMLIonInputElement).value = text;
        }
        res.telp = text;
        break;
      }
      case 2: {
        res.address = text;
        break;
      }
      case 3: {
        res.info = text;
        break;
      }
      default: break;
    }
  }

  function selectStaff() {
    let select: AlertInput[] = [];
    for (var i = 0; i < users.length; i++) {
      select.push({
        type: 'radio',
        label: users[i]._id,
        value: users[i]._id,
        checked: person.staff === users[i]._id
      });
    }
    return select;
  }

  function addShopeeAdr(e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) {
    let el = (e.currentTarget.previousElementSibling as HTMLIonInputElement);
    let input = el.value as string;
    let res = formatShopee(input);
    setEdit(true);
    (inputName.current as unknown as HTMLInputElement).value = res.name;
    (inputTel.current as unknown as HTMLInputElement).value = res.tel;
    (inputAdr.current as unknown as HTMLInputElement).value = res.adr;
    (inputInfo.current as unknown as HTMLInputElement).value = res.info;
    el.value = '';
  }


  return (
    <>
      <IonItem>
        <div className="fullwidth">
          <div className="fullwidth">
            <IonButton className="fleft no-print" slot="start" onClick={e => {
              e.stopPropagation();
              printShipment(person);
            }}><IonIcon slot="icon-only" icon={print} /></IonButton>
            <IonButton hidden={!isAdmin()} className="fleft no-print" slot="start" onClick={e => {
              e.stopPropagation();
              setShowAlert(true);
            }}><IonIcon icon={personOutline} />{person.staff && person.staff.length > 0 ? <span className="marginl">{person.staff}</span> : ''}</IonButton>
            <IonButton className="fleft no-print" slot="start" onClick={e => {
              e.stopPropagation();
              if (edit) {
                person.name = res.name;
                person.telp = res.telp;
                person.address = res.address;
                person.info = res.info;
                person.staff = user.staff;
                if (sale) {
                  updateSale(sale, true);
                }
                else {
                  updatePerson(person);
                }
              }
              setEdit(!edit);
            }}><IonIcon slot="icon-only" icon={edit ? checkmark : pencil} /></IonButton>
            {rel === null || person._id === MEKUYAID || person._id === SHOPEE_EXPORT ? '' :
              <IonButton className="fleft no-print" slot="start" onClick={e => {
                e.stopPropagation();
                setToRemove(person);
                rel.current.click();
              }}><IonIcon slot="icon-only" icon={trash} /></IonButton>}
            <IonItem>
              <IonInput hidden={!edit} className='bgsug' color='warning'></IonInput>
              <IonButton slot='end' onClick={e => { e.stopPropagation(); addShopeeAdr(e) }}>{edit ? <IonIcon icon={addOutline} /> : <img alt={SHOPEE.toUpperCase()} src={"assets/img/" + SHOPEE + ".png"} width="95%" height="95%"></img>}</IonButton></IonItem>
            <div className="fright"><b>{isAdmin() ? person._id : getPersonID(person)}</b></div>
            <div className="fleft fullwidth"><IonTextarea ref={inputInfo} disabled={!edit} placeholder={STR_Info} value={person.info} onIonChange={e => changeAtr(3, e.detail.value as string)}></IonTextarea></div>
          </div>
          <div className="center roundBorder paddingtb">
            <IonInput ref={inputName} className="fit" disabled={!edit} placeholder={STR_Name} value={person.name} onIonChange={e => changeAtr(0, e.detail.value as string)}></IonInput>
            <IonInput ref={inputTel} className="fit" disabled={!edit} inputmode="tel" type="number" placeholder={STR_tel} value={person.telp} onIonChange={e => changeAtr(1, e.detail.value as string)}></IonInput>
            <IonTextarea ref={inputAdr} className="fit" disabled={!edit} placeholder={STR_Address} value={person.address} onIonChange={e => changeAtr(2, e.detail.value as string)}></IonTextarea>
          </div>
          <br />
        </div>
      </IonItem>
      <IonAlert isOpen={showAlert} onDidDismiss={() => setShowAlert(false)}
        inputs={selectStaff()}
        buttons={[
          {
            text: STR_CANCEL,
            role: 'cancel',
            cssClass: 'secondary'
          }, {
            text: STR_OK,
            handler: (data) => {
              try {
                person.staff = data;
                updatePerson(person);
              }
              catch (error) {
                alert(error);
              }
            }
          }
        ]}></IonAlert>
    </>
  );
};

export default PersonCard;