import React, { useState, useRef } from "react";
import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonButton, IonIcon, IonTitle, IonContent, IonFooter, IonGrid, IonRow, IonCol, IonAlert } from "@ionic/react";
import { add } from "ionicons/icons";
import { List, updateList } from "../components/List";
import { STR_CANCEL, STR_ConfirmDelete, STR_Invoice, STR_New, STR_OK, STR_show } from "../lang/en";
import { Invoice, initInvoice } from "../functions/DataModel";
import { deleteInvoice, getInvoice, invoices, requestUpdateList } from "../functions/DataFunctions";
import InvoiceCard from "../components/InvoiceCard";

export const INVEDIT = 0;
export const INVREMOVE = 1;

var action = 0;
export var invToDo: Invoice | null = null;
export function setInvToDo(inv: Invoice | null, todo: number) {
  invToDo = inv;
  action = todo;
}

const Invoices: React.FC = () => {

  const [invoice, setInvoice] = useState({ inv: initInvoice(), reload: false });
  const [showYNAlert, setShowYNAlert] = useState(invToDo);
  const neubutton = useRef(null);
  const updatebutton = useRef(null);

  function fill(inv: Invoice, idx: number) {
    return <IonCol key={idx}><InvoiceCard inv={inv} neu={false} main={false} rel={updatebutton} /></IonCol>;
  }

  function getMessage() {
    switch (action) {
      case INVREMOVE: return STR_ConfirmDelete + ' ' + STR_Invoice + '?';
      default: return STR_show + ' ' + STR_Invoice + '?';
    }
  }

  function doAction() {
    switch (action) {
      case INVREMOVE: {
        setInvoice({ inv: initInvoice(), reload: !invoice.reload });
        setShowYNAlert(invToDo);
        break;
      }
      case INVEDIT: {
        if (invToDo !== null) {
          setInvoice({ inv: invToDo, reload: !invoice.reload });
        }
        break;
      }
      default: { }
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonButton hidden ref={updatebutton} onClick={() => doAction()}></IonButton>
          <IonButton ref={neubutton} slot="end" onClick={() => { setInvToDo(initInvoice(), INVEDIT); (updatebutton.current! as HTMLButtonElement).click(); }}><IonIcon icon={add} /></IonButton>
          <IonTitle>{STR_Invoice}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol className='roundBorder margin10 borderwarning'>
              <IonTitle>{invoice.inv._id.length === 0 ? STR_New : invoice.inv._id}</IonTitle>
              <InvoiceCard inv={invoice.inv} neu={invoice.inv._id.length === 0 || getInvoice(invoice.inv._id) === null} main={true} rel={updatebutton} />
            </IonCol>
            <IonCol>
              <List data={invoices} fill={fill} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonFooter>
        <IonAlert isOpen={showYNAlert !== null && action === INVREMOVE} onDidDismiss={() => { setShowYNAlert(null); setInvToDo(initInvoice(), INVEDIT) }} message={getMessage()}
          buttons={[
            {
              text: STR_CANCEL,
              role: 'cancel',
              cssClass: 'secondary'
            }, {
              text: STR_OK,
              handler: () => {
                try {
                  if (showYNAlert !== null) {
                    if (action = INVREMOVE) {
                      let invid = showYNAlert._id;
                      deleteInvoice(invid);
                      requestUpdateList(true);
                      updateList();
                      if (invoices.length > 1) {
                        let last = invoices[invoices.length - 1];
                        setInvoice({ inv: last, reload: !invoice.reload });
                        setInvToDo(last, INVEDIT);
                      }
                      else {
                        let n = initInvoice();
                        setInvoice({ inv: n, reload: !invoice.reload });
                        setInvToDo(n, INVEDIT);
                      }
                    }
                  }
                } catch (error) {
                  alert(error);
                }
              }
            }
          ]}></IonAlert>
      </IonFooter>
    </IonPage>
  );
};

export default Invoices;