import React from "react";
import { SalesItem, Sale, formatCurrency, DEFAULTSHOP, RUPIAH, Person, ROOT, BIAS, MEGAKON, Item, BIASREK, DROPSHIPTAG, RUPIAHZ, OFFLINE, CATCHX, initSale, Invoice } from "./DataModel";
import { STR_TO, STR_Thx, STR_Client, STR_Status, STR_Paid, STR_Folio, STR_Date, STR_Quantity, STR_Price, STR_RP, STR_Subtotal, STR_Total, STR_MyShop, STR_Name, STR_ID, STR_Topay, STR_No, STR_Purchases, STR_MyID, STR_UserName, STR_tel, STR_Address, STR_Info, STR_Shop, STR_Profit, STR_Items, STR_Invoice, STR_Labels, STR_Resi, STR_Pengirim, STR_SafePack, STR_DP, STR_Discount, STR_DeliveryOrder, STR_QTY, STR_Ongkir, STR_SaleNote, STR_Shipment, STR_MSRP, STR_ThxBias, STR_ThxMegakon, STR_COD, STR_Tagihan, STR_Receipt, STR_FooterReceipt, STR_FooterInvoice, STR_Receipt1, STR_Catatan, STR_OutOfStock, STR_ErrorPrinting, Str_Rekening, STR_Note, STR_InvDate, STR_Customer, STR_InvKurir, STR_InvResi, STR_DueDate, STR_telp, STR_ShipmentAddress } from "../lang/en";
import { printDateS, getShop, getNumberID, printDateMonth, inCategory, printDate, getCategoryToPrint, getCategoryNames, printDateDay, toPrint, printSalesDone, DOMode, isPO, inventoryID, LIST_INVENTORY, getImageURL, LIST_BIASINVENTORY, getPersonID, toSList, printSimpleDate, printmode, mprint, isMedis, simpleItemCountInfo, sortToShipReport, addprintedlist, isPurchase, getImgURL, getInvBankAcc } from "./DataFunctions";
import ReactDOM from "react-dom";
import { downloadJpg, htmltoimage, setItemsImage } from "./LocalStorage";
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import { isPlatform } from "@ionic/react";
import { SocialSharing } from "@ionic-native/social-sharing";
import { user } from "../components/AppContextProvider";

import { BluetoothSerial } from '@ionic-native/bluetooth-serial';

var JsBarcode = require('jsbarcode');

export const printID = 'list';
var images: string[] = [];

export function printHeader(sale: Sale | null) {
    let bias = sale !== null && sale.shop === BIAS;
    let megakon = sale !== null && sale.shop === MEGAKON;
    return (
        <>
            <div className="center fullwidth"><img /*itemID="mekuyaLogo"*/ alt='mekuya' src={bias ? 'assets/img/BiasLogo.jpg' : megakon ? 'assets/img/MegakonLogo.jpg' : 'assets/img/mekuyaLogo.jpg'}></img></div>
            {bias ?
                <div className="center fullwidth borderbottom largerX">
                    www.bias-acc.com<br />
                    HP/WA: 0895 24 0000 20<br />
                </div>
                :
                megakon ?
                    <div className="center fullwidth borderbottom largerX">
                        HP/WA: 0895 371 903 558<br />
                    </div>
                    :
                    <div className="center fullwidth borderbottom largerX">
                        MEKUYA<br />
                        www.mekuya.com<br />
                        HP/WA: 0895 371 903 558<br />
                    </div>
            }
        </>
    );
}

function printSaleHeader(sale: Sale, withname: boolean) {
    if (sale.dropshipper !== '' && !withname) {
        return; /*(
            <>
                <div className="center warning fullwidth borderbottom large">
                    !!! JANGAN DIMASUKIN PAKET !!!
                </div>
            </>
        );*/
    }
    else if (sale.dropshipper !== '' && withname) {
        return (
            <>
                <div className="center fullwidth borderbottom largerX">
                    {STR_Pengirim}<br />
                    {sale.dropshipper.startsWith(DROPSHIPTAG) ? sale.dropshipper.substring(1) : sale.dropshipper}<br />
                </div>
            </>
        );
    }
    else return printHeader(sale);
}

function printDOHeader(sale: Sale, withname: boolean) {
    if (sale.dropshipper !== '' && !withname) {
        return (
            <>
                <div className="center fullwidth borderbottom large">
                    {STR_DeliveryOrder}
                </div>
            </>
        );
    }
    else if (sale.dropshipper !== '' && withname) {
        return (
            <>
                <div className="center fullwidth borderbottom largerX">
                    {STR_Pengirim}<br />
                    {sale.dropshipper.startsWith(DROPSHIPTAG) ? sale.dropshipper.substring(1) : sale.dropshipper}<br />
                </div>
            </>
        );
    }
    else return printHeader(sale);
}

function printThx(sale: Sale) {
    return (
        sale.dropshipper !== '' ? '' : <em>{sale.shop === BIAS ? STR_ThxBias : sale.shop === MEGAKON ? STR_ThxMegakon : STR_Thx}</em>
    );
}

function printReceipt(sale: Sale) {
    return (
        <div className="padding10 printlabel">
            {printSaleHeader(sale, false)}
            <div>
                <div className="fleft minw30">{STR_Folio}: {getNumberID(sale._id)}</div>
                <div className="fright minw30">{STR_Status}: {sale.status ? sale.status : STR_Paid}</div>
            </div>
            <div className="clearboth fullwidth paddingtb">
                {STR_Date}: {printDateS(sale.date)}
            </div>
            {(DEFAULTSHOP.includes(sale.shop) && getPersonID(sale.client) === '') ? '' : <div className="fullwidth">{STR_Client}: {DEFAULTSHOP.includes(sale.shop) ? '' : sale.shop} {getPersonID(sale.client)}</div>}
            {sale.note === '' ? '' : <div className="clearboth fullwidth paddingtb">{STR_Info}: {<span className='warning'>{sale.note}</span>}</div>}
            <br />
            <table className="fullwidth paddingtb">
                <thead>
                    <tr className="bordertop borderbottom">
                        <td className="textcenter">{STR_Quantity.toUpperCase()}</td>
                        <td className="textcenter">{STR_Price.toUpperCase()}({STR_RP})</td>
                        <td className="textcenter">{STR_Subtotal}({STR_RP})</td>
                    </tr>
                </thead>
                <tbody>
                    <tr><td colSpan={3}>&nbsp;</td></tr>
                    {sale.items.map((item, idx) => printItem(idx, item))}
                    <tr><td colSpan={3}>&nbsp;</td></tr>
                    <tr><td colSpan={3}>&nbsp;</td></tr>
                </tbody>
                <tfoot>
                    {sale.discount > 0 ?
                        <>
                            <tr>
                                <td /><td />
                                <td className="bold bordertop paddingtb">
                                    {STR_Total.toUpperCase()} :
                                    <span className="fright paddingR15"><b>{formatCurrency(getShop(sale.shop)?.currency!, sale.total + +sale.discount)}</b></span></td>
                            </tr>
                            <tr>
                                <td /><td />
                                <td className="bold paddingtb">{STR_Discount.toUpperCase()} :
                                    <span className="fright paddingR15"><b>{formatCurrency(getShop(sale.shop)?.currency!, sale.discount)}</b></span></td>
                            </tr>
                        </>
                        : ''}
                    {sale.dp > 0 ?
                        <>
                            <tr>
                                <td /><td />
                                <td className="bold bordertop paddingtb">
                                    <span className="fright paddingR15"><b>{formatCurrency(getShop(sale.shop)?.currency!, sale.total)}</b></span></td>
                            </tr>
                            <tr>
                                <td /><td />
                                <td className="bold bordertop paddingtb">{STR_DP.toUpperCase()} :
                                    <span className="fright paddingR15"><b>{formatCurrency(getShop(sale.shop)?.currency!, sale.dp)}</b></span></td>
                            </tr>
                        </>
                        : ''}
                    {sale.shipping > 0 ?
                        <>
                            <tr>
                                <td /><td />
                                <td className="bold bordertop paddingtb">
                                    <span className="fright paddingR15"><b>{formatCurrency(getShop(sale.shop)?.currency!, sale.total - sale.dp)}</b></span></td>
                            </tr>
                            <tr>
                                <td /><td />
                                <td className="bold bordertop paddingtb">{STR_Ongkir.toUpperCase()} :
                                    <span className="fright paddingR15"><b>{formatCurrency(getShop(sale.shop)?.currency!, sale.shipping)}</b></span></td>
                            </tr>
                        </> : ''}
                    <tr>
                        <td className="textcenter large bold bordertop" colSpan={3}>{STR_Total.toUpperCase()} <b>{formatCurrency(getShop(sale.shop)?.currency!, sale.total - sale.dp + +sale.shipping)}</b></td>
                    </tr>
                </tfoot>
            </table>
            <div className="textcenter smaller fullwidth bordertop">{printThx(sale)}</div>
        </div>
    );
}

function printDeliveryOrder(sale: Sale) {
    return (
        <div className="padding10 printlabel">
            {printDOHeader(sale, false)}
            <div>
                <div className="fleft minw30">{STR_Folio}: {getNumberID(sale._id)}</div>
                <div className="fright minw30">{STR_Status}: {sale.status ? sale.status : STR_Paid}</div>
            </div>
            <div className="clearboth fullwidth paddingtb">
                {STR_Date}: {printDateS(sale.date)}
            </div>
            {sale.dropshipper !== '' || (DEFAULTSHOP.includes(sale.shop) && getPersonID(sale.client) === '') ? '' : <div className="fullwidth">{STR_Client}: {getPersonID(sale.client)}</div>}
            {sale.note === '' ? '' : <div className="clearboth fullwidth paddingtb">{STR_Info}: {<span className='warning'>{sale.note}</span>}</div>}
            <br />
            <table className="fullwidth paddingtb largerX">
                <thead>
                    <tr className="bordertop borderbottom">
                        <td className="textcenter">{STR_QTY}</td>
                        <td className="textcenter">{STR_Items.toUpperCase()}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr><td colSpan={3}>&nbsp;</td></tr>
                    {sale.items.map((item, idx) => printItemDO(idx, item))}
                    <tr><td colSpan={3}>&nbsp;</td></tr>
                    <tr><td colSpan={3}>&nbsp;</td></tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td className="textcenter largerX bold bordertop" colSpan={3}>{STR_Total.toUpperCase()} {STR_Items.toUpperCase()}</td>
                    </tr>
                    <tr>
                        <td className="textcenter large bold" colSpan={3}><b>{simpleItemCountInfo([{ sale: sale, data: '' }])}</b></td>
                    </tr>
                </tfoot>
            </table>
            <div className="textcenter smaller fullwidth bordertop">{printThx(sale)}</div>
        </div>
    );
}

function printShipLabel(sale: Sale) {
    let person = sale.client;
    let kurir = sale.shipment;
    let resi = sale.shipmentNo;

    return (
        <div className="fullwidth padding10 printlabel">
            {printSaleHeader(sale, sale.dropshipper.startsWith(DROPSHIPTAG))}
            {resi === '' ? '' : <div className="fullwidth middle center"><svg id={'ID' + sale.shipmentNo}></svg></div>}
            {resi === '' ? '' : <div className="center fullwidth large box">{resi}</div>}
            {sale.cod && sale.cod > 0 ?
                <div className="center fullwidth paddingtb large box">{STR_COD + ' : ' + formatCurrency(getShop(sale.shop)?.currency!, sale.cod)}</div> : ''
            }
            <div>
                <div className="fleft minw30">{STR_TO}</div>
                <div className="fright minw30">{kurir}</div>
            </div>
            {person.name.length > 0 ?
                <div className="center fullwidth paddingtb large">
                    {person.name}<br />
                    {person.telp}<br />
                    {person.address}
                </div> : <div className="center fullwidth paddingtb large"></div>}
            {person.info === '' ? '' :
                <div className="center fullwidth paddingtb large box">{person.info.split(';').map((info) =>
                    <div className="center fullwidth">{info}</div>
                )}</div>
            }
            <div className="center marginb"><img itemID={(printmode.state && !mprint) || sale.items.length > 4 || !sale.safepack ? "fragiles" : "fragile"} alt='fragile' src={(printmode.state && !mprint) || sale.items.length > 4 || !sale.safepack ? 'assets/img/fragile_s.jpg' : 'assets/img/fragile.jpeg'}></img></div>
            <div className="center smaller fullwidth bordertop">{printThx(sale)}</div>
            {sale.safepack ?
                <div className="center warning fullwidth paddingtb large">
                    !!! {STR_SafePack} !!!
                </div>
                : ''}
        </div>
    );
}

function generateAddress(person: Person) {
    try {
        var doc = new jsPDF('p', 'pt', [136, 793]); //48x210mm //136pt
        let xOffset = 0;
        let yOffset = 0;
        let width = doc.internal.pageSize.width - 1;// - 9;
        doc.setFont('helvetica', "normal");
        doc.setFontSize(8);

        let sale = initSale();
        sale.client = person
        makeShipLabel(sale, doc, xOffset, yOffset, width).then(() => printPDF(doc));
    } catch (error) {
        alert(STR_ErrorPrinting + " : " + error);
    }
}

function printAddress(person: Person) {
    return (
        <div className="fullwidth padding10 printlabel">
            {printHeader(null)}
            <div>
                <div className="fleft minw30">{STR_TO}</div>
                <div className="fright minw30"></div>
            </div>
            <div className="center fullwidth paddingtb large">
                {person.name}<br />
                {person.telp}<br />
                {person.address}
            </div>
            {person.info === '' ? '' :
                <div className="center fullwidth paddingtb large box">{person.info.split(';').map((info) =>
                    <div className="center fullwidth">{info}</div>
                )}</div>
            }
            <div className="center marginb"><img itemID="fragile" alt='fragile' src='assets/img/fragile.jpeg'></img></div>
            <div className="center smaller fullwidth bordertop"></div>
        </div>
    );
}

export function printShipment(person: Person) {
    if (printmode.state) {
        generateAddress(person);
    }
    else {
        const link = document.createElement('div');
        link.className = 'printlabel';
        document.body.appendChild(link);
        const element = printAddress(person);
        ReactDOM.render(element, link);
        return downloadJpg(null, link, true, true).then(() => ReactDOM.unmountComponentAtNode(link));
    }
}

export function printShipmentLabel(sale: Sale) {
    if (printmode.state) {
        generateLabel(sale);
    }
    else {
        const link = document.createElement('div');
        link.className = 'printlabel';
        document.body.appendChild(link);
        const element = printShip(sale);
        ReactDOM.render(element, link);

        if (sale.shipmentNo !== '') {
            JsBarcode('#ID' + sale.shipmentNo, sale.shipmentNo, {
                displayValue: false,
                width: 3
            });
        }

        return downloadJpg(null, link, true, true).then(() => ReactDOM.unmountComponentAtNode(link));
    }
}

export function getShipmentLabel(sale: Sale) {
    const link = document.createElement('div');
    link.className = 'printlabel';
    document.body.appendChild(link);
    const element = printShip(sale);
    ReactDOM.render(element, link);

    if (sale.shipmentNo !== '') {
        JsBarcode('#ID' + sale.shipmentNo, sale.shipmentNo, {
            displayValue: false,
            width: 3
        });
    }
    return htmltoimage(link);
}

function printShip(sale: Sale) {
    return (
        <div className="printlabel">
            {printShipLabel(sale)}
        </div>
    );
}

function printboth(sale: Sale) {
    return (
        <div className="printlabel">
            {(sale.dropshipper !== '' || DOMode) ? !isPO(sale) ? printDeliveryOrder(sale) : printReceipt(sale) : printReceipt(sale)}
            <br /> <br /> <br />
            {printShipLabel(sale)}
        </div>
    );
}

export function printLabel(sale: Sale, withShipping: boolean, addToMPrint: boolean) {
    const link = document.createElement('div');
    link.className = 'printlabel';
    document.getElementById("labels")!.appendChild(link);
    const element = withShipping ? printboth(sale) : (sale.dropshipper !== '' || DOMode) ? !isPO(sale) ? printDeliveryOrder(sale) : printReceipt(sale) : printReceipt(sale);
    ReactDOM.render(element, link);
    if (withShipping && sale.shipmentNo !== '') {
        JsBarcode('#ID' + sale.shipmentNo, sale.shipmentNo, {
            displayValue: false,
            width: 3
        });
    }
    return downloadJpg(sale, link, addToMPrint, withShipping).then((printdata) => { ReactDOM.unmountComponentAtNode(link); return printdata });
}

const macAddress = '66:22:FB:CB:66:DD';

function printBTenable(output: any) {
    try {
        BluetoothSerial.isEnabled().then(() => {
            startprint(output);
        }, () => {
            alert("bluetooth is not enabled trying to enable it");
            BluetoothSerial.enable().then(() => {
                startprint(output);
            }, () => {
                alert("bluetooth did not enabled");
            })
        });
    } catch (error) {
        alert("printing failed");
    }
}

function startprint(output: any) {
    //1. Try connecting to bluetooth printer
    BluetoothSerial.connect(macAddress).subscribe(_ => {
        //2. Connected successfully
        BluetoothSerial.write(output)
            .then(_ => {
                alert('Successfully printed!');
                //3. Print successful
                //If you want to tell user print is successful,
                //handle it here
                //4. IMPORTANT! Disconnect bluetooth after printing
                BluetoothSerial.disconnect();
            }, err => {
                alert("printing error");
                //If there is an error printing to bluetooth printer
                //handle it here
            })
    }, err => {
        alert("error connecting to bluetooth printer");
        //If there is an error connecting to bluetooth printer
        //handle it here
    })
}

export function printBT(sale: Sale, dataurl: string) {
    printescpos(sale, dataurl);
}

function printescpos(sale: Sale, dataurl: string) {
    try {
        if (sale._id.length > 0) {
            printSalesDone([{ sale: sale!, data: '' }], false, OFFLINE, printSimpleDate(new Date().toISOString()));
            addprintedlist([sale!]);

            let EscPosEncoder = require('esc-pos-encoder');

            let encoder = new EscPosEncoder();

            let img = new Image();
            img.src = dataurl;

            img.onload = function () {
                let width = 392;
                let height = ((width / img.width) * img.height);
                let t = Math.round(height / 8);
                height = 8 * t;

                let result = encoder
                    .initialize()
                    .image(img, width, height, 'atkinson')
                    .newline()
                    .newline()
                    .newline();

                printBTenable(result.encode());
            }
        }
    } catch (error) {
        alert(STR_ErrorPrinting);
    }
}

export function printMultipleLabels() {
    if (toPrint.length > 0) {
        user.setLoading(true);
        var doc = new jsPDF('l', 'mm'); //[297, 210]

        let xOffset = 298; //- 4;
        let yOffset = 0; //10

        let max = 6;
        let j = 0;
        for (var i = 0; i < toPrint.length; i++) {
            let img = new Image();
            img.src = toPrint[i].data;
            img.width = 48; //45
            doc.addImage(img, 'jpeg', xOffset - img.width, yOffset, img.width, img.height, toPrint[i].sale._id);
            xOffset = xOffset - img.width - 2; //xOffset + img.width + 4;
            j = j + 1;
            if (j === max && i < (toPrint.length - 1)) {
                xOffset = 298; //- 4;
                doc.addPage();
                j = 0;
            }
            else {
                doc.line(xOffset + 1, 0, xOffset + 1, 10);
                doc.line(xOffset + 1, doc.internal.pageSize.height, xOffset + 1, doc.internal.pageSize.height - 10);
            }
        }
        let date = new Date();
        let n = STR_Labels + '-' + printDate(date.toISOString());
        downloadPDF(doc, n + '.pdf');
        printSalesDone(toPrint, false, '', printSimpleDate(date.toISOString())); //be carefull, don't change staff => clear mprint
    }
}

function printItem(idx: number, item: SalesItem) {
    return (
        <>
            <tr key={idx}>
                <td colSpan={3}>{item.item.ProductName}</td>
            </tr>
            <tr>
                <td className="textcenter">{item.quantity}</td>
                <td className="textcenter">{item.price}</td>
                <td className="textcenter">{item.quantity * item.price}</td>
            </tr>
        </>
    );
}

function printItemDO(idx: number, item: SalesItem) {
    return (
        <tr key={idx}>
            <td className="textcenter">{item.quantity}</td>
            <td>{item.item.ProductName}</td>
        </tr>
    );
}

export function printList(list: any[], title: string, ohneQ: number, withFoto: boolean, date: Date) {
    if (ohneQ === 0 || withFoto) {
        setItemsImage(list).then(() => generateListPDF(list, title, ohneQ === 0 ? true : false, withFoto, date));
    }
    else generateListPDF(list, title, ohneQ === 0 ? true : false, withFoto, date);
}

function fillData(sales: Sale[], total: number, topay: number, profit: number, cat: string[]) {
    let res = [];
    let i = 1;
    for (var s = sales.length - 1; s >= 0; s--) {
        let sale = sales[s];
        let info = sale.items.length > 1 ? '-' + sale.items.length : '';
        let total1 = 0;
        let profit1 = 0;
        let index = -1;
        if (sale.items.length > 0) {
            for (var j = 0; j < sale.items.length; j++) {
                let item = sale.items[j];
                if (inCategory(item.item, cat)) {
                    if (index === -1) index = res.length;
                    total1 = total1 + (item.price * item.quantity);
                    profit1 = profit1 + item.profit;
                    res.push([i++, '', '', item.item.ProductName, item.quantity, formatCurrency(RUPIAH, item.price), '', '']);
                }
            };
            res[index][1] = getNumberID(sale._id) + info;
            res[index][2] = printDateS(sale.date);
            res[index][6] = formatCurrency(RUPIAH, total1);
            res[index][7] = formatCurrency(RUPIAH, total1 - profit1);
            res[index][8] = printDateS(sale.paid!);
        }
    };
    res.push(['', '', '', '', '', STR_Total, formatCurrency(RUPIAH, total), formatCurrency(RUPIAH, topay), formatCurrency(RUPIAH, total - profit - topay)]);
    return res;
}

export function generatePDF(cat: string[], trx: Sale[], total: number, profit: number, topay: number) {
    var doc = new jsPDF('l', 'pt');

    doc.setFontSize(24);
    doc.setFont('helvetica', 'bold');
    doc.setTextColor('#3792cb');
    //doc.text(STR_MyShop, 40, 40);

    let img = new Image();
    img.src = 'assets/img/mekuyaLogo.png';
    img.height = 42;
    img.width = 150;
    doc.addImage(img, 'PNG', 40, 6, img.width, img.height);

    let text = printDateMonth(trx[0].date);
    let xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 40);
    doc.text(text, xOffset, 40);

    doc.setFontSize(20);

    doc.setFontSize(14);
    text = getCategoryNames(cat);
    xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(text) * doc.getFontSize() / 2);
    doc.text(text, xOffset, 40);

    doc.setFontSize(20);

    const head = [[STR_No, STR_ID, STR_Date, STR_Name, STR_Quantity, STR_Price, STR_Total, STR_Topay, STR_Paid]]
    const data = fillData(trx, total, topay, profit, cat);

    autoTable(doc, {
        head: head,
        body: data,
        startY: 50,
        columnStyles: {
            3: { cellWidth: 250 }
        },
        didParseCell: (data) => {
            if (data.row.section === 'body' && data.column.index === 6) {
                data.cell.styles.fillColor = '#3792cb';
                data.cell.styles.textColor = 'white';
                data.cell.styles.fontStyle = 'bold';
            }
            if (data.row.section === 'body' && (data.column.index === 5 || data.column.index === 7 || data.column.index === 8) && data.row.cells[5].raw === STR_Total) {
                data.cell.styles.fillColor = [216, 78, 75];
                data.cell.styles.textColor = 'white';
                data.cell.styles.fontStyle = 'bold';
            }
            if (data.column.index === 4 || (data.row.section === 'head' && data.column.index === 5) || (data.row.section === 'head' && data.column.index === 6) || (data.row.section === 'head' && data.column.index === 7)) {
                data.cell.styles.halign = 'center';
            }
            if ((data.row.section === 'body' && data.column.index === 5) || (data.row.section === 'body' && data.column.index === 6) || (data.row.section === 'body' && data.column.index === 7)) {
                data.cell.styles.halign = 'right';
            }
        }
    })

    addFooters(doc);

    let n = cat[0] + '-' + printDateMonth(trx[0].date) + '.pdf';
    downloadPDF(doc, n);
}

function fillSale(sale: Sale) {
    let res = [];
    let i = 1;
    for (var s = 0; s < sale.items.length; s++) {
        let sitem = sale.items[s];
        let pname = sitem.item.ProductName;
        if (isPurchase(sale) && sitem.item.Category === CATCHX) {
            pname = '[' + sitem.item._id + '] ' + sitem.item.ProductName;
        }
        res.push([i++, pname, sitem.quantity, formatCurrency(RUPIAH, sitem.item.SalePrice), formatCurrency(RUPIAH, sitem.item.PurchasePrice)]);
    };
    return res;
}

function fillInvoice(sale: Sale) {
    let res = [];
    let i = 1;
    for (var s = 0; s < sale.items.length; s++) {
        let sitem = sale.items[s];
        let pname = sitem.item.ProductName;
        if (isPurchase(sale) && sitem.item.Category === CATCHX) {
            pname = '[' + sitem.item._id + '] ' + sitem.item.ProductName;
        }
        res.push([i++, pname, sitem.quantity, formatCurrency(RUPIAH, sitem.price), formatCurrency(RUPIAH, sitem.quantity * sitem.price)]);
    };
    return res;
}

const addHeader = (doc: jsPDF, notbias: boolean) => {
    let img = new Image();
    img.src = notbias ? 'assets/img/mekuyaLogo.png' : 'assets/img/BiasLogo.jpg';

    let dimensions = { w: img.width, h: img.height };
    img.height = 120;
    img.width = (120 / dimensions.h) * dimensions.w;

    let xOffset = img.width === 0 ? (doc.internal.pageSize.width / 2) - 215.5 : (doc.internal.pageSize.width / 2) - (img.width / 2);
    doc.addImage(img, 'PNG', xOffset, 30, img.width, img.height);

    let text = notbias ? 'Mekuya' : '';
    xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(text) * doc.getFontSize() / 2);
    doc.text(text, xOffset, 150);

    text = notbias ? 'www.mekuya.com' : 'www.bias-acc.com';
    xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(text) * doc.getFontSize() / 2);
    doc.text(text, xOffset, 170);

    text = notbias ? 'HP/WA: 0895371903558' : "HP/WA: 0895 24 0000 20";
    xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(text) * doc.getFontSize() / 2);
    doc.text(text, xOffset, 190);

    doc.line(40, 200, doc.internal.pageSize.width - 40, 200);
    return 200;
}

const addLogo = (doc: jsPDF, sale: Sale) => {
    let bias = sale.shop === BIAS;
    let megakon = sale.shop == MEGAKON;
    let img = new Image();
    img.src = bias ? 'assets/img/BiasINVLogo.jpg' : megakon ? 'assets/img/MegakonLogo.jpg' : 'assets/img/MekuyaINVLogo.jpg';
    img.height = 96;
    img.width = 250;
    doc.addImage(img, 'PNG', 40, 60, img.width, img.height);
}

function addHeaderInvoice(doc: jsPDF, sale: Sale) {
    let bias = sale.shop === BIAS;
    let megakon = sale.shop == MEGAKON;
    addLogo(doc, sale);

    doc.setFontSize(24);
    doc.setFont('helvetica', 'bold');
    doc.setTextColor('#000000');

    let text = sale.status === STR_Paid ? STR_Receipt : STR_Tagihan;
    let xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 40);
    let yOffset = 60;
    doc.text(text, xOffset, yOffset); //60

    doc.setFontSize(10.5);

    text = bias ? 'BIAS ACCESSORIES' : megakon ? "PT MEGAKON" : "Mekuya";
    xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 40);
    yOffset = yOffset + 26;
    doc.text(text, xOffset, yOffset); //80

    doc.setFont('helvetica', 'normal');
    /*text = "NPWP: 92.886.956.9-422.000";
    xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 40);
    doc.text(text, xOffset, 70);*/
    text = bias ? BIASREK : "Jl. Nawawi No. 9";
    xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 40);
    yOffset = yOffset + 14;
    doc.text(text, xOffset, yOffset); //100
    text = bias ? '' : "Bandung 40221";
    xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 40);
    yOffset = yOffset + 12;
    doc.text(text, xOffset, yOffset); //112

    /*text = "Tel: 022 20583938";
    xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 40);
    doc.text(text, xOffset, 124);*/
    text = bias ? "HP/WA: 0895 24 0000 20" : "HP/WA: 0895 371 903 558";
    xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 40);
    yOffset = yOffset + 24;
    doc.text(text, xOffset, yOffset); //136
    text = bias ? "www.bias-acc.com" : megakon ? "www.megakon.mekuya.com" : "www.mekuya.com";
    xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 40);
    yOffset = yOffset + 12;
    doc.text(text, xOffset, yOffset); //148

    doc.setDrawColor('#a7adba');
    yOffset = yOffset + 12;
    doc.line(0, yOffset, doc.internal.pageSize.width, yOffset);
    return yOffset;
}

const addLunas = (doc: jsPDF, yOffset: number) => {
    let img = new Image();
    img.src = 'assets/img/stempel-lunas.jpg';
    img.height = 95;
    img.width = 95;
    let xOffset = (doc.internal.pageSize.width - img.width - 20);
    doc.addImage(img, 'PNG', xOffset, yOffset, img.width, img.height);
}

function addItem(doc: jsPDF, item: Item, yOffset: number) {
    let img = new Image();
    img.src = getImageURL(item);
    img.height = 85;
    img.width = 85;
    try { doc.addImage(img, 'PNG', 50, yOffset, img.width, img.height); } catch (err) { }
    let xOffset = img.width + 60;

    doc.setFont('helvetica', 'bold');
    doc.setFontSize(14);
    doc.setTextColor('#000000');

    yOffset = yOffset + 23;

    let text = item.ProductName;
    var split = doc.splitTextToSize(text, 400);
    doc.text(split, xOffset, yOffset);

    yOffset = yOffset + 32;

    doc.setFont('helvetica', 'bold');
    doc.setFontSize(8);
    doc.setTextColor('#65737e');

    if (inventoryID === LIST_BIASINVENTORY) {
        text = STR_MSRP;
        doc.text(text, xOffset + 50, yOffset);
    }

    text = STR_Price;
    doc.text(text, xOffset + 260, yOffset);

    yOffset = yOffset + 17;

    doc.setFont('helvetica', 'normal');
    doc.setFontSize(16);
    doc.setTextColor('#000000');

    if (inventoryID === LIST_BIASINVENTORY) {
        text = formatCurrency(RUPIAH, item.MSRPPrice);
        doc.text(text, xOffset + 35, yOffset);
    }

    text = formatCurrency(RUPIAH, item.SalePrice);
    doc.text(text, xOffset + 235, yOffset);

    yOffset = yOffset + 35;
    return yOffset;
}

const addSimpleHeader = (doc: jsPDF, notbias: boolean) => {
    let img = new Image();
    img.src = notbias ? 'assets/img/mekuyaLogo.png' : 'assets/img/BiasLogoINV.jpg';
    let dimensions = { w: img.width, h: img.height };
    img.width = 126;
    img.height = (126 / dimensions.w) * dimensions.h;
    let xOffset = (doc.internal.pageSize.width / 2) - (img.width / 2);
    doc.addImage(img, 'PNG', xOffset, 10, img.width, img.height);
    doc.line(40, 45, doc.internal.pageSize.width - 40, 45);
    return 40;
}

const addFooters = (doc: jsPDF) => {
    const pageCount = doc.internal.pages.length;

    for (var i = 1; i <= pageCount - 1; i++) {
        doc.setPage(i)
        doc.setFont('helvetica', "normal");
        doc.setFontSize(12);
        let text = i + '/' + (pageCount - 1);
        let xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 40);
        doc.text(text, xOffset, doc.internal.pageSize.height - 15);
    }
}

const addShipment = (doc: jsPDF, sale: Sale) => {
    const pageCount = doc.internal.pages.length;

    for (var i = 1; i <= pageCount - 1; i++) {
        doc.setPage(i)
        doc.setFont('helvetica', "normal");
        doc.setFontSize(12);
        let text = sale.shipment + ' : ' + formatCurrency(RUPIAH, sale.shipping);
        doc.text(text, 40, doc.internal.pageSize.height - 15);
    }
}

const addDate = (doc: jsPDF, date: Date) => {
    const pageCount = doc.internal.pages.length;

    for (var i = 1; i <= pageCount - 1; i++) {
        doc.setPage(i)
        doc.setFont('helvetica', "normal");
        doc.setFontSize(12);
        let text = printDate(date.toISOString());
        doc.text(text, 40, doc.internal.pageSize.height - 15);
    }
}

const addDate1 = (doc: jsPDF, date: Date) => {
    doc.setPage(1)
    doc.setFont('helvetica', "normal");
    doc.setFontSize(12);
    let text = printDate(date.toISOString());
    doc.text(text, 40, doc.internal.pageSize.height - 15);
}

export async function invoicePDF(sale: Sale, shipmentlabel: boolean) {
    //printSaleDone(sale, false);
    printSalesDone([{ sale: sale, data: '' }], false, user.staff, printSimpleDate(new Date().toISOString()))

    var doc = new jsPDF('p', 'pt');

    let yOffset = addHeaderInvoice(doc, sale);

    doc.setFont('helvetica', 'bold');
    doc.setTextColor('#65737e');
    doc.text(STR_Customer, 40, yOffset + 24);
    let text = "No :  ";
    let text2 = sale.shop.substring(0, 1) + printDateDay(sale.date) + getNumberID(sale._id);
    let xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text + text2) * doc.getFontSize()) - 40);
    doc.text(text, xOffset, yOffset + 24);
    let text1 = STR_InvDate;
    let text3 = printDateS(sale.date);
    xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text1 + text3) * doc.getFontSize()) - 40);
    doc.text(text1, xOffset, yOffset + 36);
    let text4 = sale.shipment !== '' ? STR_InvKurir : '';
    let text5 = sale.shipment;
    xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text4 + text5) * doc.getFontSize()) - 40);
    doc.text(text4, xOffset, yOffset + 60);
    let text8 = sale.shipmentNo !== '' ? STR_InvResi : '';
    let text9 = sale.shipmentNo;
    xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text8 + text9) * doc.getFontSize()) - 40);
    doc.text(text8, xOffset, yOffset + 72);
    let text6 = sale.status !== STR_Paid ? "Total Tagihan :  " : '';
    let text7 = sale.status !== STR_Paid ? formatCurrency(getShop(sale.shop)?.currency!, sale.total - sale.dp + +sale.shipping) : '';//sale.shipping > 0 ? formatCurrency(RUPIAH, sale.shipping) : '';
    xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text6 + text7) * doc.getFontSize()) - 40);
    doc.text(text6, xOffset, yOffset + 86);
    doc.setTextColor('#000000');
    doc.text(getPersonID(sale.client), 40, yOffset + 36);

    doc.setFont('helvetica', 'normal');
    xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text2) * doc.getFontSize()) - 40);
    doc.text(text2, xOffset, yOffset + 24);
    xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text3) * doc.getFontSize()) - 40);
    doc.text(text3, xOffset, yOffset + 36);
    xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text5) * doc.getFontSize()) - 40);
    doc.text(text5, xOffset, yOffset + 60);
    xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text9) * doc.getFontSize()) - 40);
    doc.text(text9, xOffset, yOffset + 72);
    xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text7) * doc.getFontSize()) - 40);
    doc.text(text7, xOffset, yOffset + 86);

    doc.text(sale.client.name, 40, yOffset + 60);
    doc.text(sale.client.telp, 40, yOffset + 72);
    text = sale.client.address;
    let mtext = text.split(', ');
    yOffset = yOffset + 72;
    for (var i = 0; i < mtext.length; i++) {
        yOffset = yOffset + 12;
        doc.text(mtext[i], 40, yOffset);
    }
    if (sale.client.info.length > 0) {
        yOffset = yOffset + 24;
        doc.setFont('helvetica', 'bold');
        doc.setTextColor('#65737e');
        doc.text(STR_Catatan, 40, yOffset);
        let notelength = doc.getStringUnitWidth(STR_Catatan) * doc.getFontSize();
        doc.setTextColor('#000000');
        doc.setFont('helvetica', 'italic');
        printtext(sale.client.info, notelength + 40, yOffset, 2 * (notelength + 40), doc);
        doc.setFont('helvetica', 'normal');
    }

    yOffset = yOffset + 12;

    const head = [[STR_No, 'Nama', 'Jumlah', 'Harga', 'Total']]
    const data = fillInvoice(sale);
    data.push(['', '', '', 'Total :', formatCurrency(RUPIAH, sale.total + sale.discount)]);

    autoTable(doc, {
        head: head,
        body: data,
        startY: yOffset + 20,
        columnStyles: {
            3: { minCellWidth: 85 },
            4: { minCellWidth: 85 }
        },
        didParseCell: (data) => {
            if (data.row.section === 'head') {
                data.cell.styles.fillColor = '#343d46';
            }
            if (data.column.index === 2 || (data.row.section === 'head' && data.column.index === 3) || (data.row.section === 'head' && data.column.index === 4)) {
                data.cell.styles.halign = 'center';
            }
            if ((data.row.section === 'body' && data.column.index === 3) || (data.row.section === 'body' && data.column.index === 4)) {
                data.cell.styles.halign = 'right';
            }
            if (data.row.section === 'body' && (data.column.index === 3 || data.column.index === 4) && data.row.cells[3].raw === 'Total :') {
                data.cell.styles.fillColor = '#343d46';
                data.cell.styles.textColor = 'white';
                data.cell.styles.fontStyle = 'bold';
            }
            if (data.row.section === 'body' && (data.column.index === 0 || data.column.index === 1 || data.column.index === 2) && data.row.cells[3].raw === 'Total :') {
                data.cell.styles.fillColor = '#FFFFFF';
            }
        }
    })

    yOffset = (doc as any).lastAutoTable.finalY + 3;

    doc.setLineWidth(2);

    if (sale.discount > 0) {
        yOffset = yOffset + 12;
        text = sale.discount > 0 ? STR_Discount + " :  " : '';
        xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 159);
        doc.text(text, xOffset, yOffset);
        text = formatCurrency(RUPIAH, sale.discount);
        xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 45);
        doc.text(text, xOffset, yOffset);

        yOffset = yOffset + 10;
        xOffset = (doc.internal.pageSize.width - 295);
        doc.line(xOffset, yOffset, doc.internal.pageSize.width - 40, yOffset);

        yOffset = yOffset + 17;
        text = formatCurrency(RUPIAH, sale.total);
        xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 45);
        doc.text(text, xOffset, yOffset);
        yOffset = yOffset + 12;
    }

    if (sale.dp > 0) {
        yOffset = yOffset + 12;
        text = sale.dp > 0 ? "DP :  " : '';
        xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 159);
        doc.text(text, xOffset, yOffset);
        text = formatCurrency(RUPIAH, sale.dp);
        xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 45);
        doc.text(text, xOffset, yOffset);

        yOffset = yOffset + 10;
        xOffset = (doc.internal.pageSize.width - 295);
        doc.line(xOffset, yOffset, doc.internal.pageSize.width - 40, yOffset);

        yOffset = yOffset + 17;
        text = formatCurrency(RUPIAHZ, sale.total - sale.dp);
        xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 45);
        doc.text(text, xOffset, yOffset);
        yOffset = yOffset + 12;
    }

    if (sale.shipping > 0) {
        yOffset = yOffset + 12;
        text = sale.shipping > 0 ? STR_Ongkir + " :  " : '';
        xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 159);
        doc.text(text, xOffset, yOffset);
        text = formatCurrency(RUPIAH, sale.shipping);
        xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 45);
        doc.text(text, xOffset, yOffset);

        yOffset = yOffset + 12;
        xOffset = (doc.internal.pageSize.width - 295);
        doc.line(xOffset, yOffset, doc.internal.pageSize.width - 40, yOffset);

        yOffset = yOffset + 17;
        text = formatCurrency(RUPIAH, sale.total - sale.dp + +sale.shipping);
        xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 45);
        doc.text(text, xOffset, yOffset);
        yOffset = yOffset + 12;
    }
    if (sale.status === STR_Paid) addLunas(doc, yOffset);

    if (sale.note !== '') {
        text = STR_Catatan;
        xOffset = 40;
        doc.setFont('helvetica', 'bold');
        doc.setTextColor('#65737e');
        doc.text(text, xOffset, doc.internal.pageSize.height - 100);
        doc.setTextColor('#000000');
        doc.setFont('helvetica', 'italic');
        doc.text(sale.note, xOffset + doc.getStringUnitWidth(text) * doc.getFontSize(), doc.internal.pageSize.height - 100, { maxWidth: doc.internal.pageSize.width - doc.getStringUnitWidth(text) * doc.getFontSize() - 80 });
        doc.setFont('helvetica', 'normal');
    }

    doc.line(0, doc.internal.pageSize.height - 42, doc.internal.pageSize.width, doc.internal.pageSize.height - 42);
    text = sale.status === STR_Paid ? STR_FooterReceipt : STR_FooterInvoice;
    xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(text) * doc.getFontSize() / 2);
    doc.text(text, xOffset, doc.internal.pageSize.height - 30);

    if (shipmentlabel && isMedis(sale)) {
        doc.addPage();

        xOffset = 0;
        yOffset = 0;

        let shiplabel = await getShipmentLabel(sale);
        let img = new Image();
        img.src = shiplabel;
        var dimensions: any = await getImageDimensions(shiplabel);
        img.width = 135;
        img.height = (135 / dimensions.w) * dimensions.h;
        yOffset = yOffset + 5;
        doc.addImage(img, 'jpeg', xOffset + doc.internal.pageSize.width - img.height - 2.5, yOffset - img.height, img.width, img.height, sale._id, undefined, 270);

        doc.setFont('helvetica', 'bold');
        doc.setFontSize(30);
        text = '1 / 2 koli';
        doc.text(text, xOffset + doc.internal.pageSize.width - img.height - 35, yOffset + 5, { angle: -90 });

        yOffset = yOffset + img.width;
        doc.line(0, yOffset + 2.5, doc.internal.pageSize.width, yOffset + 2.5);

        yOffset = yOffset + 5;
        doc.addImage(img, 'jpeg', xOffset + doc.internal.pageSize.width - img.height - 2.5, yOffset - img.height, img.width, img.height, sale._id, undefined, 270);
        text = '2 / 2 koli';
        doc.text(text, xOffset + doc.internal.pageSize.width - img.height - 35, yOffset + 5, { angle: -90 });

        yOffset = yOffset + img.width;
        doc.line(0, yOffset + 2.5, doc.internal.pageSize.width, yOffset + 2.5);
    }

    let n = sale.status === STR_Paid ? STR_Receipt1 + getNumberID(sale._id) + '-' + getPersonID(sale.client) + '-' + printDateS(sale.date) : STR_Invoice + getNumberID(sale._id) + '-' + getPersonID(sale.client) + '-' + printDateS(sale.date);
    downloadPDF(doc, n + '.pdf');
}

export function generatePurchasePDF(sale: Sale) {
    var doc = new jsPDF('p', 'pt');

    doc.setFontSize(24);
    doc.setFont('helvetica', 'bold');
    doc.setTextColor('#3792cb');
    doc.text(STR_MyShop, 40, 40);

    let text = printDateS(sale.date);
    let xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 40);
    doc.text(text, xOffset, 40);

    xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(text) * doc.getFontSize() / 2);
    doc.text(STR_Purchases, xOffset, 40);

    const head = [[STR_No, STR_Name, STR_Quantity, STR_Price, STR_Topay]]
    const data = fillSale(sale);

    autoTable(doc, {
        head: head,
        body: data,
        startY: 50,
        columnStyles: {
            3: { minCellWidth: 85 },
            4: { minCellWidth: 85 }
        },
        didParseCell: (data) => {
            if (data.row.section === 'body' && data.column.index === 3) {
                data.cell.styles.fillColor = '#3792cb';
                data.cell.styles.textColor = 'white';
                data.cell.styles.fontStyle = 'bold';
            }
            if (data.column.index === 2 || (data.row.section === 'head' && data.column.index === 3) || (data.row.section === 'head' && data.column.index === 4)) {
                data.cell.styles.halign = 'center';
            }
            if ((data.row.section === 'body' && data.column.index === 3) || (data.row.section === 'body' && data.column.index === 4)) {
                data.cell.styles.halign = 'right';
            }
        }
    })

    addShipment(doc, sale);
    addFooters(doc);

    let n = STR_Purchases + '-' + printDateS(sale.date);
    downloadPDF(doc, n + '.pdf');
}

function printItemCounts(data: { item: any; count: any; }, doc: jsPDF, yOffset: number) {
    let text = '';
    let xOffset = 40;

    for (var i = 0; i < data.item.length; i++) {
        text = data.count[i] + ' ' + data.item[i];
        yOffset = yOffset + 23;
        printtext(text, xOffset, yOffset, xOffset * 2, doc);
        if (yOffset > doc.internal.pageSize.height - 61) {
            doc.addPage();
            yOffset = 17;
        }
    }

    return yOffset;
}

/**
 * Auto Resize Text to fit 1 line
 * @param text 
 * @param xOffset 
 * @param yOffset 
 * @param limit 
 * @param doc 
 */
function printtext(text: string, xOffset: number, yOffset: number, limit: number, doc: jsPDF) {
    let size = doc.getFontSize();
    while ((doc.internal.pageSize.width - limit) < (doc.getStringUnitWidth(text) * doc.getFontSize())) {
        doc.setFontSize(doc.getFontSize() - 1);
    }
    doc.text(text, xOffset, yOffset);
    doc.setFontSize(size);
}

function getImageDimensions(file: string) {
    return new Promise(function (resolved, rejected) {
        var i = new Image()
        i.onload = function () {
            resolved({ w: i.width, h: i.height });
        };
        i.src = file;
    })
}

function getImageURI(file: string) {
    return new Promise(function (resolved, rejected) {
        var i = new Image();
        i.onload = function () {
            var canvas = document.createElement('canvas'), context = canvas.getContext('2d');
            canvas.width = i.width;
            canvas.height = i.height;
            if (context) {
                context.drawImage(i, 0, 0, i.width, i.height);
                resolved(canvas.toDataURL('image/jpg'));
            }
        };
        i.src = file;
    })
}

function makeHeader(sale: Sale, doc: jsPDF, xOffset: number, yOffset: number, width: number) {
    let bias = sale !== null && sale.shop === BIAS;
    let megakon = sale !== null && sale.shop === MEGAKON;

    let img = new Image();
    img.src = bias ? 'assets/img/BiasLogo.bmp' : megakon ? 'assets/img/MegakonLogo.bmp' : 'assets/img/mekuyaLogo.bmp';
    let dimensions = { w: img.width, h: img.height };
    img.width = width;
    img.height = (width / dimensions.w) * dimensions.h;
    doc.addImage(img, 'PNG', (width / 2) - (img.width / 2), yOffset, img.width, img.height);
    yOffset = yOffset + 42;

    doc.setFont('helvetica', "bold");
    doc.setFontSize(8);

    let text = '';
    if (bias) {
        text = 'www.bias-acc.com';
        doc.text(text, (width / 2) - (doc.getStringUnitWidth(text) * doc.getFontSize() / 2), yOffset);
        yOffset = yOffset + 10;
        text = 'HP/WA: 0895 24 0000 20';
        doc.text(text, (width / 2) - (doc.getStringUnitWidth(text) * doc.getFontSize() / 2), yOffset);
    }
    else if (megakon) {
        text = 'HP/WA: 0895 371 903 558';
        doc.text(text, (width / 2) - (doc.getStringUnitWidth(text) * doc.getFontSize() / 2), yOffset);
    }
    else {
        text = 'MEKUYA';
        doc.text(text, (width / 2) - (doc.getStringUnitWidth(text) * doc.getFontSize() / 2), yOffset);
        yOffset = yOffset + 10;
        text = 'www.mekuya.com';
        doc.text(text, (width / 2) - (doc.getStringUnitWidth(text) * doc.getFontSize() / 2), yOffset);
        yOffset = yOffset + 10;
        text = 'HP/WA: 0895 371 903 558';
        doc.text(text, (width / 2) - (doc.getStringUnitWidth(text) * doc.getFontSize() / 2), yOffset);
    }
    yOffset = yOffset + 5;
    doc.line(xOffset, yOffset, width - xOffset, yOffset);
    return yOffset + 7;
}

function makeDOHeader(sale: Sale, withname: boolean, doc: jsPDF, xOffset: number, yOffset: number, width: number) {
    doc.setFont('helvetica', "bold");
    doc.setFontSize(8);

    let text = '';
    if (sale.dropshipper !== '' && !withname) {
        yOffset = yOffset + 10;
        text = STR_DeliveryOrder;
        doc.text(text, (width / 2) - (doc.getStringUnitWidth(text) * doc.getFontSize() / 2), yOffset);
        yOffset = yOffset + 5;
        doc.line(xOffset, yOffset, width - xOffset, yOffset);
        return yOffset + 7;
    }
    else if (sale.dropshipper !== '' && withname) {
        yOffset = yOffset + 10;
        text = STR_Pengirim;
        doc.text(text, (width / 2) - (doc.getStringUnitWidth(text) * doc.getFontSize() / 2), yOffset);
        yOffset = yOffset + 10;
        text = sale.dropshipper.startsWith(DROPSHIPTAG) ? sale.dropshipper.substring(1) : sale.dropshipper;
        doc.text(text, (width / 2) - (doc.getStringUnitWidth(text) * doc.getFontSize() / 2), yOffset);
        yOffset = yOffset + 5;
        doc.line(xOffset, yOffset, width - xOffset, yOffset);
        return yOffset + 7;
    }
    else return makeHeader(sale, doc, xOffset, yOffset, width);
}

function makeSaleHeader(sale: Sale, withname: boolean, doc: jsPDF, xOffset: number, yOffset: number, width: number) {
    doc.setFont('helvetica', "bold");
    doc.setFontSize(8);

    if (sale.dropshipper !== '' && !withname) {
        return yOffset + 10; /*(
            <>
                <div className="center warning fullwidth borderbottom large">
                    !!! JANGAN DIMASUKIN PAKET !!!
                </div>
            </>
        );*/
    }
    else if (sale.dropshipper !== '' && withname) {
        yOffset = yOffset + 10;
        let text = STR_Pengirim;
        doc.text(text, (width / 2) - (doc.getStringUnitWidth(text) * doc.getFontSize() / 2), yOffset);
        yOffset = yOffset + 10;
        text = sale.dropshipper.startsWith(DROPSHIPTAG) ? sale.dropshipper.substring(1) : sale.dropshipper;
        doc.text(text, (width / 2) - (doc.getStringUnitWidth(text) * doc.getFontSize() / 2), yOffset);
        yOffset = yOffset + 5;
        doc.line(xOffset, yOffset, width - xOffset, yOffset);
        return yOffset + 7;
    }
    else return makeHeader(sale, doc, xOffset, yOffset, width);
}

function makeDeliveryOrder(sale: Sale, doc: jsPDF, xOffset: number, yOffset: number, width: number) {
    yOffset = makeDOHeader(sale, false, doc, xOffset, yOffset, width);

    doc.setFontSize(6);

    let text = STR_Folio + ': ' + getNumberID(sale._id);
    doc.text(text, xOffset, yOffset);

    text = sale.status ? STR_Status + ': ' + sale.status : STR_Status + ': ' + STR_Paid;
    doc.text(text, (width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - xOffset), yOffset);
    yOffset = yOffset + 6;

    text = STR_Date + ': ' + printDateS(sale.date);
    doc.text(text, xOffset, yOffset);
    yOffset = yOffset + 6;

    if (!(sale.dropshipper !== '' || (DEFAULTSHOP.includes(sale.shop) && getPersonID(sale.client) === ''))) {
        text = STR_Client + ': ' + getPersonID(sale.client);
        doc.text(text, xOffset, yOffset);
        yOffset = yOffset + 6;
    }

    yOffset = addsalenote(sale, xOffset, yOffset, width, doc);

    doc.line(xOffset, yOffset, width - xOffset, yOffset);
    yOffset = yOffset + 6;
    text = STR_QTY;
    doc.text(text, xOffset + 2, yOffset);
    text = STR_Items.toUpperCase();
    doc.text(text, xOffset + 20, yOffset);
    yOffset = yOffset + 2;
    doc.line(xOffset, yOffset, width - xOffset, yOffset);

    yOffset = yOffset + 10;

    for (var i = 0; i < sale.items.length; i++) {
        let item = sale.items[i];
        text = '' + item.quantity;
        doc.text(text, xOffset + 5, yOffset);
        text = item.item.ProductName;
        yOffset = linebreak(text, xOffset + 20, yOffset, false, width - 20 - (2 * xOffset), doc);
        yOffset = yOffset + 7;
    }

    let s = doc.getFontSize();
    doc.setFontSize(10);
    doc.line(xOffset, yOffset, width - xOffset, yOffset);
    yOffset = yOffset + 12;
    text = STR_Total.toUpperCase() + ' ' + STR_Items.toUpperCase();
    doc.text(text, (width / 2) - (doc.getStringUnitWidth(text) * doc.getFontSize() / 2), yOffset);
    yOffset = yOffset + 10;
    doc.setFont('helvetica', "bold");
    text = '' + simpleItemCountInfo([{ sale: sale, data: '' }]);
    doc.text(text, (width / 2) - (doc.getStringUnitWidth(text) * doc.getFontSize() / 2), yOffset);
    doc.setFont('helvetica', "normal");
    yOffset = yOffset + 5;
    doc.line(xOffset, yOffset, width - xOffset, yOffset);
    doc.setFontSize(s);

    yOffset = yOffset + 7;

    s = doc.getFontSize();
    doc.setFontSize(5);
    doc.setFont('helvetica', "italic");
    text = sale.dropshipper !== '' ? '' : sale.shop === BIAS ? STR_ThxBias : sale.shop === MEGAKON ? STR_ThxMegakon : STR_Thx;
    doc.text(text, (width / 2) - (doc.getStringUnitWidth(text) * doc.getFontSize() / 2), yOffset);
    doc.setFont('helvetica', "normal");
    doc.setFontSize(s);

    return yOffset + 10;
}

function addsalenote(sale: Sale, xOffset: number, yOffset: number, width: number, doc: jsPDF) {
    if (sale.note.length > 0) {
        let text1 = STR_Info + ': ';
        doc.text(text1, xOffset, yOffset);
        //let tc = doc.getTextColor();
        doc.setFont('helvetica', "bolditalic");
        //doc.setTextColor(255, 0, 0);
        let text = sale.note;

        doc.setLineDashPattern([3, 2, 1, 2], 10);
        yOffset = yOffset + 2;
        yOffset = addboxtext([text], xOffset, yOffset, 5, width, doc);
        doc.setLineDashPattern([], 0);

        //yOffset = linebreak(text, xOffset + (doc.getStringUnitWidth(text1) * doc.getFontSize()), yOffset, false, width - (doc.getStringUnitWidth(text1) * doc.getFontSize()) - (2 * xOffset), doc);
        //doc.setTextColor(tc);
        doc.setFont('helvetica', "bold");
        //yOffset = yOffset + 5;
    }
    return yOffset;
}

function makeReceipt(sale: Sale, doc: jsPDF, xOffset: number, yOffset: number, width: number) {
    yOffset = makeSaleHeader(sale, false, doc, xOffset, yOffset, width);

    doc.setFontSize(6);

    let text = STR_Folio + ': ' + getNumberID(sale._id);
    doc.text(text, xOffset, yOffset);

    text = sale.status ? STR_Status + ': ' + sale.status : STR_Status + ': ' + STR_Paid;
    doc.text(text, (width - (doc.getStringUnitWidth(text) * doc.getFontSize())), yOffset);
    yOffset = yOffset + 6;

    text = STR_Date + ': ' + printDateS(sale.date);
    doc.text(text, xOffset, yOffset);
    yOffset = yOffset + 6;

    if (!(DEFAULTSHOP.includes(sale.shop) && getPersonID(sale.client) === '')) {
        text = DEFAULTSHOP.includes(sale.shop) ? STR_Client + ': ' + getPersonID(sale.client) : STR_Client + ': ' + sale.shop + ' ' + getPersonID(sale.client)
    };
    doc.text(text, xOffset, yOffset);
    yOffset = yOffset + 6;

    yOffset = addsalenote(sale, xOffset, yOffset, width, doc);

    doc.line(xOffset, yOffset, width - xOffset, yOffset);
    yOffset = yOffset + 6;
    text = STR_Quantity.toUpperCase();
    doc.text(text, ((width / 3) / 2) - (doc.getStringUnitWidth(text) * doc.getFontSize() / 2), yOffset);
    text = STR_Price.toUpperCase();
    doc.text(text, (width / 3) + ((width / 3) / 2) - (doc.getStringUnitWidth(text) * doc.getFontSize() / 2), yOffset);
    text = STR_Subtotal + '(' + STR_RP + ')';
    doc.text(text, (width * 2 / 3) + ((width / 3) / 2) - (doc.getStringUnitWidth(text) * doc.getFontSize() / 2), yOffset);
    yOffset = yOffset + 2;
    doc.line(xOffset, yOffset, width - xOffset, yOffset);

    yOffset = yOffset + 10;

    for (var i = 0; i < sale.items.length; i++) {
        let item = sale.items[i];
        text = item.item.ProductName;
        yOffset = linebreak(text, xOffset, yOffset, false, width - (2 * xOffset), doc);

        yOffset = yOffset + 7;

        text = '' + item.quantity;
        doc.text(text, ((width / 3) / 2) - (doc.getStringUnitWidth(text) * doc.getFontSize() / 2), yOffset);
        text = '' + item.price;
        doc.text(text, (width / 3) + ((width / 3) / 2) - (doc.getStringUnitWidth(text) * doc.getFontSize() / 2), yOffset);
        text = '' + item.quantity * item.price;
        doc.text(text, (width * 2 / 3) + ((width / 3) / 2) - (doc.getStringUnitWidth(text) * doc.getFontSize() / 2), yOffset);
        yOffset = yOffset + 7;
    }

    doc.setFont('helvetica', "bold");

    if (sale.discount > 0) {
        doc.line(width / 4, yOffset, width - xOffset, yOffset);
        yOffset = yOffset + 7;
        text = STR_Total.toUpperCase() + ' :';
        doc.text(text, width / 4, yOffset);
        text = formatCurrency(getShop(sale.shop)?.currency!, sale.total + +sale.discount);
        doc.text(text, (width - (doc.getStringUnitWidth(text) * doc.getFontSize())), yOffset);
        yOffset = yOffset + 7;

        text = STR_Discount.toUpperCase() + ' :';
        doc.text(text, width / 4, yOffset);
        text = formatCurrency(getShop(sale.shop)?.currency!, sale.discount);
        doc.text(text, (width - (doc.getStringUnitWidth(text) * doc.getFontSize())), yOffset);
        yOffset = yOffset + 5;
    }

    if (sale.dp > 0) {
        doc.line(width / 4, yOffset, width - xOffset, yOffset);
        yOffset = yOffset + 7;
        text = formatCurrency(getShop(sale.shop)?.currency!, sale.total);
        doc.text(text, (width - (doc.getStringUnitWidth(text) * doc.getFontSize())), yOffset);
        yOffset = yOffset + 7;

        doc.line(width / 4, yOffset, width - xOffset, yOffset);
        yOffset = yOffset + 7;
        text = STR_DP.toUpperCase() + ' :';
        doc.text(text, width / 4, yOffset);
        text = formatCurrency(getShop(sale.shop)?.currency!, sale.dp);
        doc.text(text, (width - (doc.getStringUnitWidth(text) * doc.getFontSize())), yOffset);
        yOffset = yOffset + 5;
    }

    if (sale.shipping > 0) {
        doc.line(width / 4, yOffset, width - xOffset, yOffset);
        yOffset = yOffset + 7;
        text = formatCurrency(getShop(sale.shop)?.currency!, sale.total - sale.dp);
        doc.text(text, (width - (doc.getStringUnitWidth(text) * doc.getFontSize())), yOffset);
        yOffset = yOffset + 7;

        doc.line(width / 4, yOffset, width - xOffset, yOffset);
        yOffset = yOffset + 7;
        text = STR_Ongkir.toUpperCase() + ' :';
        doc.text(text, width / 4, yOffset);
        text = formatCurrency(getShop(sale.shop)?.currency!, sale.shipping);
        doc.text(text, (width - (doc.getStringUnitWidth(text) * doc.getFontSize())), yOffset);
        yOffset = yOffset + 5;
    }

    doc.setFont('helvetica', "normal");

    let s = doc.getFontSize();
    doc.setFontSize(10);
    doc.line(xOffset, yOffset, width - xOffset, yOffset);
    yOffset = yOffset + 12;
    doc.setFont('helvetica', "bold");
    text = STR_Total.toUpperCase() + ' ' + formatCurrency(getShop(sale.shop)?.currency!, sale.total - sale.dp + +sale.shipping);
    doc.text(text, (width / 2) - (doc.getStringUnitWidth(text) * doc.getFontSize() / 2), yOffset);
    doc.setFont('helvetica', "normal");
    yOffset = yOffset + 5;
    doc.line(xOffset, yOffset, width - xOffset, yOffset);
    doc.setFontSize(s);

    yOffset = yOffset + 7;

    s = doc.getFontSize();
    doc.setFontSize(5);
    doc.setFont('helvetica', "italic");
    text = sale.dropshipper !== '' ? '' : sale.shop === BIAS ? STR_ThxBias : sale.shop === MEGAKON ? STR_ThxMegakon : STR_Thx;
    doc.text(text, (width / 2) - (doc.getStringUnitWidth(text) * doc.getFontSize() / 2), yOffset);
    doc.setFont('helvetica', "normal");
    doc.setFontSize(s);

    return yOffset + 10;
}

async function makeShipLabel(sale: Sale, doc: jsPDF, xOffset: number, yOffset: number, width: number) {
    let person = sale.client;
    let kurir = sale.shipment;
    let resi = sale.shipmentNo;

    yOffset = makeSaleHeader(sale, sale.dropshipper.startsWith(DROPSHIPTAG), doc, xOffset, yOffset, width);

    let text = '';
    doc.setFont('helvetica', "bold");
    doc.setFontSize(9);
    let s = doc.getFontSize();

    if (resi.length > 0) {
        yOffset = await makeBarcode(sale, doc, xOffset, yOffset - 3, width);
        doc.setFontSize(8);
        text = resi;
        yOffset = addboxtext([text], xOffset, yOffset, 5, width, doc);
        doc.setFontSize(s);
    }

    if (sale.cod && sale.cod > 0) {
        doc.setFontSize(10);
        yOffset = yOffset - 3;
        text = STR_COD + ' : ' + formatCurrency(getShop(sale.shop)?.currency!, sale.cod);
        yOffset = addboxtext([text], xOffset, yOffset, 10, width, doc);
        doc.setFontSize(s);
    }

    yOffset = yOffset + 3;

    doc.setFontSize(8);

    text = STR_TO;
    doc.text(text, xOffset, yOffset);

    text = kurir;
    doc.text(text, (width - (doc.getStringUnitWidth(text) * doc.getFontSize())), yOffset);
    yOffset = yOffset + 20;

    doc.setFontSize(s);

    if (person.name.length > 0) {
        text = person.name;
        let offset = width - (2 * xOffset) < (doc.getStringUnitWidth(text) * doc.getFontSize()) ? width / 2 : (doc.getStringUnitWidth(text) * doc.getFontSize() / 2);
        yOffset = linebreak(text, (width / 2) - offset, yOffset, true, width - (2 * xOffset), doc);
        yOffset = yOffset + 10;
        text = person.telp;
        offset = width - (2 * xOffset) < (doc.getStringUnitWidth(text) * doc.getFontSize()) ? width / 2 : (doc.getStringUnitWidth(text) * doc.getFontSize() / 2);
        yOffset = linebreak(text, (width / 2) - offset, yOffset, true, width - (2 * xOffset), doc);
        yOffset = yOffset + 5;

        doc.setLineDashPattern([3, 3], 10);
        doc.line(xOffset, yOffset, width - xOffset, yOffset);
        doc.setLineDashPattern([], 0);
        yOffset = yOffset + 12;
        text = person.address;
        offset = width - (2 * xOffset) < (doc.getStringUnitWidth(text) * doc.getFontSize()) ? width / 2 : (doc.getStringUnitWidth(text) * doc.getFontSize() / 2);
        yOffset = linebreak(text, (width / 2) - offset, yOffset, true, width - (2 * xOffset), doc);

        yOffset = yOffset + 5;

        if (person.info.length > 0) {
            yOffset = addboxtext(person.info.split(';'), xOffset, yOffset, 10, width, doc);
        }

        yOffset = makefragile(sale, doc, xOffset, yOffset, width);

        let s = doc.getFontSize();
        doc.setFontSize(5);
        doc.setFont('helvetica', "italic");

        doc.line(xOffset, yOffset, width - xOffset, yOffset);
        yOffset = yOffset + 6;

        text = sale.dropshipper !== '' ? '' : sale.shop === BIAS ? STR_ThxBias : sale.shop === MEGAKON ? STR_ThxMegakon : STR_Thx;
        doc.text(text, (width / 2) - (doc.getStringUnitWidth(text) * doc.getFontSize() / 2), yOffset);
        doc.setFont('helvetica', "normal");
        doc.setFontSize(s);

        if (sale.safepack) {
            let s = doc.getFontSize();
            doc.setFontSize(10);
            doc.setFont('helvetica', "italic");
            yOffset = yOffset + 20;
            text = '!!! ' + STR_SafePack + ' !!!';
            doc.text(text, (width / 2) - (doc.getStringUnitWidth(text) * doc.getFontSize() / 2), yOffset);
            doc.setFont('helvetica', "normal");
            doc.setFontSize(s);
        }
    }

    return yOffset;
}

function addboxtext(infos: string[], xOffset: number, yOffset: number, spacing: number, width: number, doc: jsPDF) {
    let start = yOffset;
    doc.line(xOffset, yOffset, width - xOffset, yOffset);
    yOffset = yOffset + spacing + 3;
    for (var inf = 0; inf < infos.length; inf++) {
        let text = infos[inf];
        let offset = width - (2 * xOffset) < (doc.getStringUnitWidth(text) * doc.getFontSize()) ? width / 2 : (doc.getStringUnitWidth(text) * doc.getFontSize() / 2);
        yOffset = linebreak(text, (width / 2) - offset, yOffset, true, width, doc);
        yOffset = yOffset + spacing;
    }
    yOffset = yOffset - spacing / 2;
    doc.line(xOffset, start, xOffset, yOffset);
    doc.line(width - xOffset, start, width - xOffset, yOffset);
    doc.line(xOffset, yOffset, width - xOffset, yOffset);
    return yOffset + 5;
}

function makefragile(sale: Sale, doc: jsPDF, xOffset: number, yOffset: number, width: number) {
    let img = new Image();
    img.src = (printmode.state && !mprint) || sale.items.length > 4 || !sale.safepack ? 'assets/img/fragile_s.bmp' : 'assets/img/fragile.bmp';
    let dimensions = { w: img.width, h: img.height };
    img.width = width;
    img.height = (width / dimensions.w) * dimensions.h;
    doc.addImage(img, 'PNG', xOffset + (width / 2) - (img.width / 2), yOffset, img.width, img.height);
    return yOffset + 42;
}

function makeBarcode(sale: Sale, doc: jsPDF, xOffset: number, yOffset: number, width: number) {
    const link = document.createElement('div');
    link.className = 'printlabel';
    document.body.appendChild(link);
    const element = <div className="fullwidth middle center"><svg id={'IDBarcode' + sale.shipmentNo}></svg></div>;
    ReactDOM.render(element, link);

    if (sale.shipmentNo !== '') {
        JsBarcode('#IDBarcode' + sale.shipmentNo, sale.shipmentNo, {
            displayValue: false,
            width: 3
        });
    }
    return htmltoimage(link).then(function (dataUrl) {
        let img = new Image();
        img.src = dataUrl;
        let dimensions = { w: img.width, h: img.height };
        img.width = width;
        img.height = (width / dimensions.w) * dimensions.h;
        doc.addImage(img, 'PNG', (width / 2) - (img.width / 2) + xOffset, yOffset, img.width, img.height);
        return yOffset + 35;
    });
}

export function generateReceiptLabel(sale: Sale, withShipping: boolean) {
    try {
        if (sale._id.length > 0) {
            printSalesDone([{ sale: sale!, data: '' }], false, user.staff, printSimpleDate(new Date().toISOString()));
            addprintedlist([sale!]);

            var doc = new jsPDF('p', 'pt', [136, 793]); //48x210mm //136pt
            let xOffset = 0;
            let yOffset = 0;
            let width = doc.internal.pageSize.width - 1;// - 9;
            doc.setFont('helvetica', "normal");
            doc.setFontSize(8);

            if (sale.dropshipper !== '' || DOMode) yOffset = makeDeliveryOrder(sale, doc, xOffset, yOffset, width)
            else yOffset = makeReceipt(sale, doc, xOffset, yOffset, width);

            if (withShipping) {
                yOffset = yOffset + 15;
                makeShipLabel(sale, doc, xOffset, yOffset, width).then(() => printPDF(doc));
            }
            else { printPDF(doc); }
        }
    } catch (error) {
        alert(STR_ErrorPrinting + " : " + error);
    }
}

function generateLabel(sale: Sale) {
    try {
        if (sale._id.length > 0) {
            var doc = new jsPDF('p', 'pt', [136, 793]); //48x210mm //136pt
            let xOffset = 0;
            let yOffset = 0;
            let width = doc.internal.pageSize.width - 1;// - 9;
            doc.setFont('helvetica', "normal");
            doc.setFontSize(8);

            makeShipLabel(sale, doc, xOffset, yOffset, width).then(() => printPDF(doc));
        }
    } catch (error) {
        alert(STR_ErrorPrinting + " : " + error);
    }
}

export async function generateReceiptItemList(list: { sale: Sale, data: string }[], staff: string) {
    const shipdata = sortToShipReport(list);
    if (shipdata) {
        user.setLoading(true);
        if (isPlatform("hybrid")) {
            let EscPosEncoder = require('esc-pos-encoder');

            let encoder = new EscPosEncoder();

            let result = encoder
                .initialize()
                .codepage('cp437')
                .newline()

            let idata = shipdata.total;
            for (var i = 0; i < idata.item.length; i++) {
                result = result.text(idata.count[i] + ' ' + idata.item[i]).newline();
            }

            result = result.text("--------------------------").newline();

            for (var i = 0; i < shipdata.kurir.length; i++) {
                result = result.text(shipdata.kurir[i] + ' : ' + shipdata.resi[i].length).newline();
            }

            printBTenable(result.newline().newline().encode());
        }
        else {

            const date = new Date();

            var doc = new jsPDF('p', 'px', [102, 793]); //48x210mm

            let yOffset = 10;

            doc.setFont('helvetica', "bold");
            doc.setFontSize(8);

            let text = printDate(date.toISOString());
            let xOffset = 10;
            doc.text(text, xOffset, yOffset);
            if (staff !== ROOT) {
                yOffset = yOffset + 10;
                text = staff;
                doc.text(text, (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(text) * doc.getFontSize() / 2), yOffset);
            }

            yOffset = yOffset + 20;

            xOffset = 0;

            doc.setFontSize(12);

            text = STR_Total + ' Paket : ' + list.length;
            doc.text(text, xOffset, yOffset);
            yOffset = yOffset + 3;
            doc.line(xOffset, yOffset, doc.internal.pageSize.width - xOffset, yOffset);

            doc.setFont('helvetica', "bold");
            doc.setFontSize(6);

            let idata = shipdata.total;
            for (var i = 0; i < idata.item.length; i++) {
                text = idata.count[i] + ' ' + idata.item[i];
                yOffset = yOffset + 5;
                yOffset = linebreak(text, xOffset, yOffset, false, doc.internal.pageSize.width - (2 * xOffset), doc);
                if (yOffset > doc.internal.pageSize.height - 10) {
                    doc.addPage();
                    yOffset = 0;
                }
            }

            doc.setLineWidth(2);
            yOffset = yOffset + 5;
            doc.line(xOffset, yOffset, doc.internal.pageSize.width - xOffset, yOffset);

            for (var i = 0; i < shipdata.kurir.length; i++) {
                if (yOffset > doc.internal.pageSize.height - xOffset) {
                    doc.addPage();
                    yOffset = 0;
                }
                text = shipdata.kurir[i] + ' : ' + shipdata.resi[i].length;
                yOffset = yOffset + 5;
                doc.text(text, xOffset, yOffset);
            }
            printPDF(doc);
        }
        user.setLoading(false);
    }
}

function linebreak(text: string, xOffset: number, yOffset: number, center: boolean, maxwidth: number, doc: jsPDF) {
    if (center) {
        maxwidth = maxwidth - 5;
    }

    let splits = doc.splitTextToSize(text, maxwidth);

    for (var i = 0; i < splits.length; i++) {
        text = splits[i];
        if (center) xOffset = (maxwidth / 2) - (doc.getStringUnitWidth(text) * doc.getFontSize() / 2);
        doc.text(text, xOffset, yOffset);
        if (i < splits.length - 1) yOffset = center ? yOffset + 10 : yOffset + 7;
    }

    return yOffset;
}

export async function generateShipmentLabel(list: { sale: Sale, data: string }[], withdetails: boolean, withlabels: boolean, staff: string, directprint: boolean) {
    const shipdata = sortToShipReport(list);
    const showdetail = withdetails && (isPO(list[0].sale) || !withlabels);
    if (shipdata) {
        user.setLoading(true);
        const date = new Date();
        const filename = STR_MyID + '-ShipmentList';

        var doc = new jsPDF('p', 'pt');

        let yOffset = 20;

        yOffset = addSimpleHeader(doc, inventoryID === LIST_INVENTORY);

        doc.setFont('helvetica', "bold");
        doc.setFontSize(24);

        let text = STR_Total + ' Paket : ' + list.length;
        let xOffset = 40;
        yOffset = yOffset + 40;
        doc.text(text, xOffset, yOffset);
        yOffset = yOffset + 3;
        doc.line(xOffset, yOffset, doc.getStringUnitWidth(text) * doc.getFontSize() * 1.5, yOffset);

        if (staff !== ROOT) {
            text = staff;
            doc.text(text, doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 40, yOffset - 50);
        }

        doc.setFont('helvetica', "bold");
        doc.setFontSize(12);

        yOffset = printItemCounts(shipdata.total, doc, yOffset);

        doc.setLineWidth(2);

        let f = 0;
        for (var i = 0; i < shipdata.kurir.length; i++) {
            if (yOffset > doc.internal.pageSize.height - 68 - f /* * 2 / 3*/) {
                doc.addPage();
                yOffset = 17;
            }
            yOffset = yOffset + 23;
            doc.line(xOffset, yOffset, doc.internal.pageSize.width - 40, yOffset);

            if (showdetail) {
                doc.setFont('helvetica', "bold");
                doc.setFontSize(24);
            }

            text = shipdata.kurir[i] + ' : ' + shipdata.resi[i].length;
            yOffset = yOffset + 25;
            doc.text(text, xOffset, yOffset);

            if (showdetail) {
                f = shipdata.items[i].item.length * 50;
                doc.setFont('helvetica', "normal");
                doc.setFontSize(12);

                yOffset = printItemCounts(shipdata.items[i], doc, yOffset);

                yOffset = yOffset + 15;

                var head = [[STR_ID, STR_Client, STR_Topay, STR_Discount, STR_DP, STR_Shipment, STR_Resi, STR_Items, STR_SaleNote, STR_Info]];
                var data = shipdata.resi[i];
                autoTable(doc, {
                    head: head,
                    body: data,
                    startY: yOffset
                });

                yOffset = (doc as any).lastAutoTable.finalY + 3;
            }
        }

        if (withlabels) {
            let toPrintSList = toSList;
            if (toPrintSList && toPrintSList.length > 0) {
                doc.addPage();

                xOffset = 0;
                yOffset = 0;

                let max = 6; //5;
                let j = 0;
                for (i = 0; i < toPrintSList.length; i++) {
                    let img = new Image();
                    img.src = toPrintSList[i].data;
                    var dimensions: any = await getImageDimensions(toPrintSList[i].data);
                    img.width = 135; //140;
                    img.height = (135 /*140*/ / dimensions.w) * dimensions.h;
                    yOffset = yOffset + 5;//20;
                    doc.addImage(img, 'jpeg', xOffset + doc.internal.pageSize.width - img.height - 2.5/*10*/, yOffset - img.height, img.width, img.height, toPrintSList[i].sale._id, undefined, 270);

                    yOffset = yOffset + img.width;
                    j = j + 1;
                    if (j === max) {
                        yOffset = 0;
                        doc.addPage();
                        j = 0;
                    }
                    else {
                        doc.line(0, yOffset + 2.5/*10*/, doc.internal.pageSize.width, yOffset + 2.5/*10*/);
                    }
                }
            }
            addDate1(doc, date);
        }
        else {
            addDate(doc, date);
            addFooters(doc);
        }
        if (directprint) {
            printPDF(doc);
        }
        else {
            let n = filename + '-' + printDate(date.toISOString());
            downloadPDF(doc, n + '.pdf');
        }
        if (withlabels) printSalesDone(list, false, staff, printSimpleDate(date.toISOString()));
        else user.setLoading(false);
    }
}

var total = 0;
var totalprofit = 0;

function generateListPDF(list: any[], name: string, withoutQ: boolean, withfoto: boolean, rangedate: Date) {
    const date = new Date();
    const filename = list.length > 0 && 'items' in list[0] && list[0].shop === BIAS ? BIAS + '-' + name : STR_MyID + '-' + name;

    var doc = new jsPDF(list.length > 0 && 'items' in list[0] ? 'l' : 'p', 'pt');

    let yOffset = 20;

    let isbias = list.length > 0 && 'items' in list[0] ? list[0].shop === BIAS : inventoryID === LIST_BIASINVENTORY;

    yOffset = (list.length > 0 && 'ProductName' in list[0] && withoutQ) ? addHeader(doc, !isbias) : addSimpleHeader(doc, !isbias);

    doc.setFont('helvetica', 'bold');
    doc.setTextColor('#3792cb');

    let text = list.length > 0 && 'items' in list[0] && list[0].shop === BIAS ? printDateMonth(rangedate.toISOString()) : getCategoryToPrint();
    doc.text(text, 40, yOffset + 23);

    text = name;
    let xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 40);
    doc.text(text, xOffset, yOffset + 23);

    var head = inventoryID === LIST_INVENTORY ? [[STR_No, STR_Name, STR_Quantity, STR_Price]] : [[STR_No, STR_Name, STR_Quantity, STR_Price, STR_MSRP]];
    if (withfoto) head = inventoryID === LIST_INVENTORY ? [[STR_No, STR_Name, STR_Quantity, '', STR_Price]] : [[STR_No, STR_Name, STR_Quantity, '', STR_Price, STR_MSRP]];
    if (withoutQ) head = inventoryID === LIST_INVENTORY ? [[STR_No, STR_Name, '', STR_Price]] : [[STR_No, STR_Name, '', STR_Price, STR_MSRP]];
    if (list.length > 0 && 'telp' in list[0]) head = [[STR_No, STR_UserName, STR_Name, STR_tel, STR_Address, STR_Info]];
    else if (list.length > 0 && 'items' in list[0]) head = [[STR_No, STR_ID, STR_Date, list[0].shop === BIAS ? '' : STR_Shop, STR_Client, STR_Total, STR_Profit, STR_Info]];
    else if (list.length > 0 && 'count' in list[0]) head = [[STR_No, STR_ID, STR_Name, STR_Items]];

    const data = fillList(list, withoutQ, withfoto, rangedate);

    autoTable(doc, {
        head: head,
        body: data,
        startY: yOffset + 30,
        columnStyles: {
            0: { valign: 'middle' },
            1: { valign: 'middle' },
            2: {
                minCellWidth: list.length > 0 && 'ProductName' in list[0] && (withoutQ || withfoto) ? 80 : 0,
                valign: list.length > 0 && 'ProductName' in list[0] && !withoutQ && withfoto ? 'middle' : 'top'
            },
            3: {
                minCellWidth: list.length > 0 && 'ProductName' in list[0] && !withoutQ && withfoto ? 80 : list[0] ? 'ProductName' in list[0] ? 95 : 0 : 0,
                valign: list.length > 0 && 'ProductName' in list[0] && !withoutQ && withfoto ? 'top' : 'middle'
            },
            4: { minCellWidth: list[0] ? 'ProductName' in list[0] ? 95 : 0 : 0, valign: 'middle' },
            7: { cellWidth: list[0] ? 'items' in list[0] ? 95 : 0 : 0 }
        },
        didParseCell: (data) => {
            if (!withoutQ && data.column.index === 2) {
                data.cell.styles.halign = 'center';
            }
            if (data.row.section === 'body' && list.length > 0 && 'ProductName' in list[0] && (withoutQ || withfoto)) {
                data.row.height = 80;
            }
        },
        didDrawCell: (data) => {
            if (data.column.index === 2 && (list.length > 0 && 'ProductName' in list[0] && withoutQ)) {
                var idx = data.cell.raw as number;
                let img = new Image();
                img.src = images[idx];
                var dim = data.cell.height - data.cell.padding('vertical');
                var textPos = data.cell.getTextPos();
                try { doc.addImage(img, textPos.x, textPos.y, dim, dim); } catch (err) { }
            }
            if (withfoto && data.column.index === 3 && (list.length > 0 && 'ProductName' in list[0])) {
                var idx = data.cell.raw as number;
                let img = new Image();
                img.src = images[idx];
                var dim = data.cell.height - data.cell.padding('vertical');
                var textPos = data.cell.getTextPos();
                try { doc.addImage(img, textPos.x, textPos.y, dim, dim); } catch (err) { }
            }
        }
    })

    yOffset = (doc as any).lastAutoTable.finalY + 3;

    images = [];

    text = list.length > 0 && 'items' in list[0] ? STR_Total + ' : ' + formatCurrency(RUPIAH, total) : '';
    doc.text(text, 40, yOffset + 23);

    text = list.length > 0 && 'items' in list[0] ? STR_Profit + ' : ' + formatCurrency(RUPIAH, totalprofit) : '';
    xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 40);
    doc.text(text, xOffset, yOffset + 23);

    addDate(doc, date);
    addFooters(doc);

    let n = filename + '-' + printDate(date.toISOString());
    downloadPDF(doc, n + '.pdf');
}

export function generateCatalog(list: Item[]) {
    const date = new Date();
    const filename = STR_MyID + '-' + "Catalog";

    var doc = new jsPDF('p', 'pt');

    let yOffset = 20;

    yOffset = addHeader(doc, inventoryID === LIST_INVENTORY);

    doc.setFont('helvetica', 'bold');
    doc.setTextColor('#65737e');

    let text = getCategoryToPrint();
    doc.text(text, 40, yOffset + 23);

    text = "Catalog";
    let xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 40);
    doc.text(text, xOffset, yOffset + 23);

    yOffset = yOffset + 60;

    let limit = 6;
    let c = 0;
    for (var i = 0; i < list.length; i++) {
        c++;
        if ((c === i + 1 && c > 5) || (c !== i + 1 && c > limit)) {
            doc.addPage();
            c = 0;
            yOffset = 40;
        }
        let item = list[i];
        if (item.Quantity - item.Reserve > 0) {
            yOffset = addItem(doc, item, yOffset);
        }
    }

    addDate(doc, date);
    addFooters(doc);

    let n = filename + '-' + printDate(date.toISOString());
    downloadPDF(doc, n + '.pdf');
}

function downloadPDF(doc: jsPDF, filename: string) {
    if (isPlatform("hybrid")) {
        var base64 = doc.output('datauristring');

        SocialSharing.share(
            filename,
            filename,
            ['df:' + filename + ';' + base64]);
    }
    else doc.save(filename);
}

export function downloadShare(base64: string, blob: Blob, filename: string) {
    if (isPlatform("hybrid")) {
        SocialSharing.share(
            'Share',
            filename,
            ['df:' + filename + ';' + base64]);
    }
    else {
        /*if (navigator.msSaveBlob) { // In case of IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {*/
        const link = document.createElement('a');
        if (link.download !== undefined) {
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        //}
    }
}

function fillList(list: any[], withoutQ: boolean, withfoto: boolean, rangedate: Date) {
    total = 0;
    totalprofit = 0;
    let res: any[] = [];
    for (var i = 0; i < list.length; i++) {
        let e = list[i];

        if ('items' in e) {
            let date = new Date(e.date);
            if ((date.getFullYear() === rangedate.getFullYear() && date.getMonth() === rangedate.getMonth())) {
                total += +e.total;
                totalprofit += +e.profit;
                res.push(getEntryList(i + 1, e, withoutQ, withfoto));
            }
        }
        else res.push(getEntryList(i + 1, e, withoutQ, withfoto));
    }
    return res;
}

function getEntryList(n: number, entry: any, withoutQ: boolean, withfoto: boolean) {
    if (entry && 'ProductName' in entry) {
        images.push(getImageURL(entry));
        if (inventoryID === LIST_INVENTORY) {
            return withoutQ ? [n, entry.ProductName, images.length - 1, entry.Quantity - entry.Reserve > 0 ? formatCurrency(RUPIAH, entry.SalePrice) : STR_OutOfStock]
                : withfoto ? [n, entry.ProductName, entry.Quantity, images.length - 1, formatCurrency(RUPIAH, entry.SalePrice)]
                    : [n, entry.ProductName, entry.Quantity, formatCurrency(RUPIAH, entry.SalePrice)];
        }
        else {
            return withoutQ ? [n, entry.ProductName, images.length - 1, formatCurrency(RUPIAH, entry.SalePrice), formatCurrency(RUPIAH, entry.MSRPPrice)]
                : withfoto ? [n, entry.ProductName, entry.Quantity, images.length - 1, formatCurrency(RUPIAH, entry.SalePrice)]
                    : [n, entry.ProductName, entry.Quantity, formatCurrency(RUPIAH, entry.SalePrice), formatCurrency(RUPIAH, entry.MSRPPrice)]
        }
    }
    else if (entry && 'telp' in entry) {
        return [n, getPersonID(entry), entry.name, entry.telp, entry.address, entry.info];
    }
    else if (entry && 'items' in entry) {
        return [n, getNumberID(entry._id) + '-' + entry.items.length, printDateS(entry.date), entry.shop === BIAS ? '' : entry.shop, getPersonID(entry.client), formatCurrency(RUPIAH, entry.total), formatCurrency(RUPIAH, entry.profit), entry.info];
    }
    else if (entry && 'count' in entry) {
        return [n, entry._id, entry.name, entry.count];
    }
    return ['error'];
}

var randomcolors: string[] = [];

function getColors(n: number) {
    switch (n) {
        case 1: return ['rgba(255, 99, 132, 0.2)'];
        case 2: return ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)'];
        case 3: return ['rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)'];
        case 4: return ['rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(153, 102, 255, 0.2)'];
        case 5: return ['rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(75, 192, 192, 0.2)'];
        case 6: return ['rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(255, 159, 64, 0.2)'];
        default: {
            randomcolors = [];
            let res = [];
            for (var i = 0; i < n; i++) {
                let c = colorgenerator();
                while (randomcolors.includes(c)) {
                    c = colorgenerator();
                }
                randomcolors.push(c);
                let rgb = hexToRgb(c);
                res.push('rgba(' + rgb?.r + ', ' + rgb?.g + ', ' + rgb?.b + ', 0.5)')
            }
            return res;
            /*let res = [];
            for (var i = 0; i < n; i++) {
                if (i % 2 === 0) res.push('rgba(153, 102, 255, 0.2)');
                else res.push('rgba(255, 99, 132, 0.2)');
            }
            return res;*/
        };
    }
}

function componentToHex(c: number) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(r: number, g: number, b: number) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function hexToRgb(hex: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

function colorgenerator() {
    const randomColor = ("00000" + Math.floor(Math.random() * Math.pow(16, 6)).toString(16)).slice(-6);
    return ("#" + randomColor);
}

function gethoverColors(n: number) {
    switch (n) {
        case 1: return ['rgba(255,99,132,1)'];
        case 2: return ['rgba(255,99,132,1)', 'rgba(54, 162, 235, 1)'];
        case 3: return ['rgba(255,99,132,1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)'];
        case 4: return ['rgba(255,99,132,1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(153, 102, 255, 1)'];
        case 5: return ['rgba(255,99,132,1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(153, 102, 255, 1)', 'rgba(75, 192, 192, 1)'];
        case 6: return [
            'rgba(255,99,132,1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(255, 159, 64, 1)'
        ]
        default: {
            return randomcolors;
            /*let res = [];
            for (var i = 0; i < n; i++) {
                if (i % 2 === 0) res.push('rgba(153, 102, 255, 1)');
                else res.push('rgba(255,99,132,1)');
            }
            return res;*/
        };
    }
}

export function makeChart(catList: { cat: string, count: number }[]) {
    let labels: string[] = [];
    let data: number[] = [];
    catList.map(c => { labels.push(c.cat); data.push(c.count) });
    return {
        labels: labels,
        datasets: [{
            label: '# ' + STR_Items,
            data: data,
            backgroundColor: getColors(labels.length),
            hoverBackgroundColor: gethoverColors(labels.length)
        }]
    };
}

export function printImage(base64: string) {
    var embed = "<embed src='" + base64 + "'/>"
    var x = window.open();
    if (x) {
        x.document.open();
        x.document.write(embed);
        x.document.title = "labelprint";
        x.document.close();
        x.print();
        x.close();
    }
}

export function printPDF(doc: jsPDF) {
    doc.autoPrint();
    //window.open(doc.output('bloburl').toString());
    //doc.output('dataurlnewwindow');

    const hiddFrame = document.createElement('iframe');
    hiddFrame.style.position = 'fixed';
    // "visibility: hidden" would trigger safety rules in some browsers like safari，
    // in which the iframe display in a pretty small size instead of hidden.
    // here is some little hack ~
    hiddFrame.style.width = '1px';
    hiddFrame.style.height = '1px';
    hiddFrame.style.opacity = '0.01';
    const isSafari = /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);
    if (isSafari) {
        // fallback in safari
        hiddFrame.onload = () => {
            try {
                hiddFrame.contentWindow?.document.execCommand('print', false, undefined);
            } catch (e) {
                hiddFrame.contentWindow?.print();
            }
        };
    }
    hiddFrame.src = doc.output('bloburl').toString();
    document.body.appendChild(hiddFrame);
}

export function printcontent(id: string) {
    var content = document.getElementById(id);
    var pri = window.open();
    if (pri && content) {
        pri.document.open();
        pri.document.write(`<html><head><style type="text/css">
          @media print {
            body { 
              height: auto !important;
              min-height: 100%;
              max-height: unset !important;
            }
            .no-print { display: none; }
            .print { display: block !important; }
            .printModal {
              --overflow: visible !important;
              --height: auto !important;
              --min-height: 100%;
              --max-height: unset !important;
              --width: 100%;
            }
          }
          h3 {
            color: var(--ion-color-medium);
          }
          .logo {
            padding: 20px;
          }
          .quantity {
            text-align: center;
            width: 50%;
            float: left;
          }
          .price {
            text-align: center;
            width: 50%;
            float: right;
          }
          .status {
            text-align: right;
            min-width: 10%;
            float: right;
          }
          .floatright{
            position: relative;
            float: right;
            margin-top: -2.8em;
            z-index: 2;
          }
          ion-label{
            float: right;
            height: 180px !important;
            width: 75%;
            border: 1px solid grey;
            padding: 10px 0 10px 10px;
            margin: 2px 0 2px 0;
          }
          ion-avatar {
            display: flex;
            align-items: center;
            align-content: center;
            float:left;
            height: 180px !important;
            width: 21% !important;
            border: 1px solid grey;
            padding: 10px 0 10px 10px;
            margin: 2px 0 2px 0;
          }
          .placeholder {
            height: 100%;
            color: var(--ion-color-medium);
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .smallerx {
            font-size: x-small;
            color: var(--ion-color-tertiary);
          }
          .smaller {
            font-size: smaller;
          }
          .small {
            font-size: small;
          }
          .larger {
            font-size: larger;
          }
          .largerX {
            font-size: x-large !important;
          }
          .large {
            font-size: xx-large;
          }
          .bold {
            font-weight: bold;
          }
          .border {
            border: 1px solid var(--ion-color-light);
          }
          .bordertop {
            border-top: 2px solid black;
            margin-top: 10px;
            padding-top: 10px;
          }
          .borderbottom {
            border-bottom: 2px solid black;
            margin-bottom: 10px;
            padding-bottom: 10px;
          }
          .box {
            border: 2px solid black;
            margin: 10px 10px 10px 0;
          }
          .paddingtb {
            padding-bottom: 10px;
            padding-top: 10px;
          }
          .paddingt {
            padding-top: 10px;
          }
          .paddinglr {
            padding-left: 10px;
            padding-right: 10px;
          }
          .marginlr {
            margin-left: 10px;
            margin-right: 3px;
          }
          .marginb {
            margin-bottom: 5px;
          }
          .marginl {
            margin-left: 5px !important;
          }
          .margint {
            margin-top: 5px;
          }
          .margint10 {
            margin-top: 10px;
          }
          .marginr{
            margin-right: 5px;
          }
          .fit {
            flex: 0 0 100%;
          }
          .fixed {
            position: fixed;
          }
          .clearboth {
            clear: both;
          }
          .margin10 {
            margin: 10px;
          }
          .padding10 {
            padding: 10px;
          }
          .catalog {
            --border-radius: 0;
            height: 80px;
            width: 80px;
            text-align:center;
          }
          .catalog img {
            width: auto;
          }
          .catalogbg {
            --border-radius: 10px;
            --background: var(--ion-color-light);
            --padding-start: 20px;
            --padding-end: 20px;
            --padding-top: 20px;
            --padding-bottom: 20px;
            margin-left: 10px;
            margin-right: 10px;
            margin-bottom: 10px;
          }
          .fulltext {
            white-space: pre-wrap !important;
          }
          .info {
            font-size: small;
            color: var(--ion-color-warning);
          }
          .TitleBar {
            background-color: var(--ion-color-primary);
            color: var(--ion-color-primary-contrast);
          }
          .fullwidth {
            width: 100% !important;
            max-width: 100% !important;
          }
          .posrelative {
            position: relative;
            padding-left: 10px;
            padding-right: 10px;
            background: var(--ion-color-light);
          }
          .roundBorder {
            border: 1px solid var(--ion-color-light-tint);
            border-radius: 10px;
            box-shadow: 0px 2px 2px 0px var(--ion-color-light-shade);
            padding: 10px;
            background-color: var(--ion-color-light);
            color: var(--ion-color-light-contrast);
          }
          .center{
            display: flex;
            flex-wrap: wrap;
            text-align: center;
            align-items: center;
            justify-content: center;
          }
          .right {
            display: flex;
            background-color: var(--ion-color-danger-tint);
            font-weight: bolder;
            font-size: x-large;
            align-items: center;
            align-content: center;
            justify-content: flex-end;
            border-radius: 0 7px 7px 0;
            padding-right: 10px;
          }
          .middle {
            display: flex;
            align-items: center;
            align-content: center;
          }
          .end {
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: flex-end;
          }
          .hidden {
            display: none;
          }
          .leftalign {
            text-align: left;
            width: 70%;
            float: left;
          }
          .rightalign {
            text-align: right;
            width: 30%;
            float: right;
          }
          .fleft {
            text-align: left;
            float: left;
          }
          .fright {
            text-align: right;
            float: right;
          }
          .textcenter {
            text-align: center;
          }
          .textr {
            text-align: right;
          }
          .marginR8 {
            margin-right: 8px !important;
          }
          .margin0 {
            margin: 0 !important;
          }
          .marginR15 {
            margin-right: 15px;
          }
          .paddingR15 {
            padding-right: 15px;
          }
          .paddingR10 {
            padding-right: 10px;
          }
          .icon{
            height: 25px;
            width: 25px;
          }
          .floatingNow {
            position: fixed;
            top: 115px;
            left: 10px;
            z-index: 3;
            color: black;
            --border-color: black;
          }
          .minw30 {
            min-width: 30%;
          }
          .maxw30 {
            max-width: 30%;
          }
          .w50px {
            width: 50px;
          }
          .mTop-4px {
            margin-top: -4px;
            margin-left: 8px !important;
            margin-right: -5px !important;
          }
          .resetZ {
            z-index: 0;
          }
          .wrap-text {
            overflow: hidden;
            text-overflow: ellipsis;
          }</style></head><body>`);
        pri.document.write(content.innerHTML);
        pri.document.write('</body></html>');
        pri.document.title = "toprint";
        pri.document.close();
        pri.focus();
        pri.print();
    }
}

export async function printinvoice(inv: Invoice) {
    let sale = inv.sale;

    var doc = new jsPDF('p', 'pt');

    if (inv.logo !== null) {
        let img = new Image();
        img.src = getImgURL(inv.logo);
        img.height = 96;
        img.width = 250;
        doc.addImage(img, 'PNG', 40, 60, img.width, img.height);
    }

    doc.setFontSize(24);
    doc.setFont('helvetica', 'bold');
    doc.setTextColor('#000000');

    let text = sale.status === STR_Paid ? STR_Receipt : STR_Tagihan;
    let xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 40);
    let yOffset = 60;
    doc.text(text, xOffset, yOffset); //60

    doc.setFontSize(10.5);

    text = inv.company.name;
    xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 40);
    yOffset = yOffset + 26;
    doc.text(text, xOffset, yOffset); //80

    doc.setFont('helvetica', 'normal');

    text = inv.company.address;
    let cadrtext = text.split(', ');
    for (var i = 0; i < cadrtext.length; i++) {
        xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(cadrtext[i]) * doc.getFontSize()) - 40);
        yOffset = yOffset + 14;
        doc.text(cadrtext[i], xOffset, yOffset);
    }
    if (inv.company.info.length > 0) {
        doc.setTextColor('#000000');
        doc.setFont('helvetica', 'italic');
        text = inv.company.info;
        xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 40);
        yOffset = yOffset + 14;
        doc.text(text, xOffset, yOffset); //112
        doc.setFont('helvetica', 'normal');
    }

    if (inv.telp && inv.telp.length > 0) {
        text = STR_telp + inv.telp;
        xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 40);
        yOffset = yOffset + 24;
        doc.text(text, xOffset, yOffset);
    }
    if (inv.company.telp && inv.company.telp.length > 0) {
        text = "HP/WA: " + inv.company.telp;
        xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 40);
        yOffset = inv.telp && inv.telp.length > 0 ? yOffset + 14 : yOffset + 24;
        doc.text(text, xOffset, yOffset); //136
    }
    text = inv.website;
    xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 40);
    yOffset = yOffset + 12;
    doc.text(text, xOffset, yOffset); //148

    doc.setDrawColor('#a7adba');
    yOffset = yOffset + 12;
    doc.line(0, yOffset, doc.internal.pageSize.width, yOffset);

    /** header end **/

    doc.setFont('helvetica', 'bold');
    doc.setTextColor('#65737e');
    doc.text(STR_Customer, 40, yOffset + 24);
    text = "No :  ";
    let text2 = inv._id;
    xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text + text2) * doc.getFontSize()) - 40);
    doc.text(text, xOffset, yOffset + 24);
    let text1 = STR_InvDate;
    let text3 = printDateS(sale.date);
    xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text1 + text3) * doc.getFontSize()) - 40);
    doc.text(text1, xOffset, yOffset + 36);

    if (sale.paid === null) {
        var pos = 60;
        getInvBankAcc(inv).forEach(rek => {
            let textrek = Str_Rekening;
            let textrek2 = rek;
            xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(textrek + textrek2) * doc.getFontSize()) - 40);
            doc.text(textrek, xOffset, yOffset + pos);
            pos = pos + 12;
        });
    } else {
        let text4 = STR_Date + ' ' + STR_Paid + " :  ";
        let text5 = printDateS(sale.paid);
        xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text4 + text5) * doc.getFontSize()) - 40);
        doc.text(text4, xOffset, yOffset + 60);
        if (inv.note.length > 0) {
            let text8 = STR_Note + " :  ";
            let text9 = inv.note;
            xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text8 + text9) * doc.getFontSize()) - 40);
            doc.text(text8, xOffset, yOffset + 72);
        }
    }

    let text6 = sale.status !== STR_Paid ? STR_DueDate : '';
    let text7 = sale.status !== STR_Paid ? printDateS(inv.dateDue) : '';
    xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text6 + text7) * doc.getFontSize()) - 40);
    doc.text(text6, xOffset, yOffset + 98);

    doc.setTextColor('#000000');

    doc.text(getPersonID(inv.customer), 40, yOffset + 36);

    doc.setFont('helvetica', 'normal');
    xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text2) * doc.getFontSize()) - 40);
    doc.text(text2, xOffset, yOffset + 24);
    xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text3) * doc.getFontSize()) - 40);
    doc.text(text3, xOffset, yOffset + 36);

    if (sale.paid === null) {
        var pos = 60;
        getInvBankAcc(inv).forEach(rek => {
            xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(rek) * doc.getFontSize()) - 40);
            doc.text(rek, xOffset, yOffset + pos);
            pos = pos + 12;
        });
    } else {
        xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(printDateS(sale.paid)) * doc.getFontSize()) - 40);
        doc.text(printDateS(sale.paid), xOffset, yOffset + 60);
        if (inv.note.length > 0) {
            xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(inv.note) * doc.getFontSize()) - 40);
            doc.text(inv.note, xOffset, yOffset + 72);
        }
    }

    doc.setTextColor('#FF0000');
    xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text7) * doc.getFontSize()) - 40);
    doc.text(text7, xOffset, yOffset + 98);
    doc.setTextColor('#000000');

    //------------------------------

    yOffset = yOffset - 10;

    doc.text(inv.customer.name, 40, yOffset + 60);
    doc.text(inv.customer.telp, 40, yOffset + 72);
    text = inv.customer.address;
    let mtext = text.split(', ');
    yOffset = yOffset + 72;
    for (var i = 0; i < mtext.length; i++) {
        yOffset = yOffset + 12;
        doc.text(mtext[i], 40, yOffset);
    }
    if (inv.customer.info.length > 0) {
        yOffset = yOffset + 12;
        doc.setFont('helvetica', 'bold');
        doc.setTextColor('#65737e');
        doc.text(STR_Catatan, 40, yOffset);
        let notelength = doc.getStringUnitWidth(STR_Catatan) * doc.getFontSize();
        doc.setTextColor('#000000');
        doc.setFont('helvetica', 'italic');
        printtext(inv.customer.info, notelength + 40, yOffset, 2 * (notelength + 40), doc);
        doc.setFont('helvetica', 'normal');
    }

    //------------------------------
    if (inv.sale.client._id.length > 0 && inv.customer._id !== inv.sale.client._id) {
        yOffset = yOffset - 20;

        doc.setFont('helvetica', 'bold');
        doc.setTextColor('#65737e');
        doc.text(STR_ShipmentAddress, 40, yOffset + 48); //24

        doc.setFont('helvetica', 'normal');
        doc.setTextColor('#000000');

        doc.text(inv.sale.client.name, 40, yOffset + 60);
        doc.text(inv.sale.client.telp, 40, yOffset + 72);
        text = inv.sale.client.address;
        mtext = text.split(', ');
        yOffset = yOffset + 12;
        for (var i = 0; i < mtext.length; i++) {
            yOffset = yOffset + 72;
            doc.text(mtext[i], 40, yOffset);
        }
        if (inv.sale.client.info.length > 0) {
            yOffset = yOffset + 12;
            doc.setFont('helvetica', 'bold');
            doc.setTextColor('#65737e');
            doc.text(STR_Catatan, 40, yOffset);
            let notelength = doc.getStringUnitWidth(STR_Catatan) * doc.getFontSize();
            doc.setTextColor('#000000');
            doc.setFont('helvetica', 'italic');
            printtext(inv.sale.client.info, notelength + 40, yOffset, 2 * (notelength + 40), doc);
            doc.setFont('helvetica', 'normal');
        }
    }
    //-----------------------

    yOffset = yOffset + 12;

    const head = [[STR_No, 'Nama', 'Jumlah', 'Harga', 'Total']]
    const data = fillInvoice(sale);
    data.push(['', '', '', 'Total :', formatCurrency(getShop(sale.shop)?.currency!, sale.total + sale.discount)]);

    autoTable(doc, {
        head: head,
        body: data,
        startY: yOffset + 20,
        columnStyles: {
            3: { minCellWidth: 85 },
            4: { minCellWidth: 85 }
        },
        didParseCell: (data) => {
            if (data.row.section === 'head') {
                data.cell.styles.fillColor = '#343d46';
            }
            if (data.column.index === 2 || (data.row.section === 'head' && data.column.index === 3) || (data.row.section === 'head' && data.column.index === 4)) {
                data.cell.styles.halign = 'center';
            }
            if ((data.row.section === 'body' && data.column.index === 3) || (data.row.section === 'body' && data.column.index === 4)) {
                data.cell.styles.halign = 'right';
            }
            if (data.row.section === 'body' && (data.column.index === 3 || data.column.index === 4) && data.row.cells[3].raw === 'Total :') {
                data.cell.styles.fillColor = '#343d46';
                data.cell.styles.textColor = 'white';
                data.cell.styles.fontStyle = 'bold';
            }
            if (data.row.section === 'body' && (data.column.index === 0 || data.column.index === 1 || data.column.index === 2) && data.row.cells[3].raw === 'Total :') {
                data.cell.styles.fillColor = '#FFFFFF';
            }
        }
    })

    yOffset = (doc as any).lastAutoTable.finalY + 3;

    doc.setLineWidth(2);

    if (sale.discount > 0) {
        yOffset = yOffset + 12;
        text = sale.discount > 0 ? STR_Discount + " :  " : '';
        xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 159);
        doc.text(text, xOffset, yOffset);
        text = formatCurrency(RUPIAH, sale.discount);
        xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 45);
        doc.text(text, xOffset, yOffset);

        yOffset = yOffset + 10;
        xOffset = (doc.internal.pageSize.width - 295);
        doc.line(xOffset, yOffset, doc.internal.pageSize.width - 40, yOffset);

        yOffset = yOffset + 17;
        text = formatCurrency(RUPIAH, sale.total);
        xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 45);
        doc.text(text, xOffset, yOffset);
        yOffset = yOffset + 12;
    }

    if (sale.dp > 0) {
        yOffset = yOffset + 12;
        text = sale.dp > 0 ? "DP :  " : '';
        xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 159);
        doc.text(text, xOffset, yOffset);
        text = formatCurrency(RUPIAH, sale.dp);
        xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 45);
        doc.text(text, xOffset, yOffset);

        yOffset = yOffset + 10;
        xOffset = (doc.internal.pageSize.width - 295);
        doc.line(xOffset, yOffset, doc.internal.pageSize.width - 40, yOffset);

        yOffset = yOffset + 17;
        text = formatCurrency(RUPIAHZ, sale.total - sale.dp);
        xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 45);
        doc.text(text, xOffset, yOffset);
        yOffset = yOffset + 12;
    }

    if (sale.shipping > 0) {
        yOffset = yOffset + 12;
        text = sale.shipping > 0 ? STR_Ongkir + " :  " : '';
        xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 159);
        doc.text(text, xOffset, yOffset);
        text = formatCurrency(RUPIAH, sale.shipping);
        xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 45);
        doc.text(text, xOffset, yOffset);

        yOffset = yOffset + 12;
        xOffset = (doc.internal.pageSize.width - 295);
        doc.line(xOffset, yOffset, doc.internal.pageSize.width - 40, yOffset);

        yOffset = yOffset + 17;
        text = formatCurrency(RUPIAH, sale.total - sale.dp + +sale.shipping);
        xOffset = (doc.internal.pageSize.width - (doc.getStringUnitWidth(text) * doc.getFontSize()) - 45);
        doc.text(text, xOffset, yOffset);
        yOffset = yOffset + 12;
    }
    if (sale.status === STR_Paid) addLunas(doc, yOffset);

    if (sale.note !== '') {
        text = STR_Catatan;
        xOffset = 40;
        doc.setFont('helvetica', 'bold');
        doc.setTextColor('#65737e');
        doc.text(text, xOffset, doc.internal.pageSize.height - 100);
        doc.setTextColor('#000000');
        doc.setFont('helvetica', 'italic');
        doc.text(sale.note, xOffset + doc.getStringUnitWidth(text) * doc.getFontSize(), doc.internal.pageSize.height - 100, { maxWidth: doc.internal.pageSize.width - doc.getStringUnitWidth(text) * doc.getFontSize() - 80 });
        doc.setFont('helvetica', 'normal');
    }

    doc.line(0, doc.internal.pageSize.height - 42, doc.internal.pageSize.width, doc.internal.pageSize.height - 42);
    text = sale.status === STR_Paid ? inv.footnote[0] : inv.footnote[1];
    xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(text) * doc.getFontSize() / 2);
    doc.text(text, xOffset, doc.internal.pageSize.height - 30);

    let n = sale.status === STR_Paid ? STR_Receipt1 + '-' + inv._id + '-' + printDateS(sale.date) : STR_Invoice + '-' + inv._id + '-' + printDateS(sale.date);
    downloadPDF(doc, n + '.pdf');
}