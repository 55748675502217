import React, { useState, useRef } from "react";
import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonButton, IonIcon, IonTitle, IonContent, IonFooter, IonGrid, IonRow, IonCol, isPlatform } from "@ionic/react";
import { add } from "ionicons/icons";
import { NoteCard } from "../components/NoteCard";
import { initNote, Note } from "../functions/DataModel";
import { STR_Note } from "../lang/en";
import { notes } from "../functions/DataFunctions";
import { List } from "../components/List";

var selectedNote = initNote();
export function setselectedNote(note: Note) {
  selectedNote = note;
}

const Notes: React.FC = () => {

  const [show, setShow] = useState(false);
  const [reload, setReload] = useState(false);
  const neubutton = useRef(null);
  const updatebutton = useRef(null);

  function fill(note: Note, idx: number) {
    return <IonCol key={idx}><NoteCard note={note} rel={updatebutton} /></IonCol>;
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonButton hidden ref={updatebutton} onClick={() => { setReload(!reload); setShow(false) }}></IonButton>
          <IonButton ref={neubutton} className={show ? "paid" : "npaid"} slot="end" onKeyUp={e => { e.stopPropagation(); e.preventDefault() }} onClick={() => setShow(!show)}><IonIcon icon={add} /></IonButton>
          <IonTitle>{STR_Note}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className='neugheim' hidden={!show}><NoteCard note={selectedNote} rel={updatebutton} /></div>
        <IonGrid>
          <IonRow>
            <List data={notes} fill={fill} />
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonFooter>
      </IonFooter>
    </IonPage>
  );
};

export default Notes;