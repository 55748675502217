import { IonButton, IonCheckbox, IonIcon, IonLabel, IonSearchbar, IonToolbar } from "@ionic/react";
import { arrowDown, arrowUp, barcodeOutline, bookOutline } from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import { checkboxList, getCategoryList, getFiltered, getShowList, setAll, toggleOne } from "../functions/DataFunctions";
import { setItemsImage } from "../functions/LocalStorage";
import { STR_Catalog, STR_Empty } from "../lang/en";
import { BarcodeScanner } from '@ionic-native/barcode-scanner';
import { Item } from "../functions/DataModel";
import { printList } from "../functions/Output";

interface ContainerProps {
    data: any[];
    fill: Function;
}

export var updateList = () => { };

export const List: React.FC<ContainerProps> = ({ data, fill }) => {

    const searchEl = useRef(null);

    var limit = 10;
    const [items, setItems] = useState({ list: data, page: 1, data: getShowList(data, limit, 1), reload: false })

    updateList = () => { setList(data, items.page, getShowList(data, limit, items.page), true) }

    function getMaxPage() {
        let m = Math.floor(items.list.length / limit);
        if (items.list.length % limit > 0) m = m + 1;
        return m;
    }

    function setList(list: any[], next: number, listlimit: any[], reload: boolean) {
        setItemsImage(listlimit).then(() =>
            setItems({ list: list, page: next, data: listlimit, reload: reload ? !items.reload : items.reload }));
    }

    function scroll(down: boolean) {
        let next = down ? items.page + 1 : items.page - 1;
        if (next > getMaxPage() || next < 0) next = 0;
        if (next > 0) {
            setList(items.list, next, getShowList(items.list, limit, next), false)
        }
    }

    function searchItem(text: string) {
        let filteredData = 'count' in data[0] ? data : getFiltered(data, text.trim());
        setList(filteredData, 1, getShowList(filteredData, limit, 1), false);
    }

    function setChecked(key: string) {
        if (key === checkboxList[0].val) {
            setAll(!checkboxList[0].isChecked);
        }
        else {
            toggleOne(key);
        }
        let catList = getCategoryList();
        let text = (searchEl.current! as HTMLIonSearchbarElement).value;
        if(text && text.length > 0) searchItem(text);
        else setList(catList, 1, getShowList(catList, limit, 1), true);    
    }

    function showOptions() {
        return (
            checkboxList.map(({ val, name, isChecked }, idx) =>
                <div className="fleft middle" key={idx}>
                    <IonCheckbox className="marginlr" slot="start" value={val} checked={isChecked} onClick={() => setChecked(val)} />
                    <IonLabel>{name}</IonLabel>
                </div>
            )
        );
    }

    async function openScanner(e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) {
        const data = await BarcodeScanner.scan();
        (searchEl.current as unknown as HTMLInputElement).value = data.text;
    };

    useEffect(() => {
        setList(data, 1, getShowList(data, limit, 1), true);
    }, []);

    return (
        <>
            {data.length > 0 && 'ProductName' in data[0] ? <div className="no-print">{showOptions()}</div> : ''}
            <IonToolbar className="no-print">
                <IonSearchbar ref={searchEl} slot="start" onIonChange={e => searchItem(e.detail.value!)} ></IonSearchbar>
                <IonButton slot="end" fill="clear" size="large" onClick={e => openScanner(e)} >
                    <IonIcon icon={barcodeOutline} />
                </IonButton>
                {data.length > 0 && 'ProductName' in data[0] ? <IonButton slot="end" onClick={() => printList(items.list as Item[], STR_Catalog, 0, false, new Date())}><IonIcon slot="icon-only" icon={bookOutline} /></IonButton> : ''}
            </IonToolbar>
            {items.list.length > 0 ?
                <>
                    <IonButton className="no-print" hidden={items.page === 1} expand='full' onClick={() => scroll(false)}><IonIcon icon={arrowUp} /></IonButton>
                    {items.data.map((item, idx) => fill(item, idx))}
                    <IonButton className="no-print" hidden={items.page === getMaxPage() || items.list.length === 0} expand='full' onClick={() => scroll(true)}><IonIcon icon={arrowDown} /></IonButton>
                </>
                : <div className="placeholder clearboth">{STR_Empty}</div>}
        </>
    );
}