import React, { SyntheticEvent, MouseEvent, useState, useRef } from 'react';
import { IonModal, IonItem, IonAvatar, IonLabel, IonIcon } from '@ionic/react';
import { checkmark, warning } from 'ionicons/icons';
import { formatCurrency, Item, RUPIAH, Sale } from '../functions/DataModel';
import ItemModal from './ItemModal';
import { addSalesItem, updateListFlag, requestUpdateList, LIST_CATALOG, LIST_CATALOGQ, LIST_INVENTORY, LIST_SALES, LIST_PURCHASES, getImageURL, LIST_BIASINVENTORY } from '../functions/DataFunctions';
import { STR_MSRP } from '../lang/en';

interface ContainerProps {
  uRef: any;
  item: Item;
  isShowing: boolean;
  type: number;
  sale: Sale;
}

const ItemCard: React.FC<ContainerProps> = ({ uRef, item, isShowing, type, sale }) => {
  const modalEl = useRef(null);
  const defaultImage = "assets/img/item.jpg";
  const productImage = getImageURL(item);
  const available = item.Quantity > 0;

  const [showItem, setShowItem] = useState(false);

  function img404(event: SyntheticEvent<HTMLImageElement, Event>) {
    (event.currentTarget as HTMLImageElement).src = defaultImage;
  }

  function clicked(e: MouseEvent) {
    if (type === LIST_CATALOG || type === LIST_CATALOGQ) {
      return;
    }
    else if (type === LIST_INVENTORY || type === LIST_BIASINVENTORY) {
      setShowItem(true);
    }
    else {
      const popup = e.currentTarget.parentElement?.parentElement as HTMLDivElement;
      popup.style.display = 'none';
      (popup?.previousElementSibling?.firstElementChild as HTMLIonSearchbarElement).value = '';
      addSalesItem(type, item, sale);
      (uRef.current as HTMLElement).click();
    }
  }

  function dismiss() {
    setShowItem(false);
    if (updateListFlag) {
      (uRef.current as HTMLElement).click();
      requestUpdateList(false);
    }
  }

  return (
    <IonItem button={type === LIST_INVENTORY || type === LIST_BIASINVENTORY || type === LIST_SALES || type === LIST_PURCHASES} hidden={!isShowing} onClick={e => clicked(e)} lines={(type === LIST_CATALOGQ || type === LIST_CATALOG) ? "none" : "full"} className={(type === LIST_CATALOGQ || type === LIST_CATALOG) ? "catalogbg" : ''}>
      {type === LIST_INVENTORY || type === LIST_BIASINVENTORY ?
        <IonModal ref={modalEl} isOpen={showItem} swipeToClose={true} backdropDismiss={true} onDidDismiss={() => dismiss()}>
          <ItemModal uRef={uRef} modalRef={modalEl} copy={false} item={item} mode={type} />
        </IonModal> : ''}
      <IonAvatar className={(type === LIST_CATALOGQ || type === LIST_CATALOG) ? "catalog" : ''} slot="start">
        <img src={productImage} alt='' onError={img404}></img>
      </IonAvatar>
      <IonLabel className="fulltext">
        <div>
          {(type === LIST_CATALOGQ || type === LIST_CATALOG) ? '' :
            <div className="status">
              {item.MSRPPrice && item.MSRPPrice > 0 ? <IonLabel><span className="label">{STR_MSRP}</span>&nbsp;&nbsp;{formatCurrency(RUPIAH, item.MSRPPrice)}</IonLabel> : ''}
              <IonIcon hidden={!available} className="available" icon={checkmark} />
              <IonIcon hidden={available} className="warning" icon={warning} />
            </div>}
          {(type === LIST_CATALOGQ || type === LIST_CATALOG) ? '' :
            <h3>{item._id}</h3>}
          <h2>{item.ProductName}</h2>
        </div>
        {(type === LIST_CATALOG) ? '' :
          <div className="quantity"><span className="label">Quantity</span><br />{item.Quantity}</div>}
        <div className="price"><span className="label">Price</span><br />{formatCurrency(RUPIAH, item.SalePrice)}</div>
        {false && item.MSRPPrice && item.MSRPPrice > 0 ? <div className="clearboth center fullwidth paddingtb box margint"><span className="label">{STR_MSRP}</span>&nbsp;&nbsp;{formatCurrency(RUPIAH, item.MSRPPrice)}</div> : ''}
      </IonLabel>
    </IonItem>
  );
};

export default ItemCard;