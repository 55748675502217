import { IonFooter, IonHeader, IonToolbar, IonButton, IonIcon, IonTitle, IonContent, IonAlert, IonModal } from "@ionic/react"
import React, { useRef, useState } from "react";
import { trash, close, checkmark, copyOutline } from "ionicons/icons";
import ItemSheet from "./ItemSheet";
import { cloneItem, Item } from "../functions/DataModel";
import { removeInventoryItem, addInventoryItem, removeBiasItem, addBiasItem, LIST_INVENTORY } from "../functions/DataFunctions";
import { STR_CANCEL, STR_OK, STR_AddProduct, STR_ProductDetail, STR_RemoveProductMsg, STR_RemoveProduct, STR_Copy } from "../lang/en";

interface ContainerProps {
    uRef: any;
    modalRef: any;
    copy: boolean
    item: Item;
    mode: number
}

const ItemModal: React.FC<ContainerProps> = ({ uRef, modalRef, copy, item, mode }) => {
    const neu = item._id === '';
    const [showYNAlert, setShowYNAlert] = useState(false);

    const [show, setShow] = useState(false);
    const relModal = useRef(null);

    function closeModal() {
        reload();
        (modalRef.current as HTMLIonModalElement).dismiss();
    }

    function addItem(i: Item) {
        if (i._id !== '' && i.ProductName !== '') {
            if(mode===LIST_INVENTORY) addInventoryItem(i);
            else addBiasItem(i);
            closeModal();
            reload();
        }
    }

    function reload() {
        (uRef.current as HTMLElement).click();
    }

    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonButton slot="start" onClick={() => closeModal()}><IonIcon slot="icon-only" icon={close} /></IonButton>
                    <IonTitle>{neu ? STR_AddProduct : copy ? STR_Copy + '?' : STR_ProductDetail}</IonTitle>
                    <IonButton hidden={!neu && !copy} slot="end" onClick={() => addItem(item)}><IonIcon slot="icon-only" icon={checkmark} /></IonButton>
                    <IonButton hidden={neu || copy} slot="end" onClick={() => setShow(true)}><IonIcon slot="icon-only" icon={copyOutline} /></IonButton>
                    <IonButton hidden={neu || copy} slot="end" onClick={() => setShowYNAlert(true)}><IonIcon slot="icon-only" icon={trash} /></IonButton>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <ItemSheet item={item} copy={copy} mode={mode} />
            </IonContent>
            <IonFooter>
                <IonModal ref={relModal} isOpen={show} swipeToClose={true} backdropDismiss={true} onDidDismiss={() => { setShow(false); closeModal() }}>
                    <ItemModal uRef={uRef} modalRef={relModal} copy={true} item={cloneItem(item)} mode={mode} />
                </IonModal>
                <IonAlert isOpen={showYNAlert} onDidDismiss={() => setShowYNAlert(false)} header={STR_RemoveProduct} message={STR_RemoveProductMsg}
                    buttons={[
                        {
                            text: STR_CANCEL,
                            role: 'cancel',
                            cssClass: 'secondary',
                        }, {
                            text: STR_OK,
                            handler: () => {
                                try {
                                    if(mode===LIST_INVENTORY) removeInventoryItem(item._id);
                                    else removeBiasItem(item._id);
                                    closeModal();
                                    (uRef.current as HTMLElement).click();
                                } catch (error) {
                                    alert(error);
                                }
                            }
                        }
                    ]}></IonAlert>
            </IonFooter>
        </>
    )
}

export default ItemModal;