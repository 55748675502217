import React from 'react';
import ItemCard from './ItemCard';
import { Item, ALL, Sale } from '../functions/DataModel';
import { IonList } from '@ionic/react';
import { makeBiasCategory } from '../functions/DataFunctions';

interface ContainerProps {
  uRel: any;
  cat: string;
  data: Item[];
  type: number;
  sale: Sale;
}

export const ItemList: React.FC<ContainerProps> = ({ uRel, cat, data, type, sale }) => {

  return (
      <IonList>
        {data.map((item, idx) => <ItemCard key={idx} uRef={uRel} item={item} isShowing={(cat === ALL) || (cat === makeBiasCategory(ALL)) || (cat === item.Category)} type={type} sale={sale} />)}
      </IonList>
  );
}