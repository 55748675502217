import React, { useRef, useState } from 'react';
import { IonItem, IonInput, IonTextarea, IonButton, IonIcon, IonAlert, IonLabel, IonToolbar, IonSearchbar, IonList, IonSegment, IonSegmentButton, IonDatetime, IonHeader, IonGrid, IonRow, IonCol } from '@ionic/react';
import { cloneSale, formatCurrency, initItem, initPerson, Invoice, Person, SalesItem, SHOPEE_EXPORT } from '../functions/DataModel';
import { STR_Info, STR_Name, STR_tel, STR_Address, STR_CANCEL, STR_OK, STR_Key, STR_ErrorNull, STR_ErrorItemKey1, STR_ErrorItemExists, STR_Logo, STR_Client, STR_UserName, STR_SaveClient, STR_Shipment, STR_SaleNote, STR_Notes, STR_Website, STR_Bank, STR_multipleEntryInfo, STR_DueDate, STR_Company, STR_SameShippingAdr, STR_SelectInv, STR_Date, STR_Quantity, STR_Price, STR_Paid, STR_NotPaid, STR_AddProduct, STR_Payment, STR_Edit } from '../lang/en';
import { openinvLogo, removeInvLogo, saveInvoice } from '../functions/LocalStorage';
import { checkmark, copy, copyOutline, homeOutline, listOutline, pencil, person, personAdd, printOutline, storefrontOutline, trash } from 'ionicons/icons';
import { user } from './AppContextProvider';
import { addInvoice, contacts, containsClient, deleteInvoice, getFilteredContact, getImgURL, getInvoice, getPersonID, getShop, removeSalesItem, requestUpdateList, saveClient, updateTotal } from '../functions/DataFunctions';
import { updateList } from './List';
import { INVEDIT, INVREMOVE, invToDo, setInvToDo } from '../pages/Invoices';
import { printinvoice } from '../functions/Output';

interface ContainerProps {
  inv: Invoice;
  neu: boolean;
  main: boolean;
  rel: any;
}

export const invLogoID = 'iLogo';

const InvoiceCard: React.FC<ContainerProps> = ({ inv, neu, main, rel }) => {

  const [amount, setAmount] = useState(inv && inv.sale && inv.sale.total > 0 ? formatCurrency(getShop(inv.sale.shop)?.currency!, inv.sale.total) + ' - ' + inv.sale.status : '');
  const [error, setError] = useState('');
  const [psug, setPsug] = useState<Person[]>([]);
  const [personToAdd, setPersonToAdd] = useState(null as unknown as Person);
  const [showYNAlert, setShowYNAlert] = useState('');
  const [adr, setAdr] = useState(1);
  const [edit, setEdit] = useState(false);
  const [show, setShow] = useState(neu);

  const errorEL = useRef(null);
  const keyReqEL = useRef(null);

  const adrcleditbutton = useRef(null);

  const updateAmountE = useRef(null);
  const inputName = useRef(null);
  const inputTel = useRef(null);
  const inputAdr = useRef(null);
  const inputInfo = useRef(null);
  const inputKey = useRef(null);
  const clientSugEl = useRef(null);
  const inputSaleInfo = useRef(null);
  const inputSaleNote = useRef(null);
  const inputAddName = useRef(null);
  const inputAddQ = useRef(null);
  const inputAddP = useRef(null);

  const inputName1 = useRef(null);
  const inputTel1 = useRef(null);
  const inputAdr1 = useRef(null);
  const inputInfo1 = useRef(null);
  const inputKey1 = useRef(null);
  const clientSugEl1 = useRef(null);

  const inputName2 = useRef(null);
  const inputTel2 = useRef(null);
  const inputAdr2 = useRef(null);
  const inputInfo2 = useRef(null);
  const inputKey2 = useRef(null);
  const clientSugEl2 = useRef(null);

  const inputID = useRef(null);
  const inputWWW = useRef(null);
  const inputLTel = useRef(null);
  const inputRek = useRef(null);
  const inputDate = useRef(null);
  const inputDue = useRef(null);
  const inputPaid = useRef(null);
  const inputNote = useRef(null);

  if (main && !neu) {
    reseterror();
  }

  function setKey(key: string) {
    const newInv = {
      _id: key,
      logo: inv.logo,
      company: inv.company,
      website: inv.website,
      telp: inv.telp,
      bankaccount: inv.bankaccount,
      dateDue: inv.dateDue,
      customer: inv.customer,
      note: inv.note,
      footnote: inv.footnote,
      sale: inv.sale
    };
    addInvoice(newInv);
    if (inv._id.length > 0) deleteInvoice(inv._id);
    requestUpdateList(true);
    updateList();
    //inv = newInv;
    setInvToDo(newInv, INVEDIT);
    (updateAmountE.current as unknown as HTMLIonButtonElement).click();
    rel.current.click();
  }

  function reseterror() {
    if (error.length > 0) {
      let box = keyReqEL.current! as HTMLElement;
      box.style.border = 'none';
      setError('');
    }
  }

  function enableEdit(e: React.MouseEvent, id: string) {
    let err = errorEL.current! as HTMLElement;
    let el = e.currentTarget.previousElementSibling as HTMLInputElement;
    let box = keyReqEL.current! as HTMLElement;
    box.style.border = 'none';
    setError('');

    switch (id) {
      case 'adrcompany': {
        el = inputKey.current! as HTMLInputElement;
        break;
      }
      case 'adrcustomer': {
        el = inputKey1.current! as HTMLInputElement;
        break;
      }
      case 'adrclient': {
        el = inputKey2.current! as HTMLInputElement;
        break;
      }
      case 'addItems': {
        el = inputAddName.current! as HTMLInputElement;
        break;
      }
      default: break;
    }

    if (!el.disabled) {
      if (id !== 'key' && inv._id.length === 0) {
        setError(STR_ErrorNull);
        err.style.display = 'flex';
        box.style.border = "1px solid red";
        return;
      }
      switch (id) {
        case 'key': {
          let key = el.value;
          if (key !== '' && key === inv._id) {
            if (getInvoice(key) === null) {
              updateSalesTotal();
              break;
            }
            else {
              break;
            }
          }
          if (key === '' || getInvoice(key) !== null) {
            setError(key === '' ? STR_ErrorNull : STR_ErrorItemKey1 + key + STR_ErrorItemExists);
            err.style.display = 'flex';
            box.style.border = "1px solid red";
          }
          else if (!neu) {
            setKey(key);
          }
          else {
            inv._id = key;
            updateSalesTotal();
          }
          setInvToDo(inv, INVEDIT);
          break;
        }
        case 'www': {
          inv.website = el.value;
          break;
        }
        case 'rek': {
          inv.bankaccount = el.value;
          break;
        }
        case 'note': {
          inv.note = el.value;
          break;
        }
        case 'date': {
          inv.sale.date = el.value;
          break;
        }
        case 'due': {
          inv.dateDue = el.value;
          break;
        }
        case 'paiddate': {
          if (el.value !== inv.sale.paid) {
            inv.sale.status = STR_Paid;
            inv.sale.paid = el.value;
            (el.previousElementSibling?.previousElementSibling as HTMLIonButtonElement).disabled = false;
            (updateAmountE.current as unknown as HTMLIonButtonElement).click();
          }
          break;
        }
        case 'telp': {
          let n = el.value;
          if (n.startsWith('62')) {
            n = '0' + n.substring(2);
          }
          inv.telp = n;
          break;
        }
        case 'img': {
          (el.previousElementSibling?.previousElementSibling?.previousElementSibling as HTMLIonButtonElement).disabled = false;
          break;
        }
        case 'adrcompany': {
          (inputName.current! as HTMLInputElement).disabled = true;
          (inputTel.current! as HTMLInputElement).disabled = true;
          (inputAdr.current! as HTMLInputElement).disabled = true;
          (inputInfo.current! as HTMLInputElement).disabled = true;
          break;
        }
        case 'adrcustomer': {
          (inputName1.current! as HTMLInputElement).disabled = true;
          (inputTel1.current! as HTMLInputElement).disabled = true;
          (inputAdr1.current! as HTMLInputElement).disabled = true;
          (inputInfo1.current! as HTMLInputElement).disabled = true;
          (inputSaleInfo.current! as HTMLInputElement).disabled = true;
          (inputSaleNote.current! as HTMLInputElement).disabled = true;
          break;
        }
        case 'adrclient': {
          (inputName2.current! as HTMLInputElement).disabled = true;
          (inputTel2.current! as HTMLInputElement).disabled = true;
          (inputAdr2.current! as HTMLInputElement).disabled = true;
          (inputInfo2.current! as HTMLInputElement).disabled = true;
          break;
        }
        case 'addItems': {
          let newname = (inputAddName.current as unknown as HTMLInputElement).value;
          let newQ = Number.parseInt((inputAddQ.current as unknown as HTMLInputElement).value);
          let newprice = Number.parseFloat((inputAddP.current as unknown as HTMLInputElement).value);
          if (newname.length > 0 && newQ > 0) {
            let itemtoAdd = initItem();
            itemtoAdd._id = newname;
            itemtoAdd.ProductName = newname;
            let addSaleItems = {
              item: itemtoAdd,
              quantity: newQ,
              price: newprice,
              profit: 0
            }
            inv.sale.items.push(addSaleItems);
          }
          (inputAddName.current as unknown as HTMLInputElement).value = '';
          (inputAddQ.current as unknown as HTMLInputElement).value = '1';
          (inputAddP.current as unknown as HTMLInputElement).value = '0';
          (inputAddQ.current as unknown as HTMLInputElement).disabled = true;
          (inputAddP.current as unknown as HTMLInputElement).disabled = true;
          el.parentElement!.parentElement!.parentElement!.parentElement!.hidden = true;
          break;
        }
        default: break;
      }
      if (id !== 'key') updateSalesTotal();
      el.disabled = true;
      let ic = e.currentTarget.firstElementChild as HTMLIonIconElement;
      ic.icon = pencil;
      ic.color = "primary";
    }
    else {
      el.disabled = false;
      switch (id) {
        case 'adrcompany': {
          (inputName.current! as HTMLInputElement).disabled = false;
          (inputTel.current! as HTMLInputElement).disabled = false;
          (inputAdr.current! as HTMLInputElement).disabled = false;
          (inputInfo.current! as HTMLInputElement).disabled = false;
          break;
        }
        case 'adrcustomer': {
          (inputName1.current! as HTMLInputElement).disabled = false;
          (inputTel1.current! as HTMLInputElement).disabled = false;
          (inputAdr1.current! as HTMLInputElement).disabled = false;
          (inputInfo1.current! as HTMLInputElement).disabled = false;
          (inputSaleInfo.current! as HTMLInputElement).disabled = false;
          (inputSaleNote.current! as HTMLInputElement).disabled = false;
          break;
        }
        case 'adrclient': {
          (inputName2.current! as HTMLInputElement).disabled = false;
          (inputTel2.current! as HTMLInputElement).disabled = false;
          (inputAdr2.current! as HTMLInputElement).disabled = false;
          (inputInfo2.current! as HTMLInputElement).disabled = false;
          break;
        }
        case 'addItems': {
          (inputAddQ.current as unknown as HTMLInputElement).disabled = false;
          (inputAddP.current as unknown as HTMLInputElement).disabled = false;
          el.parentElement!.parentElement!.parentElement!.parentElement!.hidden = false;
        }
        default: break;
      }
      let ic = e.currentTarget.firstElementChild as HTMLIonIconElement;
      ic.icon = checkmark;
      ic.color = "success";
      el.focus();
    }
  }

  function saveAddInvoice() {
    let err = errorEL.current! as HTMLElement;
    let box = keyReqEL.current! as HTMLElement;
    box.style.border = 'none';
    setError('');

    inv.website = (inputWWW.current! as HTMLInputElement).value;
    inv.bankaccount = (inputRek.current! as HTMLInputElement).value;
    inv.note = (inputNote.current! as HTMLInputElement).value;
    inv.sale.date = (inputDate.current! as HTMLInputElement).value;
    inv.dateDue = (inputDue.current! as HTMLInputElement).value;
    let paid = (inputPaid.current! as HTMLInputElement).value;
    if (paid !== inv.sale.paid) {
      inv.sale.status = STR_Paid;
      inv.sale.paid = paid;
    }

    let n = (inputLTel.current! as HTMLInputElement).value;
    if (n.startsWith('62')) {
      n = '0' + n.substring(2);
    }
    inv.telp = n;

    let key = (inputID.current! as HTMLInputElement).value;
    if (neu && (key === '' || getInvoice(key) !== null)) {
      setError(key === '' ? STR_ErrorNull : STR_ErrorItemKey1 + key + STR_ErrorItemExists);
      err.style.display = 'flex';
      box.style.border = "1px solid red";
    }
    else if (!neu && key !== '' && inv._id !== '' && key !== inv._id) {
      setKey(key);
    }
    else {
      inv._id = key;
      updateSalesTotal();
    }
    setInvToDo(inv, INVEDIT);
    rel.current.click();
  }

  function setCustomer(p: Person) {
    const list = clientSugEl1.current as unknown as HTMLElement;
    list.style.display = "none";
    const inputKey1 = list?.previousElementSibling?.firstElementChild as HTMLInputElement;
    const inputName1 = list?.parentElement?.lastElementChild?.firstElementChild as HTMLInputElement;
    const inputTel1 = inputName1?.nextElementSibling as HTMLInputElement;
    const inputAdr1 = inputTel1?.nextElementSibling as HTMLInputElement;
    const inputInfo1 = inputAdr1?.nextElementSibling as HTMLInputElement;
    inputKey1.value = getPersonID(p);
    inputName1.value = p.name;
    inputTel1.value = p.telp;
    inputAdr1.value = p.address;
    inputInfo1.value = p.info;
    inv.customer._id = p._id;
  }

  function setCompany(p: Person) {
    const list = clientSugEl.current as unknown as HTMLElement;
    list.style.display = "none";
    const inputKey = list?.previousElementSibling?.firstElementChild as HTMLInputElement;
    const inputName = list?.parentElement?.lastElementChild?.firstElementChild as HTMLInputElement;
    const inputTel = inputName?.nextElementSibling as HTMLInputElement;
    const inputAdr = inputTel?.nextElementSibling as HTMLInputElement;
    const inputInfo = inputAdr?.nextElementSibling as HTMLInputElement;
    inputKey.value = getPersonID(p);
    inputName.value = p.name;
    inputTel.value = p.telp;
    inputAdr.value = p.address;
    inputInfo.value = p.info;
    inv.company._id = p._id;
  }

  function setClient(p: Person) {
    const list = clientSugEl2.current as unknown as HTMLElement;
    list.style.display = "none";
    const inputKey2 = list?.previousElementSibling?.firstElementChild as HTMLInputElement;
    const inputName2 = list?.parentElement?.lastElementChild?.firstElementChild as HTMLInputElement;
    const inputTel2 = inputName2?.nextElementSibling as HTMLInputElement;
    const inputAdr2 = inputTel2?.nextElementSibling as HTMLInputElement;
    const inputInfo2 = inputAdr2?.nextElementSibling as HTMLInputElement;
    inputKey2.value = getPersonID(p);
    inputName2.value = p.name;
    inputTel2.value = p.telp;
    inputAdr2.value = p.address;
    inputInfo2.value = p.info;
    inv.sale.client._id = p._id;
  }

  function addClient(e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) {
    let p = initPerson();
    const inputKey = (e.currentTarget as HTMLElement).parentElement?.firstElementChild as HTMLInputElement;
    const inputName = (e.currentTarget as HTMLElement).parentElement?.parentElement?.lastElementChild?.firstElementChild as HTMLInputElement;
    const inputTel = inputName?.nextElementSibling as HTMLInputElement;
    const inputAdr = inputTel?.nextElementSibling as HTMLInputElement;
    const inputInfo = inputAdr?.nextElementSibling as HTMLInputElement;
    let key = inputKey.value;
    if (key !== '' && !containsClient(key)) {
      p._id = key;
      p.name = inputName.value;
      p.telp = inputTel.value;
      p.address = inputAdr.value;
      p.info = inputInfo.value;
      p.staff = user.staff;
      setPersonToAdd(p);
      setShowYNAlert(STR_SaveClient);
    }
    else {
      (inputKey.firstElementChild as HTMLElement).style.border = "1px solid red";
    }
  }

  function searchClient(e: Event, text: string, id: string) {
    if ((e.currentTarget as HTMLInputElement).disabled) return;
    const popup = (e.currentTarget as HTMLElement).parentElement?.nextElementSibling as HTMLElement;
    if (text.length === 0) {
      setPsug([]);
      popup.style.display = 'none';
    }
    else if (text !== SHOPEE_EXPORT) {
      ((e.target as HTMLElement).firstElementChild as HTMLElement).style.border = "none";
      if (text.length > 0) {
        let filtered = getFilteredContact(contacts, text, 0);
        if (filtered.length > 0) popup.style.display = 'block';
        else popup.style.display = 'none';
        setPsug(filtered);
      }
      else {
        setPsug([]);
        popup.style.display = 'none';
      }
    }
    switch (id) {
      case 'adrcompany': {
        (inputKey.current! as HTMLInputElement).disabled = false;
        (inputName.current! as HTMLInputElement).disabled = false;
        (inputTel.current! as HTMLInputElement).disabled = false;
        (inputAdr.current! as HTMLInputElement).disabled = false;
        (inputInfo.current! as HTMLInputElement).disabled = false;
        break;
      }
      case 'adrcustomer': {
        (inputKey1.current! as HTMLInputElement).disabled = false;
        (inputName1.current! as HTMLInputElement).disabled = false;
        (inputTel1.current! as HTMLInputElement).disabled = false;
        (inputAdr1.current! as HTMLInputElement).disabled = false;
        (inputInfo1.current! as HTMLInputElement).disabled = false;
        (inputSaleInfo.current! as HTMLInputElement).disabled = false;
        (inputSaleNote.current! as HTMLInputElement).disabled = false;
        break;
      }
      case 'adrclient': {
        (inputKey2.current! as HTMLInputElement).disabled = false;
        (inputName2.current! as HTMLInputElement).disabled = false;
        (inputTel2.current! as HTMLInputElement).disabled = false;
        (inputAdr2.current! as HTMLInputElement).disabled = false;
        (inputInfo2.current! as HTMLInputElement).disabled = false;
        break;
      }
      default: break;
    }
  }

  function setTelp(input: HTMLIonInputElement, n: string) {
    if (n.startsWith('62')) {
      n = '0' + n.substring(2);
      input.value = n;
    }
    inv.customer.telp = n;
  }

  function setTelpCompany(input: HTMLIonInputElement, n: string) {
    if (n.startsWith('62')) {
      n = '0' + n.substring(2);
      input.value = n;
    }
    inv.company.telp = n;
  }

  function setTelpShip(input: HTMLIonInputElement, n: string) {
    if (n.startsWith('62')) {
      n = '0' + n.substring(2);
      input.value = n;
    }
    inv.sale.client.telp = n;
  }

  function copyAdr() {
    inv.sale.client._id = inv.customer._id;
    inv.sale.client.name = inv.customer.name;
    inv.sale.client.telp = inv.customer.telp;
    inv.sale.client.address = inv.customer.address;
    inv.sale.client.info = inv.customer.info;
    inv.sale.client.staff = inv.customer.staff;
  }


  function updateSalesTotal() {
    updateTotal(inv.sale);
    inv.sale.profit = inv.sale.profit - inv.sale.fee - inv.sale.discount;
    saveInvoice(inv);
    (updateAmountE.current as unknown as HTMLIonButtonElement).click();
    rel.current.click();
  }

  function update(value: number, item: SalesItem, n: number) {
    if (n === 0) {
      if (value >= 0) { item.quantity = value; }
      else {
        return;
      }
    }
    else if (n === 1) {
      if (value >= 0) { item.price = value; }
      else {
        return;
      }
    }
    updateSalesTotal();
  }

  function enableEditP(e: React.MouseEvent, id: string, item: SalesItem) {
    e.stopPropagation();
    let el = e.currentTarget.previousElementSibling as HTMLInputElement;

    if (!el.disabled) {
      switch (id) {
        case 'q': {
          update(Number.parseInt(el.value), item, 0)
          break;
        }
        case 'p': {
          update(Number.parseFloat(el.value.replace(".", "")), item, 1);
          break;
        }
        case 'name': {
          item.item.ProductName = el.value;
          updateSalesTotal();
          break;
        }
        default: break;
      }
      el.disabled = true;
      let ic = e.currentTarget.firstElementChild as HTMLIonIconElement;
      ic.icon = pencil;
      ic.color = "primary";
    }
    else {
      el.disabled = false;
      let ic = e.currentTarget.firstElementChild as HTMLIonIconElement;
      ic.icon = checkmark;
      ic.color = "success";
      el.focus();
    }
  }

  function fillItem(idx: number, item: SalesItem) {
    return (
      <IonItem key={idx}>
        <IonButton className="no-print" slot='end' color='danger' onClick={e => {
          e.stopPropagation();
          removeSalesItem(inv.sale, item);
          updateSalesTotal();
        }}><IonIcon slot="icon-only" icon={trash} />
        </IonButton>
        <IonLabel>
          <IonItem>
            <IonInput className='inputE' disabled={!edit} value={item.item.ProductName} />
            <IonButton hidden={main && neu} slot="end" fill="clear" size="small" onClick={e => enableEditP(e, 'name', item)}><IonIcon icon={edit ? checkmark : pencil} color={edit ? "success" : "primary"} /></IonButton>
          </IonItem>
          <div className="quantity inputE"><span className="label">{STR_Quantity}</span><br />
            <IonItem>
              <IonInput className='center' disabled={!edit} type="number" min="1" value={item.quantity} />
              <IonButton hidden={main && neu} className='saleQbutton' slot="end" fill="clear" size="small" onClick={e => enableEditP(e, 'q', item)}><IonIcon icon={edit ? checkmark : pencil} color={edit ? "success" : "primary"} /></IonButton></IonItem>
          </div>
          <div className="price inputE"><span className="label">{STR_Price}</span><br />
            <IonItem>
              <IonInput className='center' disabled={!edit} type="number" min="0" step={'500'} value={item.price} />
              <IonButton hidden={main && neu} className='saleQbutton' slot="end" fill="clear" size="small" onClick={e => enableEditP(e, 'p', item)}><IonIcon icon={edit ? checkmark : pencil} color={edit ? "success" : "primary"} /></IonButton></IonItem>
          </div>
        </IonLabel>
      </IonItem>
    );
  }

  return (
    <>
      <div className={getInvoice(inv._id) !== null && invToDo?._id === inv._id ? 'roundBorder margin10 borderwarning' : 'roundBorder margin10 borderblue'}>
        {neu ? <IonButton expand="full" color='success' onClick={() => saveAddInvoice()}><IonIcon slot="icon-only" icon={checkmark} /></IonButton>
          :
          <IonToolbar>
            {rel === null || neu ? '' : <>
              <IonButton disabled={invToDo?._id === inv._id} className="no-print" onClick={e => { e.stopPropagation(); setShow(!show); }}>
                {inv._id}
              </IonButton>
              <IonButton hidden={edit || invToDo?._id === inv._id} className="no-print" color='warning' onClick={e => {
                e.stopPropagation();
                setInvToDo(inv, INVEDIT);
                setShow(false);
                (updateAmountE.current as unknown as HTMLIonButtonElement).click();
                rel.current.click();
              }}>{STR_SelectInv}<IonIcon slot="start" icon={listOutline} />
              </IonButton></>
            }
            <IonButton onClick={e => { e.stopPropagation(); printinvoice(inv); }}>
              <IonIcon slot="icon-only" icon={printOutline} />
            </IonButton>
            <IonButton className="no-print" onClick={e => {
              e.stopPropagation();
              let neucopy = {
                _id: inv._id + '-copy',
                logo: inv.logo,
                company: inv.company,
                website: inv.website,
                telp: inv.telp,
                bankaccount: inv.bankaccount,
                dateDue: '',
                customer: inv.customer,
                note: inv.note,
                footnote: inv.footnote,
                sale: cloneSale(inv.sale)
              };
              setInvToDo(neucopy, INVEDIT);
              (updateAmountE.current as unknown as HTMLIonButtonElement).click();
              rel.current.click();
            }}><IonIcon slot="icon-only" icon={copyOutline} />
            </IonButton>
            <IonButton className="no-print" color='danger' onClick={e => {
              e.stopPropagation();
              setInvToDo(inv, INVREMOVE);
              (updateAmountE.current as unknown as HTMLIonButtonElement).click();
              rel.current.click();
            }}><IonIcon slot="icon-only" icon={trash} />
            </IonButton>
          </IonToolbar>
        }
        <IonLabel ref={errorEL} className="warning center hidden margint marginb">{error}</IonLabel>
        <div className="fullwidth">
          <IonHeader className={inv.sale.status === STR_Paid ? "fullwidth center bgGreen large" : inv.sale.status === STR_NotPaid ? "fullwidth center bgRed large" : "fullwidth center bg large"}>{inv && inv.sale && inv.sale.total > 0 ? formatCurrency(getShop(inv.sale.shop)?.currency!, inv.sale.total) + ' - ' + inv.sale.status : ''}</IonHeader>
          <IonButton ref={updateAmountE} hidden onClick={() => { setAmount(formatCurrency(getShop(inv.sale.shop)?.currency!, inv.sale.total) + ' - ' + inv.sale.status); updateList() }} />
        </div>
        <IonGrid hidden={!show}>
          <IonRow>
            <IonCol>
              <IonItem ref={keyReqEL}>
                <IonLabel position="stacked">{STR_Key}</IonLabel>
                <IonInput disabled={!edit && !neu} ref={inputID} required={true} value={inv._id} />
                <IonButton hidden={main && neu} slot="end" fill="clear" size="large" onClick={e => enableEdit(e, 'key')}><IonIcon icon={edit ? checkmark : pencil} color={edit ? "success" : "primary"} /></IonButton>
              </IonItem>
              <IonItem>
                <IonButton disabled={inv.logo === null} slot='end' color='danger' onClick={e => {
                  removeInvLogo(inv);
                  updateSalesTotal();
                  e.currentTarget.disabled = true;
                }}><IonIcon slot="icon-only" icon={trash} /></IonButton>
                <IonLabel position="stacked">{STR_Logo}</IonLabel>
                {inv.logo && inv.logo !== null ? <img id={invLogoID} src={getImgURL(inv.logo)} alt='' /> : ''}
                <input disabled={!edit && !neu} type="file" accept="image/*" onChange={e => { openinvLogo(e.target.files ? e.target.files.item(0) : null, inv); e.currentTarget.value = ''; updateList() }} onClick={e => e.stopPropagation()} />
                <IonButton hidden={main && neu} slot="end" fill="clear" size="large" onClick={e => enableEdit(e, 'img')}><IonIcon icon={edit ? checkmark : pencil} color={edit ? "success" : "primary"} /></IonButton>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">{STR_Website}</IonLabel>
                <IonInput disabled={!edit && !neu} ref={inputWWW} value={inv.website} />
                <IonButton hidden={main && neu} slot="end" fill="clear" size="large" onClick={e => enableEdit(e, 'www')}><IonIcon icon={edit ? checkmark : pencil} color={edit ? "success" : "primary"} /></IonButton>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">{STR_tel}</IonLabel>
                <IonInput disabled={!edit && !neu} ref={inputLTel} inputmode="tel" type="number" value={inv.telp} />
                <IonButton hidden={main && neu} slot="end" fill="clear" size="large" onClick={e => enableEdit(e, 'telp')}><IonIcon icon={edit ? checkmark : pencil} color={edit ? "success" : "primary"} /></IonButton>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">{STR_Bank + ' - ' + STR_multipleEntryInfo}</IonLabel>
                <IonInput disabled={!edit && !neu} ref={inputRek} value={inv.bankaccount} />
                <IonButton hidden={main && neu} slot="end" fill="clear" size="large" onClick={e => enableEdit(e, 'rek')}><IonIcon icon={edit ? checkmark : pencil} color={edit ? "success" : "primary"} /></IonButton>
              </IonItem>
              <IonItem>
                <IonLabel position='stacked'>{STR_Date}</IonLabel>
                <IonDatetime disabled={!edit && !neu} ref={inputDate} className="fullwidth center" displayFormat="DD-MM-YYYY (DDD)" placeholder="Select Date" value={inv.sale.date} />
                <IonButton hidden={main && neu} slot="end" fill="clear" size="large" onClick={e => enableEdit(e, 'date')}><IonIcon icon={edit ? checkmark : pencil} color={edit ? "success" : "primary"} /></IonButton>
              </IonItem>
              <IonItem>
                <IonLabel position='stacked'>{STR_DueDate}</IonLabel>
                <IonDatetime disabled={!edit && !neu} ref={inputDue} className="fullwidth center" displayFormat="DD-MM-YYYY (DDD)" placeholder="Select Date" value={inv.dateDue} />
                <IonButton hidden={main && neu} slot="end" fill="clear" size="large" onClick={e => enableEdit(e, 'due')}><IonIcon icon={edit ? checkmark : pencil} color={edit ? "success" : "primary"} /></IonButton>
              </IonItem>
              <IonItem>
                <IonButton disabled={inv.sale.paid === null} slot='end' color='danger' onClick={e => {
                  e.stopPropagation();
                  inv.sale.status = STR_NotPaid;
                  inv.sale.paid = null;
                  updateSalesTotal();
                  e.currentTarget.disabled = true;
                  (e.currentTarget.nextElementSibling?.nextElementSibling as HTMLIonDatetimeElement).value = inv.sale.paid;
                }}><IonIcon slot="icon-only" icon={trash} />
                </IonButton>
                <IonLabel position='stacked'>{STR_Payment + ' ' + STR_Date}</IonLabel>
                <IonDatetime disabled={!edit && !neu} ref={inputPaid} className="fullwidth center" displayFormat="DD-MM-YYYY (DDD)" placeholder="Select Date" value={inv.sale.paid} />
                <IonButton hidden={main && neu} slot="end" fill="clear" size="large" onClick={e => enableEdit(e, 'paiddate')}><IonIcon icon={edit ? checkmark : pencil} color={edit ? "success" : "primary"} /></IonButton>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">{STR_Notes}</IonLabel>
                <IonInput disabled={!edit && !neu} ref={inputNote} value={inv.note} />
                <IonButton hidden={main && neu} slot="end" fill="clear" size="large" onClick={e => enableEdit(e, 'note')}><IonIcon icon={edit ? checkmark : pencil} color={edit ? "success" : "primary"} /></IonButton>
              </IonItem>
            </IonCol><IonCol hidden={main && inv._id.length === 0}>
              <IonSegment value={adr === 0 ? STR_Company : adr === 1 ? STR_Client : STR_Shipment}>
                <IonSegmentButton value={STR_Company} layout="icon-start" onClick={() => setAdr(0)}>
                  <IonLabel>{STR_Company}</IonLabel>
                  <IonIcon icon={storefrontOutline} />
                </IonSegmentButton>
                <IonSegmentButton value={STR_Client} layout="icon-start" onClick={() => setAdr(1)}>
                  <IonLabel>{STR_Client}</IonLabel>
                  <IonIcon icon={person} />
                </IonSegmentButton>
                <IonSegmentButton value={STR_Shipment} layout="icon-start" onClick={() => setAdr(2)}>
                  <IonLabel>{STR_Shipment}</IonLabel>
                  <IonIcon icon={homeOutline} />
                </IonSegmentButton>
              </IonSegment>
              <IonItem hidden={adr !== 0}>
                <IonButton hidden={main && neu} className="no-print" expand='full' color='medium' onClick={e => enableEdit(e, 'adrcompany')}><IonIcon slot="start" icon={edit ? checkmark : pencil} color={edit ? "success" : "primary"} />{STR_Edit}</IonButton>
                <IonLabel position="stacked">{STR_Company}</IonLabel>
                <div className="fullwidth">
                  <IonToolbar className='resetZ paddingR10 bgtooltrans'>
                    <IonSearchbar disabled={!edit} slot="start" ref={inputKey} searchIcon={person} placeholder={STR_UserName} value={getPersonID(inv.company)} onIonInput={e => { inv.company._id = ((e.currentTarget as HTMLIonInputElement).value as string).trimStart() }} onIonChange={e => searchClient(e, (e.currentTarget as HTMLInputElement).value, 'adrcompany')}></IonSearchbar>
                    <IonButton disabled={inv.company.name.length === 0} slot="end" size="default" onClick={e => addClient(e)}><IonIcon icon={personAdd} /></IonButton><br />
                  </IonToolbar>
                  <div ref={clientSugEl} hidden={psug.length === 0 || (psug.length === 1 && psug[0]._id === inv.company._id)} className="suggestions hidden posrelative">
                    <IonList>
                      {psug.map((c, idx) => <IonItem className="bgsug" button key={idx} onClick={() => setCompany(c)}>{getPersonID(c)} : {c.name} | {c.telp} | {c.address}</IonItem>)}
                    </IonList>
                  </div>
                  <div className="roundBorder inputE marginb">
                    <IonInput disabled={!edit} ref={inputName} placeholder={STR_Name} value={inv.company.name} onIonChange={e => inv.company.name = e.detail.value as string}></IonInput>
                    <IonInput disabled={!edit} ref={inputTel} inputmode="tel" type="number" placeholder={STR_tel} value={inv.company.telp} onIonChange={e => setTelpCompany(e.currentTarget as HTMLIonInputElement, e.detail.value as string)}></IonInput>
                    <IonTextarea disabled={!edit} ref={inputAdr} placeholder={STR_Address} value={inv.company.address} onIonChange={e => inv.company.address = e.detail.value as string}></IonTextarea>
                    <IonTextarea disabled={!edit} ref={inputInfo} placeholder={STR_Info} value={inv.company.info} onIonChange={e => inv.company.info = e.detail.value as string}></IonTextarea>
                  </div>
                </div>
              </IonItem>
              <IonItem hidden={adr !== 1}>
                <IonButton hidden={main && neu} className="no-print" expand='full' color='medium' onClick={e => enableEdit(e, 'adrcustomer')}><IonIcon slot="start" icon={edit ? checkmark : pencil} color={edit ? "success" : "primary"} />{STR_Edit}</IonButton>
                <IonButton expand='full' color='medium' onClick={() => { copyAdr(); setAdr(2); (adrcleditbutton.current! as HTMLIonButtonElement).click() }}>
                  {STR_SameShippingAdr}
                  <IonIcon slot="start" icon={copy} />
                </IonButton>
                <IonLabel position="stacked">{STR_Client}</IonLabel>
                <div className="fullwidth">
                  <IonToolbar className='resetZ paddingR10 bgtooltrans'>
                    <IonSearchbar disabled={!edit} slot="start" ref={inputKey1} searchIcon={person} placeholder={STR_UserName} value={getPersonID(inv.customer)} onIonInput={e => { inv.customer._id = ((e.currentTarget as HTMLIonInputElement).value as string).trimStart() }} onIonChange={e => searchClient(e, (e.currentTarget as HTMLInputElement).value, 'adrcustomer')}></IonSearchbar>
                    <IonButton disabled={inv.customer.name.length === 0} slot="end" size="default" onClick={e => addClient(e)}><IonIcon icon={personAdd} /></IonButton><br />
                  </IonToolbar>
                  <div ref={clientSugEl1} hidden={psug.length === 0 || (psug.length === 1 && psug[0]._id === inv.customer._id)} className="suggestions hidden posrelative">
                    <IonList>
                      {psug.map((c, idx) => <IonItem className="bgsug" button key={idx} onClick={() => setCustomer(c)}>{getPersonID(c)} : {c.name} | {c.telp} | {c.address}</IonItem>)}
                    </IonList>
                  </div>
                  <div className="roundBorder inputE marginb">
                    <IonInput disabled={!edit} ref={inputName1} placeholder={STR_Name} value={inv.customer.name} onIonChange={e => inv.customer.name = e.detail.value as string}></IonInput>
                    <IonInput disabled={!edit} ref={inputTel1} inputmode="tel" type="number" placeholder={STR_tel} value={inv.customer.telp} onIonChange={e => setTelp(e.currentTarget as HTMLIonInputElement, e.detail.value as string)}></IonInput>
                    <IonTextarea disabled={!edit} ref={inputAdr1} placeholder={STR_Address} value={inv.customer.address} onIonChange={e => inv.customer.address = e.detail.value as string}></IonTextarea>
                    <IonTextarea disabled={!edit} ref={inputInfo1} placeholder={STR_Info} value={inv.customer.info} onIonChange={e => inv.customer.info = e.detail.value as string}></IonTextarea>
                  </div>
                </div>
                <IonTextarea disabled={!edit} ref={inputSaleInfo} placeholder={STR_Notes} value={inv.sale.info} onIonChange={e => inv.sale.info = e.detail.value as string}></IonTextarea>
                <IonTextarea disabled={!edit} ref={inputSaleNote} placeholder={STR_SaleNote} value={inv.sale.note} onIonChange={e => inv.sale.note = e.detail.value as string}></IonTextarea>
              </IonItem>
              <IonItem hidden={adr !== 2}>
                <IonButton hidden={main && neu} ref={adrcleditbutton} className="no-print" expand='full' color='medium' onClick={e => enableEdit(e, 'adrclient')}><IonIcon slot="start" icon={edit ? checkmark : pencil} color={edit ? "success" : "primary"} />{STR_Edit}</IonButton>
                <IonLabel position="stacked">{STR_Shipment + ' ' + STR_Address}</IonLabel>
                <div className="fullwidth">
                  <IonToolbar className='resetZ paddingR10 bgtooltrans'>
                    <IonSearchbar disabled={!edit} slot="start" ref={inputKey2} searchIcon={person} placeholder={STR_UserName} value={getPersonID(inv.sale.client)} onIonInput={e => { inv.sale.client._id = ((e.currentTarget as HTMLIonInputElement).value as string).trimStart() }} onIonChange={e => searchClient(e, (e.currentTarget as HTMLInputElement).value, 'adrclient')}></IonSearchbar>
                    <IonButton disabled={inv.sale.client.name.length === 0} slot="end" size="default" onClick={e => addClient(e)}><IonIcon icon={personAdd} /></IonButton><br />
                  </IonToolbar>
                  <div ref={clientSugEl2} hidden={psug.length === 0 || (psug.length === 1 && psug[0]._id === inv.sale.client._id)} className="suggestions hidden posrelative">
                    <IonList>
                      {psug.map((c, idx) => <IonItem className="bgsug" button key={idx} onClick={() => setClient(c)}>{getPersonID(c)} : {c.name} | {c.telp} | {c.address}</IonItem>)}
                    </IonList>
                  </div>
                  <div className="roundBorder inputE marginb">
                    <IonInput disabled={!edit} ref={inputName2} placeholder={STR_Name} value={inv.sale.client.name} onIonChange={e => inv.sale.client.name = e.detail.value as string}></IonInput>
                    <IonInput disabled={!edit} ref={inputTel2} inputmode="tel" type="number" placeholder={STR_tel} value={inv.sale.client.telp} onIonChange={e => setTelpShip(e.currentTarget as HTMLIonInputElement, e.detail.value as string)}></IonInput>
                    <IonTextarea disabled={!edit} ref={inputAdr2} placeholder={STR_Address} value={inv.sale.client.address} onIonChange={e => inv.sale.client.address = e.detail.value as string}></IonTextarea>
                    <IonTextarea disabled={!edit} ref={inputInfo2} placeholder={STR_Info} value={inv.sale.client.info} onIonChange={e => inv.sale.client.info = e.detail.value as string}></IonTextarea>
                  </div>
                </div>
              </IonItem>
            </IonCol><IonCol hidden={main && inv._id.length === 0}>
              {inv.sale.items.map((item, idx) => fillItem(idx, item))}
              <IonButton expand="full" color='medium' onClick={e => enableEdit(e, 'addItems')}>+<IonIcon slot="end" icon={edit ? checkmark : pencil} color={edit ? "success" : "primary"} /></IonButton>
              <div className='fullwidth' hidden={!edit}>
                <IonLabel position="stacked">{STR_AddProduct}</IonLabel>
                <div className="center roundBorder paddingtb">
                  <IonLabel>
                    <IonItem>
                      <IonInput disabled={!edit} ref={inputAddName} className='inputE' placeholder={STR_Name} />
                    </IonItem>
                    <div className="quantity inputE"><span className="label">{STR_Quantity}</span><br />
                      <IonItem>
                        <IonInput disabled={!edit} ref={inputAddQ} className='center' type="number" min="1" value="1" />
                      </IonItem>
                    </div>
                    <div className="price inputE"><span className="label">{STR_Price}</span><br />
                      <IonItem>
                        <IonInput disabled={!edit} ref={inputAddP} className='center' type="number" min="0" step={'500'} value="0" />
                      </IonItem>
                    </div>
                  </IonLabel>
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonAlert isOpen={showYNAlert !== ''} onDidDismiss={() => setShowYNAlert('')} message={showYNAlert}
          buttons={[
            {
              text: STR_CANCEL,
              role: 'cancel',
              cssClass: 'secondary'
            }, {
              text: STR_OK,
              handler: () => {
                switch (showYNAlert) {
                  case STR_SaveClient: {
                    (clientSugEl.current as unknown as HTMLElement).style.display = "none";
                    saveClient(personToAdd);
                    setPersonToAdd(null as unknown as Person);
                    break;
                  }

                  default:
                    break;
                }
              }
            }
          ]}></IonAlert>
      </div >
    </>
  );
};

//  <SalesCard sale={inv.sale} type={EDIT} rel={updateAmountE} po={false} />

export default InvoiceCard;