import React, { SyntheticEvent, useState } from 'react';
import { IonItem, IonAvatar, IonLabel, IonIcon, IonInput, IonAlert, IonList } from '@ionic/react';
import { warning } from 'ionicons/icons';
import { Sale, SalesItem } from '../functions/DataModel';
import { updateTotal, removeSalesItem, sale, LIST_PURCHASES, getImageURL, getAdditionalPrices } from '../functions/DataFunctions';
import { STR_Quantity, STR_Price, STR_CANCEL, STR_OK, STR_Purchase, STR_RemoveProduct, STR_ConfirmDelete, STR_Items } from '../lang/en';

interface ContainerProps {
  mode: number;
  salesItem: SalesItem;
  sale: Sale
}

const SalesItemCard: React.FC<ContainerProps> = ({ mode, salesItem, sale }) => {
  const item = salesItem.item;
  const defaultImage = "assets/img/item.jpg";
  const productImage = getImageURL(item);
  const available = item.Quantity > 0;

  const [showYNAlert, setShowYNAlert] = useState(null as unknown as HTMLElement);

  function img404(event: SyntheticEvent<HTMLImageElement, Event>) {
    (event.currentTarget as HTMLImageElement).src = defaultImage;
  }

  function setQuantity(e: Event) {
    let n = Number.parseInt((e.target as HTMLIonInputElement).value as string);
    let updateB = (e.target as HTMLIonInputElement).parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.nextElementSibling as HTMLButtonElement;
    if (n === 0) {
      setShowYNAlert(updateB);
    }
    else {
      salesItem.quantity = n;
      updateTotal(sale);
      updateB.click();
    }
  }

  function setPrice(e: Event) {
    salesItem.price = (e.target as HTMLIonInputElement).value as number;
    updateTotal(sale);
    let updateB = (e.target as HTMLIonInputElement).parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.nextElementSibling as HTMLButtonElement;
    updateB.click();
  }

  function setPriceFromSelect(e: React.MouseEvent<HTMLIonItemElement, MouseEvent>, price: string) {
    let sug = e.currentTarget.parentElement?.parentElement;
    if (sug) {
      let el = sug.previousElementSibling as HTMLInputElement;
      el.value = price;
      sug.style.display = 'none';
    }
  }

  return (
    <>
      <IonItem>
        <IonAvatar slot="start">
          <img src={productImage} alt='' onError={img404}></img>
        </IonAvatar>
        <IonLabel className="fullwidth fulltext">
          <div>
            <div className="status">
              <IonLabel hidden={!available} className="available" >{item.Quantity}</IonLabel>
              <IonIcon hidden={available} className="warning" icon={warning} />
            </div>
            <span className="fright info">Rp {mode === LIST_PURCHASES ? item.SalePrice : item.PurchasePrice}</span>
            <h3>{item._id}</h3>
            <h2>{item.ProductName}</h2>
          </div>
          <div className="quantity inputE"><span className="label">{STR_Quantity}</span><br />
            <IonInput type="number" min="0" value={salesItem.quantity} onIonChange={e => setQuantity(e)} />
          </div>
          <div className="price inputE"><span className="label">{mode === LIST_PURCHASES ? STR_Purchase + ' ' + STR_Price : STR_Price}</span><br />
            <IonInput type="number" min="0" step={'500'} value={salesItem.price} onIonChange={e => setPrice(e)} onClick={e => (e.currentTarget.nextElementSibling as HTMLElement).style.display = 'block'} />
            <div hidden={mode === LIST_PURCHASES} className="suggestions hidden posrelative">
              <IonList>
                <IonItem className="bgsug" button onClick={e => setPriceFromSelect(e, item.SalePrice.toString())}>{item.SalePrice}</IonItem>
                {getAdditionalPrices(item).map((p, idx) => <IonItem className="bgsug" button key={idx} onClick={e => setPriceFromSelect(e, p)}>{p}</IonItem>)}
              </IonList>
            </div>
          </div>
        </IonLabel>
      </IonItem>
      <IonAlert isOpen={showYNAlert != null} onDidDismiss={() => setShowYNAlert(null as unknown as HTMLElement)} header={STR_RemoveProduct} message={STR_ConfirmDelete + STR_Items + '?'}
        buttons={[
          //{
          //text: STR_CANCEL,
          //role: 'cancel',
          //cssClass: 'secondary',
          //}, 
          {
            text: STR_OK,
            handler: () => {
              try {
                removeSalesItem(sale, salesItem);
                showYNAlert.click();
              } catch (error) {
                alert(error);
              }
            }
          }
        ]}></IonAlert>
    </>
  );
};

export default SalesItemCard;