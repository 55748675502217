export const STR_MyShop = "Mekuya Games";
export const STR_MyID = "MG";
export const STR_SaveClient = "Do you want to <strong>SAVE</strong> the client for future use?";
export const STR_Problem = "Problem";
export const STR_Retur = "Retur";
export const STR_Paid = "Paid";
export const STR_NotPaid = "Not Paid";
export const STR_Instalments = "Instalments";
export const STR_Total = "Total";
export const STR_Discount = "Discount";
export const STR_Initial = "Initial Payment";
export const STR_Debt = "Debt";
export const STR_OK = "OK";
export const STR_CANCEL = "CANCEL";
export const STR_Wait = "Please wait...";
export const STR_AddProduct = "Add Product";
export const STR_Weight = "Weight (gram)";
export const STR_Dimensions = "Dimensions (cm)";
export const STR_ProductDetail = "Product Detail";
export const STR_ProductDescription = "Product Description";
export const STR_RemoveProduct = "Remove Product";
export const STR_RemoveProductMsg = "Do you want to <strong>remove</strong> the product?";
export const STR_RemoveSaleMsg = "Do you want to <strong>remove</strong> transaction?";
export const STR_RemovePersonMsg = "Do you want to <strong>remove</strong> contact?";
export const STR_RemoveKurir = "Are You sure? <strong>DELETE</strong> kurir : ";
export const STR_ErrorItemKey1 = "Error: Item with the same KEY: ";
export const STR_ErrorItemExists = " exists!";
export const STR_ErrorItemName1 = "Error: Item with the same NAME: ";
export const STR_ErrorRepeatedKeyName = "Error: Repeated KEY or NAME!";
export const STR_Remove = "REMOVE";
export const STR_multipleEntryInfo = "Separate each entry with # sign";
export const STR_Key = "Key";
export const STR_Website = "Website";
export const STR_Bank = "Bank Account";
export const STR_Logo = "Logo";
export const STR_Name = "Name";
export const STR_Category = "Category";
export const STR_Quantity = "Quantity";
export const STR_Reserve = "Reserve";
export const STR_Purchase = "Purchase";
export const STR_Price = "Price";
export const STR_AddSalesPrice = "Additional Sales Prices (optional)";
export const STR_ProductInfo = "Additional Product Information (optional)";
export const STR_Inventory = "Inventory";
export const STR_Sale = "Sale";
export const STR_Log = "Log";
export const STR_Purchases = "Purchases";
export const STR_Sales = "Sales";
export const STR_SalesHistory = "Sales History";
export const STR_PurchasesHistory = "Purchases History";
export const STR_tel = "Telephone number";
export const STR_Address = "Address";
export const STR_Info = "Info";
export const STR_UserName = "Client user name";
export const STR_Notes = "Notes (optional)";
export const STR_RP = "Rp";
export const STR_EUR = "€";
export const STR_Person = "Contacts";
export const STR_Empty = "List empty...";
export const STR_TO = "PENERIMA";
export const STR_Thx = "Terimakasih telah berbelanja di " + STR_MyShop + "!";
export const STR_CancelSale = "Cancel " + STR_Sale + "?";
export const STR_SaveSale = "Save " + STR_Sale + "?";
export const STR_SaveExpenseSale = "Save " + STR_Purchase + " and <b>add Expense</b>?";
export const STR_SaveUpdateSale = "Save " + STR_Sale + " and <b>update Inventory</b>?";
export const STR_SaveExpense = "Save Expense?";
export const STR_Payment = "Payment";
export const STR_Change = "Change";
export const STR_Status = "Status";
export const STR_Client = "Client";
export const STR_Company = "Company";
export const STR_telp = "Telp :  ";
export const STR_Customer = "Pembeli";
export const STR_InvKurir = "Kurir :  ";
export const STR_InvResi = "No Resi :  ";
export const STR_Folio = "Folio";
export const STR_Date = "Date";
export const STR_InvDate = "Tanggal :  ";
export const STR_DueDate = "Tanggal Jatuh Tempo :  ";
export const STR_SameShippingAdr = "Copy to Shipping Address";
export const STR_SelectInv = "show & edit";
export const STR_Subtotal = "SUBTOTAL";
export const STR_Items = "items";
export const STR_Catalog = "Catalog";
export const STR_OutOfStock = "Out of Stock";
export const STR_Stock = "Stock";
export const STR_with = "with";
export const STR_without = "without";
export const STR_Transaction = "Transaction";
export const STR_Monthly = "Monthly View";
export const STR_ErrorQuantity = "Error: Item Quantity more than stock available!";
export const STR_Profit = "Profit";
export const STR_Topay = "ToPay";
export const STR_Fee = "Fee";
export const STR_Balance = "Balance";
export const STR_SetPPrice = "<i>Override All</i> <b>Board Game</b>'s Purchase Price to have Profit: ";
export const STR_WarningOverride = "Warning: All Purchase Price will be overriden!";
export const STR_ProductChange = "Change Products";
export const STR_Shipment = "Shipment";
export const STR_ShipmentAddress = "Alamat Pengiriman"
export const STR_Resi = "Resi";
export const STR_Backup = "Import / Export";
export const STR_LoadCSV = "LOAD CSV";
export const STR_Import = "Import";
export const STR_ImportIDone = "Inventory updated.";
export const STR_Export = "Export";
export const STR_DownloadCSV = "Download CSV";
export const STR_OverridePPrice = "Set % Purchase Price";
export const STR_Income = "Income";
export const STR_Expense = "Expense";
export const STR_Transfer = "Transfer";
export const STR_Account = "Account";
export const STR_To = "To";
export const STR_From = "From";
export const STR_Note = "Notes";
export const STR_RemoveTransfer = "<strong>Remove</strong> transfer?";
export const STR_WarningInventoryNotUpdated = "Changes here does NOT affect inventory quantity & price.";
export const STR_WarningDoNOTUpdateHere = "Do NOT update here !!!!";
export const STR_additionalCost = "extra cost+ / profit- ";
export const STR_TarikDana = "Tarik Dana";
export const STR_extra = "extra";
export const STR_Cost = "Cost";
export const STR_Images = "Images";
export const STR_Add = "Add";
export const STR_ID = "ID";
export const STR_No = "No";
export const STR_EOL = "End of List";
export const STR_COD = "COD";
export const STR_Loading = "Loading...";
export const STR_Image = "Image";
export const STR_All = "ALL";
export const STR_Shop = 'Shop';
export const STR_Provider = 'Provider';
export const STR_ErrorNull = 'Input cannot be empty';
export const STR_Resize = "Resize all images?";
export const STR_Cashless = "Cashless";
export const STR_Pay = "Pay";
export const STR_Invoice = "Invoice";
export const STR_Receipt1 = "Receipt";
export const STR_Last = "Last";
export const STR_addPerson = "New Contact: ";
export const STR_addCategory = "New Category: ";
export const STR_mprint = "Toggle multiple print";
export const STR_print = "Print";
export const STR_Labels = "Labels";
export const STR_hide = "hide";
export const STR_show = "show";
export const STR_dsList = "Report";
export const STR_clear = "CLEAR";
export const STR_amount = "Amount";
export const STR_Copy = "COPY";
export const STR_calculate = "calculate?";
export const STR_Wealth = "Wealth";
export const STR_archive = "Limit database to this year only? Don't forget to <strong>SAVE past year(s) to Archive</strong> first!!";
export const STR_Dropshipper = "Dropshipper";
export const STR_Pengirim = 'PENGIRIM: ';
export const STR_AddPO = 'Materialize order?';
export const STR_NOW = "NOW";
export const STR_Code = "Code";
export const STR_SafePack = "Pack AMAN - Kardus";
export const STR_SAFE = "SAFE";
export const STR_DP = "DP";
export const STR_SaleNote = "Sale Info for Client";
export const STR_AddedToSale = " added to Sale.";
export const STR_Login = "Login";
export const STR_Register = "Register";
export const STR_LoginUser = "User Name";
export const STR_LoginPwd = "Password";
export const STR_UserSaved = "New User saved";
export const STR_UserSavedFailed = "Error: New User NOT saved";
export const STR_WrongPassword = "Error: Wrong Password";
export const STR_UserNameNotAvailable = "Error: UserName not available!";
export const STR_Pwd8 = "Password must have at least 8 characters";
export const STR_EmptyResi = "EMPTY Resi number";
export const STR_DeliveryOrder = "DELIVERY ORDER";
export const STR_QTY = "QTY";
export const STR_UPDATED = "UPDATED";
export const STR_PAUSED = "PAUSED";
export const STR_ERROR = "ERROR";
export const STR_ErrorNeedLogin = 'Please login';
export const STR_KEYS = 'Gheim';
export const STR_Email = "eMail";
export const STR_Pwd = "Password";
export const STR_ConfirmDelete = "Do you want to <strong>remove</strong> the ";
export const STR_DOMode = "CLEAR ALL selection and toggle Delivery Order Mode?";
export const STR_UseFingerprint = "Use Fingerprint"
export const STR_Ongkir = "Biaya Kirim";
export const STR_NeedPrintAll = "Set ALL need print?";
export const STR_EditSale = "Edit Sale - Inventory not updated!";
export const STR_BankPayment = "BCA";
export const STR_SelectSale = "Please select some Sale first!";
export const STR_toPrint = "Not Printed";
export const STR_SaveList = "Save List?";
export const STR_Rename = "Rename";
export const STR_MSRP = "MSRP Price";
export const STR_ThxBias = "Terimakasih telah berbelanja di Bias!";
export const STR_ThxMegakon = "Terimakasih telah berbelanja di Megakon!";
export const STR_DrawFund = "Tarik dana";
export const STR_upload = "UPLOAD";
export const STR_ShipmentLabel = "Label";
export const Str_Rekening = "Rek :";
export const STR_Description = "Description";
export const STR_JSXElement = "JSX Element";
export const STR_Title = "Title";
export const STR_Subtitle = "Subtitle";
export const STR_Edit = "Edit";
export const STR_Neu = "New Note";
export const STR_ErrorTruncate = "Error at truncating Logs";
export const STR_LIMITDB = "Truncate Database?";
export const STR_Receipt = "RECEIPT / NOTA";
export const STR_Tagihan = "INVOICE / TAGIHAN";
export const STR_FooterReceipt = "Terima kasih!";
export const STR_FooterInvoice = "Harap dibayar dalam 7 hari kerja. Terima kasih!";
export const STR_FooterInvoiceDue = "Harap membayar sebelum tanggal jatuh tempo. Terima kasih!";
export const STR_ShopeePay = "Shopee Pay 081215206885";
export const STR_ChangeInventory = "Change Inventory?";
export const STR_Catatan = "Catatan :  ";
export const STR_User = "User";
export const STR_New = "New";
export const STR_Sure = "Are you sure?";
export const STR_SelectCats = "Select categories to be shown";
export const STR_Chart = "Chart";
export const STR_outPrint = "Get out of Print Mode and Print Items-List? All saved sales will be cleared.";
export const STR_inPrint = "Start Print Mode? All following printed sales will be saved.";
export const STR_printed = "Printed";
export const STR_ConvertImagetoMono = "Image to convert to monochrome";
export const STR_ThermalPrint = "Print";
export const STR_ErrorPrinting = "PRINT ERROR !!";
export const STR_DeleteResiStart = "Are You sure? to DELETE resi starts with ";