import React, { useRef, useState } from 'react';
import { IonItem, IonInput, IonTextarea, IonButton, IonIcon, IonLabel, IonGrid, IonCol, IonRow, IonAlert } from '@ionic/react';
import { STR_Info, STR_tel, STR_Email, STR_ErrorNull, STR_ErrorItemExists, STR_ERROR, STR_Key, STR_To, STR_LoginUser, STR_Pwd, STR_Remove, STR_ConfirmDelete, STR_CANCEL, STR_OK } from '../lang/en';
import { checkmark, copyOutline, pencil, trash } from 'ionicons/icons';
import { cloneGheim, Gheim, initGheim } from '../functions/DataModel';
import { addGheim, gheimExists, removeGheim } from '../functions/DataFunctions';
import { updateList } from './List';
import { saveGheim } from '../functions/LocalStorage';
import { setGheimCopy } from '../pages/Keys';

interface ContainerProps {
  item: Gheim | null;
  copy: boolean;
  open: boolean;
  rel: any
}

export var reset = () => { }

const GheimCard: React.FC<ContainerProps> = ({ item, copy, open, rel }) => {

  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(item === null || copy);
  const [confirm, setConfirm] = useState(false);

  const [error, setError] = useState("");

  const inputloc = useRef(null);
  const inputuser = useRef(null);
  const inputpwd = useRef(null);
  const inputemail = useRef(null);
  const inputtelp = useRef(null);
  const inputinfo = useRef(null);

  var neu = item === null;

  if (neu) {
    item = initGheim();
  }
  else if (copy) {
    item = cloneGheim(item!);
  }

  function onclickSubmit(e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) {
    e.stopPropagation();
    let l = (inputloc.current! as HTMLIonInputElement).value as string;
    let u = (inputuser.current! as HTMLIonInputElement).value as string;
    if (edit) {
      if (l === '' || u === '') return setError(STR_ErrorNull);
      else if ((neu || copy) && gheimExists(l, u)) return setError(STR_ERROR + ' : ' + STR_Key + STR_ErrorItemExists)

      item!.loc = l;
      item!.userid = u;
      item!.pwd = (inputpwd.current! as HTMLIonInputElement).value as string;
      item!.email = (inputemail.current! as HTMLIonInputElement).value as string;
      item!.telp = (inputtelp.current! as HTMLIonInputElement).value as string;
      item!.info = (inputinfo.current! as HTMLIonTextareaElement).value as string;
      if (neu || copy) addGheim(item!);
      else saveGheim(item!);
      updateList();
      setError('');
      if (neu || copy) {
        setGheimCopy(null);
        rel.current.click();
      }
      else setEdit(!edit);
    }
    else {
      setError('');
      setEdit(!edit);
    }
  }

  reset = () => {
    try {
      (inputloc.current! as HTMLIonInputElement).value = '';
      (inputuser.current! as HTMLIonInputElement).value = '';
      (inputpwd.current! as HTMLIonInputElement).value = '';
      (inputemail.current! as HTMLIonInputElement).value = '';
      (inputtelp.current! as HTMLIonInputElement).value = '';
      (inputinfo.current! as HTMLIonTextareaElement).value = '';
      setError('');
    } catch (error) {

    }
  }

  function showItem(e: React.MouseEvent<HTMLIonItemElement, MouseEvent>) {
    e.stopPropagation();
    if (!(neu || copy)) { setShow(!show) }
  }

  return (
    <IonItem onClick={e => showItem(e)}>
      <IonButton slot="end" className="endButton no-print" color={edit ? "success" : "primary"} onClick={e => onclickSubmit(e)}><IonIcon icon={edit ? checkmark : pencil} /></IonButton>
      <IonButton hidden={neu || copy} className="endButton no-print" slot="end" onClick={() => { setGheimCopy(item!); rel.current.click() }}><IonIcon slot="icon-only" icon={copyOutline} /></IonButton>
      <IonButton hidden={neu || copy} className="endButton no-print" slot="end" onClick={e => {
        e.stopPropagation();
        setConfirm(true);
      }}><IonIcon slot="icon-only" icon={trash} /></IonButton>
      <IonLabel>
        <IonGrid className="bggheim">
          <IonRow>
            <IonCol><IonInput className="inputE" disabled={!neu && !copy} ref={inputloc} value={item!.loc} placeholder={STR_Key + ' ' + STR_To} onClick={e => e.currentTarget.disabled ? {} : e.stopPropagation()}></IonInput></IonCol>
            <IonCol><IonInput className="inputE" disabled={!neu && !copy} ref={inputuser} value={item!.userid} placeholder={STR_LoginUser} onClick={e => e.currentTarget.disabled ? {} : e.stopPropagation()}></IonInput></IonCol>
            <IonCol hidden={!(neu || copy) && !open}><IonInput className="inputE" disabled={!edit} ref={inputpwd} value={item!.pwd} placeholder={STR_Pwd} onClick={e => e.currentTarget.disabled ? {} : e.stopPropagation()}></IonInput></IonCol>
          </IonRow></IonGrid>
        <div hidden={!(neu || copy) && !show} className="roundBorder margin10 fullwidth">
          <IonLabel position="stacked">{STR_Email}</IonLabel><IonInput className="inputE" disabled={!edit} ref={inputemail} value={item!.email} onClick={e => e.currentTarget.disabled ? {} : e.stopPropagation()}></IonInput>
          <IonLabel position="stacked">{STR_tel}</IonLabel><IonInput className="inputE" disabled={!edit} ref={inputtelp} value={item!.telp} onClick={e => e.currentTarget.disabled ? {} : e.stopPropagation()}></IonInput>
          <IonLabel position="stacked">{STR_Info}</IonLabel><IonTextarea className="inputE" disabled={!edit} ref={inputinfo} value={item!.info} onClick={e => e.currentTarget.disabled ? {} : e.stopPropagation()}></IonTextarea>
        </div>
        <IonItem hidden={error === ''} className='clearboth fullwidth warning center smaller'>{error!}</IonItem>
      </IonLabel>
      <IonAlert isOpen={confirm} onDidDismiss={() => setConfirm(false)} header={STR_Remove} message={STR_ConfirmDelete + STR_Key + '?'}
        buttons={[
          {
            text: STR_CANCEL,
            role: 'cancel',
            cssClass: 'secondary',
          }, {
            text: STR_OK,
            handler: () => {
              try {
                removeGheim(item!);
                updateList();
              } catch (error) {
                alert(error);
              }
            }
          }
        ]}></IonAlert>
    </IonItem>
  );
};

export default GheimCard;