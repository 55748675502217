import React, { useState } from "react"; import { IonMenu, IonHeader, IonToolbar, IonContent, IonList, IonItem, IonIcon, IonLabel, IonRouterOutlet, IonModal, IonButton } from "@ionic/react";
import { basket, cart, person, newspaperOutline, warning, downloadOutline, cashOutline, saveOutline, peopleOutline } from "ionicons/icons";
import { STR_Purchases, STR_Sales, STR_Person, STR_Catalog, STR_OutOfStock, STR_Backup, STR_Expense, STR_User } from "../lang/en";
import ListModal from "./ListModal";
import { LIST_PURCHASES, LIST_SALES, LIST_PERSON, BU, LIST_CATALOGQ, LIST_CATALOG, setAll, LIST_EXPENSE, isAdmin, LIST_BIASSALES, isBias, LIST_USER } from "../functions/DataFunctions";
import { BackUpModal } from "./BackUpModal";
import { backup } from "../functions/LocalStorage";
import { BIAS } from "../functions/DataModel";


export const Menu: React.FC = () => {

    const [show, setShow] = useState(0);

    return (
        <>
            <IonMenu side="start" contentId="contentMenu">
                <IonHeader>
                    <IonToolbar color="primary">
                        <img className="logo" alt='Mekuya Games' src='assets/img/mekuyaLogo.png'></img>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonList>
                        {isAdmin() ? <>
                            <IonButton expand='block' color="success" onClick={() => backup()}><IonIcon icon={saveOutline} /></IonButton>
                            <IonItem button onClick={() => setShow(LIST_EXPENSE)} >
                                <IonIcon slot="start" icon={cashOutline} />
                                <IonLabel>{STR_Expense}</IonLabel>
                            </IonItem>
                            <IonItem button onClick={() => setShow(LIST_PURCHASES)}>
                                <IonIcon slot="start" icon={basket}></IonIcon>
                                <IonLabel>{STR_Purchases}</IonLabel>
                            </IonItem></> : ''}
                        {isBias() ? '' : <IonItem button onClick={() => setShow(LIST_SALES)} >
                            <IonIcon slot="start" icon={cart} />
                            <IonLabel>{STR_Sales}</IonLabel>
                        </IonItem>}
                        {isAdmin() || isBias() ? <IonItem button onClick={() => setShow(LIST_BIASSALES)} >
                            <img slot="start" className="icon" alt={BIAS} src={"assets/img/" + BIAS + ".png"}></img>
                            <IonLabel>{BIAS.toUpperCase()} {STR_Sales}</IonLabel>
                        </IonItem> : ''}
                        <IonItem button onClick={() => setShow(LIST_PERSON)} >
                            <IonIcon slot="start" icon={person} />
                            <IonLabel>{STR_Person}</IonLabel>
                        </IonItem>
                        {isAdmin() ? <>
                            <IonItem button onClick={() => setShow(BU)} >
                                <IonIcon slot="start" icon={downloadOutline} />
                                <IonLabel>{STR_Backup}</IonLabel>
                            </IonItem>
                            <IonItem button onClick={() => setShow(LIST_CATALOGQ)} >
                                <IonIcon slot="start" icon={warning} />
                                <IonLabel>{STR_OutOfStock}</IonLabel>
                            </IonItem>
                            <IonItem button onClick={() => setShow(LIST_CATALOG)} >
                                <IonIcon slot="start" icon={newspaperOutline} />
                                <IonLabel>{STR_Catalog}</IonLabel>
                            </IonItem>
                            <IonItem button onClick={() => setShow(LIST_USER)} >
                                <IonIcon slot="start" icon={peopleOutline} />
                                <IonLabel>{STR_User}</IonLabel>
                            </IonItem></> : ''}
                    </IonList>
                </IonContent>
            </IonMenu>
            <IonRouterOutlet id="contentMenu" />
            <IonModal isOpen={show !== 0} id="printregion" cssClass='printModal' swipeToClose={true} backdropDismiss={true} onDidDismiss={() => { setShow(0); setAll(true) }}>
                {show === BU ? <BackUpModal /> : <ListModal type={show} shop='' />}
            </IonModal>
        </>
    );
}

