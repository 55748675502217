import { useState, useRef } from "react";
import { konto, trx, RDNKonto, getInvested, getTrfFromTrx, isSaleTransfer, isTransfer, isSale, transfer, TRANSFER, isKonto, addTxf, MainKonto, getSale, saleNoteNumberID, MGProvider } from "../functions/Log";
import { Shop, formatCurrency, RUPIAH, BIAS } from "../functions/DataModel";
import React from "react";
import { IonItem, IonAvatar, IonLabel, IonIcon, IonButton, IonModal, IonContent, IonToolbar, IonHeader, IonTitle } from "@ionic/react";
import { getBookCats, getCategoryNames, getShop, printDateS } from "../functions/DataFunctions";
import { add, close, eyeOffOutline, eyeOutline, remove, send } from "ionicons/icons";
import { STR_Sale, STR_TarikDana } from "../lang/en";
import LogModal from "./LogModal";
import { List, updateList } from "./List";
import SalesCard, { EDIT } from "./SalesCard";
import { BOOK_ABENG, setItemsImage } from "../functions/LocalStorage";
import { refresh } from "../pages/Tab4";

interface ContainerProps {
  konto: Shop | konto;
}

const LogCard: React.FC<ContainerProps> = ({ konto }) => {

  const [showItem, setShowItem] = useState(false);
  const [show, setShow] = useState(undefined as trx | transfer | undefined);
  const [amount, setAmount] = useState('');
  const [showSale, setShowSale] = useState(true);
  const relModal = useRef(null);
  const updateAmountE = useRef(null);

  function clickTrx(e: React.MouseEvent<HTMLIonItemElement, MouseEvent>, trx: trx) {
    e.stopPropagation();
    let sale = getSale(trx);
    if (sale) {
      setItemsImage([sale]).then(() => {
        setShow(isTransfer(trx) ? getTrfFromTrx(trx) : trx)
      });
    }
    else setShow(isTransfer(trx) ? getTrfFromTrx(trx) : trx);
  }

  const fill = (trx: trx, idx: number) => {
    /*<h2>{trx.note} {getSale(trx)?.client._id}</h2>*/
    return (
      <IonItem key={idx} button onClick={e => clickTrx(e, trx)}>
        <IonAvatar>
          <IonIcon icon={trx.type > 0 ? add : remove} />
        </IonAvatar>
        <IonLabel className="fleft fulltext">
          <h2>{saleNoteNumberID(trx.note)}</h2>
          <h3>{printDateS(trx.date)}</h3>
        </IonLabel>
        <IonLabel className={trx.type > 0 ? "fright available" : "fright warning"}>{formatCurrency(konto.currency, trx.amount)}</IonLabel>
      </IonItem>
    );
  }

  function printTotalInvested() {
    return <span className='risk smaller'> - {formatCurrency(konto.currency, getInvested())}</span>;
  }

  function printisMGProvided() {
    return <span className='risk smaller'> - [{getCategoryNames(getBookCats(BOOK_ABENG))}]</span>;
  }

  return (
    <>
      <IonItem lines="full" button onClick={() => setShowItem(!showItem)}>
        <IonAvatar className="marginr">
          {getShop(konto._id) ? <img alt={konto._id.toUpperCase()} src={"assets/img/" + konto._id + ".png"}></img> : <IonIcon size="large" icon={(konto as konto).icon} />}
        </IonAvatar>
        <IonLabel className={'calculate' in konto && konto.calculate ? "fleft" : "fleft passive"}>{konto.name}{konto._id === RDNKonto ? printTotalInvested() : ''}{konto._id === MGProvider ? printisMGProvided() : ''}</IonLabel>
        <IonLabel className={konto.balance < 0 ? "fright warning" : "fright available"}>{formatCurrency(konto.currency, konto.balance)}</IonLabel>
        {!isKonto(konto) && konto.balance > 0 ?
          <IonButton hidden={konto._id === BIAS} slot="end" className={"npaid endButton"} onClick={e => {
            e.stopPropagation();
            if (konto.balance > 0) {
              addTxf({
                date: new Date().toISOString(),
                from: konto._id,
                to: MainKonto,
                amount: konto.balance,
                fee: 0,
                note: STR_TarikDana + ' ' + konto.name
              }, true);
              refresh(konto.name, false);
            }
          }}>
            <IonIcon icon={send} />
          </IonButton> : ''
        }
      </IonItem>
      <div hidden={!showItem} className="roundBorder margin10">
        {!showItem ? '' : <List data={konto.trx} fill={fill} />}
      </div>
      <IonModal ref={relModal} isOpen={show !== undefined} swipeToClose={true} backdropDismiss={true} onDidDismiss={() => { setShow(undefined); setAmount('') }}>
        {show && (isSaleTransfer(show as trx) || isSale(show as trx)) && getSale(show as trx) && showSale ?
          <>
            <IonHeader>
              <IonToolbar>
                <IonButton slot="end" className={!showSale ? "paid" : "npaid"} onClick={() => setShowSale(!showSale)}><IonIcon slot="icon-only" icon={!showSale ? eyeOffOutline : eyeOutline} /></IonButton>
                <IonButton slot="start" onClick={() => setShow(undefined)}><IonIcon slot="icon-only" icon={close} /></IonButton>
                <IonTitle>{STR_Sale}</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <IonButton ref={updateAmountE} hidden onClick={e => { setAmount(formatCurrency(RUPIAH, (getSale(show as trx)!.total - getSale(show as trx)!.fee))); updateList() }} />
              <IonHeader className='center large'>{amount === '' ? formatCurrency(RUPIAH, (getSale(show as trx)!.total - getSale(show as trx)!.fee)) : amount}</IonHeader>
              <SalesCard sale={getSale(show as trx)!} type={EDIT} rel={updateAmountE} po={false} />
            </IonContent>
          </>
          :
          <>
            <LogModal trxtype={show ? 'to' in show ? TRANSFER : (show as trx).type : -1} txf={show} copy={false} rel={relModal} />
            <IonToolbar>
              <IonButton slot="end" className={!showSale ? "paid" : "npaid"} onClick={() => setShowSale(!showSale)}><IonIcon slot="icon-only" icon={!showSale ? eyeOffOutline : eyeOutline} /></IonButton>
            </IonToolbar>
          </>}
      </IonModal>
    </>
  );
};

export default LogCard;