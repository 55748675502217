import React, { SyntheticEvent, useState, useRef } from 'react';
import { IonItem, IonLabel, IonIcon, IonButton, IonInput, IonTextarea, IonAlert, IonSelect, IonSelectOption, isPlatform, IonGrid, IonRow, IonCol } from '@ionic/react';
import { imageOutline, camera, close, refresh, barcodeOutline, pencil, checkmark } from 'ionicons/icons';
import { Item } from '../functions/DataModel';
import { sortInventory, requestUpdateList, existName, existKey, setSPPrice, categories, getImageURL, changeItemID, LIST_INVENTORY, sortBiasInventory } from '../functions/DataFunctions';
import { STR_OK, STR_ErrorItemKey1, STR_ErrorItemExists, STR_ErrorItemName1, STR_Key, STR_Name, STR_Category, STR_Quantity, STR_Reserve, STR_Purchase, STR_Price, STR_AddSalesPrice, STR_ProductInfo, STR_ErrorNull, STR_MSRP, STR_multipleEntryInfo, STR_ProductDescription, STR_Weight, STR_Dimensions } from '../lang/en';
import { updateItem, itemImageID, openItemImage, removeItemImage, getBLOB, updateBiasItem } from '../functions/LocalStorage';
import { usePhotoGallery } from '../hooks/usePhotoGallery';
import { BarcodeScanner } from '@ionic-native/barcode-scanner';

interface ContainerProps {
  item: Item;
  copy: boolean;
  mode: number
}

const ItemSheet: React.FC<ContainerProps> = ({ item, copy, mode }) => {

  const neu = !existKey(item._id, mode) || copy;

  const defaultImage = mode === LIST_INVENTORY ? "assets/img/item.jpg" : "assets/img/itemBias.jpg";
  const [productImage, setProductImage] = useState(getImageURL(item));

  const [showAlert, setShowAlert] = useState('');
  const [error, setError] = useState('');

  const purchasePB = useRef(null);

  const { takePhoto, takePic } = usePhotoGallery();

  function takePicture(n: number) {
    switch (n) {
      case 0: {
        takePhoto().then(photo => {
          if (photo && photo.base64) {
            getBLOB(photo.base64).then(file => openItemImage(file, item, neu));
          }
        }).catch(err => alert(JSON.stringify(err)));
        break;
      }
      case 1: {
        takePic().then(photo => {
          if (photo && photo.base64) {
            getBLOB(photo.base64).then(file => openItemImage(file, item, neu));
          }
        }).catch(err => alert(JSON.stringify(err)));
        break;
      }
      default: break;
    }

  }

  async function openScanner(el: HTMLInputElement) {
    const data = await BarcodeScanner.scan();
    el.value = data.text;
  };

  function img404(event: SyntheticEvent<HTMLImageElement, Event>) {
    (event.currentTarget as HTMLImageElement).src = defaultImage;
  }

  function setKey(key: string) {
    changeItemID(item, key, mode);
  }

  function enableEdit(e: React.MouseEvent, id: string) {
    let err = e.currentTarget.parentElement?.parentElement?.previousElementSibling?.firstChild as HTMLElement;
    let el = e.currentTarget.previousElementSibling as HTMLInputElement;
    let box = el.parentElement as HTMLElement;
    box.style.border = 'none';

    if (!el.disabled) {
      switch (id) {
        case 'key': {
          let key = el.value;
          if (key !== '' && key === item._id) break;
          if (key === '' || existKey(key, mode)) {
            setError(key === '' ? STR_ErrorNull : STR_ErrorItemKey1 + key + STR_ErrorItemExists);
            err.style.display = 'flex';
            if (neu) item._id = '';
            box.style.border = "1px solid red";
          }
          else {
            if (neu) item._id = key;
            else setKey(key);
          }
          break;
        }
        case 'name': {
          let name = el.value.toUpperCase();
          if (name !== '' && name === item.ProductName) break;
          if (name === '' || existName(name, mode)) {
            setError(name === '' ? STR_ErrorNull : STR_ErrorItemName1 + name + STR_ErrorItemExists);
            err.style.display = 'flex';
            if (neu) item.ProductName = '';
            box.style.border = "1px solid red";
          }
          else {
            item.ProductName = name;
            if (!neu) {
              if (mode === LIST_INVENTORY) sortInventory(item);
              else sortBiasInventory(item);
              requestUpdateList(true);
            }
          }
          break;
        }
        case 'cat': {
          item.Category = el.value;
          if (!neu) {
            requestUpdateList(true);
          }
          break;
        }
        case 'quantity': {
          item.Quantity = Number.parseInt(el.value);
          break;
        }
        case 'reserve': {
          item.Reserve = Number.parseInt(el.value);
          break;
        }
        case 'pprice': {
          item.PurchasePrice = Number.parseFloat(el.value.replace(".", ""));
          break;
        }
        case 'sprice': {
          let n = setSPPrice(item, Number.parseFloat(el.value.replace(".", "")));
          (purchasePB.current! as HTMLIonInputElement).value = n;
          break;
        }
        case 'aprice': {
          item.AdditionalSalesPrices = el.value;
          break;
        }
        case 'mprice': {
          item.MSRPPrice = Number.parseFloat(el.value.replace(".", ""));
          break;
        }
        case 'desc': {
          item.description = el.value;
          break;
        }
        case 'dimP': {
          item.dimensionP = Number.parseFloat(el.value);
          break;
        }
        case 'dimL': {
          item.dimensionL = Number.parseFloat(el.value);
          break;
        }
        case 'dimT': {
          item.dimensionT = Number.parseFloat(el.value);
          break;
        }
        case 'weight': {
          item.weight = Number.parseFloat(el.value);
          break;
        }
        case 'info': {
          item.Info = el.value;
          break;
        }
        default: break;
      }
      if (!neu) {
        if (mode === LIST_INVENTORY) updateItem(item);
        else updateBiasItem(item);
        el.disabled = true;
        let ic = e.currentTarget.firstElementChild as HTMLIonIconElement;
        ic.icon = pencil;
        ic.color = "primary";
      }
    }
    else {
      el.disabled = false;
      let ic = e.currentTarget.firstElementChild as HTMLIonIconElement;
      ic.icon = checkmark;
      ic.color = "success";
      el.focus();
    }
  }

  function submit(e: CustomEvent<FocusEvent> | CustomEvent<void>) {
    let b = (e.currentTarget as HTMLElement).nextElementSibling as HTMLButtonElement;
    b.click();
  }

  function getCSSRotate(rotate: number) {
    let n = "rotate";
    let d = (rotate % 4) * 90;
    return d === 0 ? '' : n + d;
  }

  function saveImage(n: number) {
    let degree = (n % 4) * 90;

    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext('2d');

    var imgC = document.getElementById(itemImageID) as HTMLImageElement;

    let img = new Image();
    img.src = imgC?.src;
    let oriwidth = img.width;
    let oriheight = img.height;
    img.width = 120;
    img.height = (120 / oriwidth) * oriheight;

    img.onload = function () {
      if (ctx) {
        var cw = img.width, ch = img.height, cx = 0, cy = 0;

        //   Calculate new canvas size and x/y coorditates for image
        switch (degree) {
          case 90:
            cw = img.height;
            ch = img.width;
            cy = img.height * (-1);
            break;
          case 180:
            cx = img.width * (-1);
            cy = img.height * (-1);
            break;
          case 270:
            cw = img.height;
            ch = img.width;
            cx = img.width * (-1);
            break;
        }

        //  Rotate image            
        canvas.width = cw;
        canvas.height = ch;
        ctx.rotate(degree * Math.PI / 180);

        ctx.drawImage(img, cx, cy, canvas.width, canvas.height);
      }
      canvas.toBlob(function (blob) { openItemImage(blob, item, neu) });
    };
  }

  return (
    <>
      <div>
        <IonLabel className="warning center hidden margint marginb">{error}</IonLabel>
        <div className="center">
          <img id={itemImageID} src={productImage} height="160px" alt='' onError={img404}></img>
        </div>
        <div className="center">
          <IonButton fill="clear" onClick={() => takePicture(1)}>
            {isPlatform('hybrid') ? '' : <input id="fileImg" type="file" accept="image/*" onChange={e => { openItemImage(e.target.files ? e.target.files.item(0) : null, item, neu); setProductImage(URL.createObjectURL(e.target.files?.item(0)!)) }} onClick={e => e.stopPropagation()} />}
            <IonIcon icon={imageOutline} slot="icon-only"></IonIcon>
          </IonButton>
          <IonButton fill="clear" onClick={() => takePicture(0)}>
            <IonIcon icon={camera} slot="icon-only"></IonIcon>
          </IonButton>
          <IonButton fill="clear" onClick={() => saveImage(1)}>
            <IonIcon icon={refresh} slot="icon-only"></IonIcon>
          </IonButton>
          <IonButton fill="clear" onClick={() => removeItemImage(item, neu)}>
            <IonIcon icon={close} slot="icon-only"></IonIcon>
          </IonButton>
        </div>
      </div>
      <div>
        <IonItem>
          <IonLabel position="stacked">{STR_Key}</IonLabel>
          <IonInput disabled={!neu} required={true} value={item._id} onIonBlur={e => neu ? submit(e) : ''}></IonInput>
          <IonButton hidden={neu} slot="end" fill="clear" size="large" onClick={e => enableEdit(e, 'key')}><IonIcon icon={pencil} /></IonButton>
          <IonButton slot="end" fill="clear" size="large" onClick={e => openScanner(e.currentTarget.previousElementSibling?.previousElementSibling as HTMLInputElement)}><IonIcon icon={barcodeOutline} /></IonButton>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">{STR_Name}</IonLabel>
          <IonInput disabled={!neu} required={true} value={item.ProductName} onIonBlur={e => neu ? submit(e) : ''}></IonInput>
          <IonButton hidden={neu} slot="end" fill="clear" size="large" onClick={e => enableEdit(e, 'name')}><IonIcon icon={pencil} /></IonButton>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">{STR_Category}</IonLabel>
          <IonSelect disabled={!neu} interface="action-sheet" value={item.Category} onIonBlur={e => neu ? submit(e) : ''}>
            {categories.map((cat, idx) => cat._id === 'ALL' ? '' : <IonSelectOption key={idx} value={cat._id}>{cat.name}</IonSelectOption>)}
          </IonSelect>
          <IonButton hidden={neu} slot="end" fill="clear" size="large" onClick={e => enableEdit(e, 'cat')}><IonIcon icon={pencil} /></IonButton>
        </IonItem>
        <IonItem>
          <div className="quantity"><span className="label">{STR_Quantity}</span><br />
            <IonInput disabled={!neu} value={item.Quantity} type="number" min="0" onIonBlur={e => neu ? submit(e) : ''}></IonInput>
            <IonButton className="floatright" size="large" hidden={neu} fill="clear" onClick={e => enableEdit(e, 'quantity')}><IonIcon icon={pencil} /></IonButton>
          </div>
          <div className="price"><span className="label">{STR_Reserve}</span><br />
            <IonInput disabled={!neu} value={item.Reserve} type="number" min="0" onIonBlur={e => neu ? submit(e) : ''}></IonInput>
            <IonButton className="floatright" size="large" hidden={neu} fill="clear" onClick={e => enableEdit(e, 'reserve')}><IonIcon icon={pencil} /></IonButton>
          </div>
        </IonItem>
        <IonItem>
          <div className="quantity"><span className="label">{STR_Purchase}</span><br />
            <IonInput ref={purchasePB} disabled={!neu} value={item.PurchasePrice} type="number" min="0" step='500' onIonBlur={e => neu ? submit(e) : ''}></IonInput>
            <IonButton className="floatright" size="large" hidden={neu} fill="clear" onClick={e => enableEdit(e, 'pprice')}><IonIcon icon={pencil} /></IonButton>
          </div>
          <div className="price"><span className="label">{STR_Price}</span><br />
            <IonInput disabled={!neu} value={item.SalePrice} type="number" min="0" step='500' onIonBlur={e => neu ? submit(e) : ''}></IonInput>
            <IonButton className="floatright" size="large" hidden={neu} fill="clear" onClick={e => enableEdit(e, 'sprice')}><IonIcon icon={pencil} /></IonButton>
          </div>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">{STR_MSRP}</IonLabel>
          <IonInput disabled={!neu} value={item.MSRPPrice} onIonBlur={e => neu ? submit(e) : ''}></IonInput>
          <IonButton hidden={neu} slot="end" fill="clear" size="large" onClick={e => enableEdit(e, 'mprice')}><IonIcon icon={pencil} /></IonButton>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">{STR_AddSalesPrice + ' - ' + STR_multipleEntryInfo}</IonLabel>
          <IonInput disabled={!neu} value={item.AdditionalSalesPrices} onIonBlur={e => neu ? submit(e) : ''}></IonInput>
          <IonButton hidden={neu} slot="end" fill="clear" size="large" onClick={e => enableEdit(e, 'aprice')}><IonIcon icon={pencil} /></IonButton>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">{STR_ProductInfo}</IonLabel>
          <IonTextarea disabled={!neu} value={item.Info} onIonBlur={e => neu ? submit(e) : ''}></IonTextarea>
          <IonButton hidden={neu} slot="end" fill="clear" size="large" onClick={e => enableEdit(e, 'info')}><IonIcon icon={pencil} /></IonButton>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">{STR_Weight}</IonLabel>
          <IonInput disabled={!neu} value={item.weight!} onIonBlur={e => neu ? submit(e) : ''}></IonInput>
          <IonButton hidden={neu} slot="end" fill="clear" size="large" onClick={e => enableEdit(e, 'weight')}><IonIcon icon={pencil} /></IonButton>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">{STR_Dimensions}</IonLabel>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonInput disabled={!neu} className='textcenter' value={item.dimensionP!} onIonBlur={e => neu ? submit(e) : ''}></IonInput>
                <IonButton hidden={neu} className="floatright" fill="clear" size="large" onClick={e => enableEdit(e, 'dimP')}><IonIcon icon={pencil} /></IonButton>
              </IonCol>
              x
              <IonCol>
                <IonInput disabled={!neu} className='textcenter'  value={item.dimensionL!} onIonBlur={e => neu ? submit(e) : ''}></IonInput>
                <IonButton hidden={neu} className="floatright" fill="clear" size="large" onClick={e => enableEdit(e, 'dimL')}><IonIcon icon={pencil} /></IonButton>
              </IonCol>
              x
              <IonCol>
                <IonInput disabled={!neu} className='textcenter'  value={item.dimensionT!} onIonBlur={e => neu ? submit(e) : ''}></IonInput>
                <IonButton hidden={neu} className="floatright" fill="clear" size="large" onClick={e => enableEdit(e, 'dimT')}><IonIcon icon={pencil} /></IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">{STR_ProductDescription}</IonLabel>
          <IonTextarea disabled={!neu} value={item.description!} onIonBlur={e => neu ? submit(e) : ''}></IonTextarea>
          <IonButton hidden={neu} slot="end" fill="clear" size="large" onClick={e => enableEdit(e, 'desc')}><IonIcon icon={pencil} /></IonButton>
        </IonItem>
      </div>
      <div>
        <IonAlert isOpen={showAlert !== ''}
          onDidDismiss={() => setShowAlert('')}
          message={showAlert}
          buttons={[{
            text: STR_OK,
            cssClass: 'warning'
          }]}></IonAlert>
      </div>
    </>
  );
};

export default ItemSheet;