import { Plugins, AppState } from '@capacitor/core';
import React, { useEffect, useState, useRef } from 'react';
import { IonLoading, IonButton, IonIcon, IonModal } from '@ionic/react';
import { STR_Wait } from '../lang/en';
import { checkLogIn, getUsers, syncManual } from '../functions/LocalStorage';
import { syncOutline, logInOutline } from 'ionicons/icons';
import Login from './Login';
import { printHeader } from '../functions/Output';

interface IUserManager {
  user: string,
  passwd: string,
  staff: string,
  isLoggedIn: boolean;
  setLoading: Function;
  setLogin: Function;
  setIsLoggedIn: Function;
  setStaff: Function
}

export var user: IUserManager = { user: '', passwd: '', staff: '', isLoggedIn: false, setLoading: (loading: boolean) => { }, setLogin: (login: boolean) => { }, setIsLoggedIn: (loggedIn: boolean) => { }, setStaff: (staff: string) => { } };

export const UserContext = React.createContext<IUserManager>(user);

export const AppContextProvider = (props: any) => {

  const [showLoading, setShowLoading] = useState(false);

  const loginModal = useRef(null);
  const [showLogin, setShowLogin] = useState(false);

  const [userStaff, setUserStaff] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);

  const { App } = Plugins;

  App.addListener('appStateChange', (state: AppState) => {
    if (user.isLoggedIn && state.isActive) {
      checkLogIn().then(onsync => user.setLogin(!onsync))
    }
  });

  user = {
    user: '',
    passwd: '',
    staff: userStaff,
    isLoggedIn: loggedIn,
    setLoading: (bool: boolean) => setShowLoading(bool),
    setLogin: (bool: boolean) => setShowLogin(bool),
    setIsLoggedIn: (b: boolean) => setLoggedIn(b),
    setStaff: (user: string) => setUserStaff(user)
  };

  useEffect(() => {
    user.setLoading(true);
    getUsers().then(() => {
      setShowLogin(true);
      user.setLoading(false)
    }).catch(() => user.setLoading(false));
  }, []);

  return (
    <UserContext.Provider value={user}>
      <UserContext.Consumer>
        {({ isLoggedIn }) => {
          return (
            <IonButton className="loginB" size="small" fill="clear" color={!isLoggedIn ? "warning" : "success"} onClick={() => isLoggedIn ? syncManual() : setShowLogin(true)}>
              <IonIcon icon={isLoggedIn ? syncOutline : logInOutline} />
            </IonButton>
          );
        }}
      </UserContext.Consumer>
      <img hidden itemID="fragile" alt='fragile' src='assets/img/fragile.jpeg' />
      <img hidden itemID="fragiles" alt='fragile' src='assets/img/fragile_s.jpg' />
      <img hidden itemID="mekuyaLogo" alt='' src='assets/img/mekuyaLogo.jpg' />
      <img hidden alt='' src='assets/img/BiasLogo.jpg' />
      <img hidden alt='' src='assets/img/MegakonLogo.jpg' />
      <IonLoading isOpen={showLoading} backdropDismiss={false} message={STR_Wait} />
      {showLoading ? '' : props.children}
      <IonModal ref={loginModal} isOpen={showLogin} swipeToClose={false} backdropDismiss={false} onDidDismiss={() => setShowLogin(false)}>
        <Login rel={loginModal} />
      </IonModal>
      <div id="labels"><div className="placeholder" /></div>
    </UserContext.Provider>
  );
}
