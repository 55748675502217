import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { cart, fileTrayFullSharp, bookOutline, walletOutline, keyOutline, newspaperOutline, receiptOutline } from 'ionicons/icons';
import Tab1 from './pages/Tab1';
import Tab2 from './pages/Tab2';
import Tab3 from './pages/Tab3';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { AppContextProvider, UserContext } from './components/AppContextProvider';
import { Menu } from './components/Menu';
import { STR_Inventory, STR_Log, STR_Transaction, STR_Balance, STR_KEYS, STR_Note, STR_Invoice } from './lang/en';
import Tab4 from './pages/Tab4';
import Keys from './pages/Keys';
import { isAdmin, isBias } from './functions/DataFunctions';
import Notes from './pages/Notes';
import Invoices from './pages/Invoices';

const App: React.FC = () => {

  return (
    <IonApp>
      <AppContextProvider>
        <Menu />
        <UserContext.Consumer>
          {({ staff }) => {
            if (staff && isAdmin()) return (
              <IonRouterOutlet>
                <IonReactRouter>
                  <IonTabs>
                    <IonRouterOutlet>
                      <Route path="/tab1" component={Tab1} exact={true} />
                      <Route path="/tab2" component={Tab2} exact={true} />
                      <Route path="/tab3" component={Tab3} exact={true} />
                      <Route path="/tab7" component={Invoices} exact={true} />
                      <Route path="/tab4" component={Tab4} exact={true} />
                      <Route path="/tab5" component={Keys} exact={true} />
                      <Route path="/tab6" component={Notes} exact={true} />
                      <Route path="/" render={() => <Redirect to="/tab1" />} exact={true} />
                    </IonRouterOutlet>
                    <IonTabBar slot="bottom">
                      <IonTabButton tab="tab1" href="/tab1">
                        <IonIcon icon={fileTrayFullSharp} />
                        <IonLabel>{STR_Inventory}</IonLabel>
                      </IonTabButton>
                      <IonTabButton tab="tab2" href="/tab2">
                        <IonIcon icon={cart} />
                        <IonLabel>{STR_Transaction}</IonLabel>
                      </IonTabButton>
                      <IonTabButton tab="tab3" href="/tab3">
                        <IonIcon icon={bookOutline} />
                        <IonLabel>{STR_Log}</IonLabel>
                      </IonTabButton>
                      <IonTabButton tab="tab7" href="/tab7">
                        <IonIcon icon={receiptOutline} />
                        <IonLabel>{STR_Invoice}</IonLabel>
                      </IonTabButton>
                      <IonTabButton tab="tab4" href="/tab4">
                        <IonIcon icon={walletOutline} />
                        <IonLabel>{STR_Balance}</IonLabel>
                      </IonTabButton>
                      <IonTabButton tab="tab5" href="/tab5">
                        <IonIcon icon={keyOutline} />
                        <IonLabel>{STR_KEYS}</IonLabel>
                      </IonTabButton>
                      <IonTabButton tab="tab6" href="/tab6">
                        <IonIcon icon={newspaperOutline} />
                        <IonLabel>{STR_Note}</IonLabel>
                      </IonTabButton>
                    </IonTabBar>
                  </IonTabs>
                </IonReactRouter>
              </IonRouterOutlet>
            )
            else if (staff && isBias()) return (
              <IonRouterOutlet>
                <IonReactRouter>
                  <IonTabs>
                    <IonRouterOutlet>
                      <Route path="/tab1" component={Tab1} exact={true} />
                      <Route path="/tab2" component={Tab2} exact={true} />
                      <Route path="/" render={() => <Redirect to="/tab2" />} exact={true} />
                    </IonRouterOutlet>
                    <IonTabBar slot="bottom">
                      <IonTabButton tab="tab1" href="/tab1">
                        <IonIcon icon={fileTrayFullSharp} />
                        <IonLabel>{STR_Inventory}</IonLabel>
                      </IonTabButton>
                      <IonTabButton tab="tab2" href="/tab2">
                        <IonIcon icon={cart} />
                        <IonLabel>{STR_Transaction}</IonLabel>
                      </IonTabButton>
                    </IonTabBar>
                  </IonTabs>
                </IonReactRouter>
              </IonRouterOutlet>
            )
            else return (
              <Tab2 />
            )
          }}
        </UserContext.Consumer>
      </AppContextProvider>
    </IonApp>
  );
}

export default App;
