import React, { useState, useContext, useRef, useEffect } from "react";
import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonLabel,
    IonList,
    IonItem,
    IonInput,
    IonButton,
    IonIcon,
    IonSelect,
    IonSelectOption,
    IonSegment,
    IonSegmentButton,
    IonTextarea,
    IonCheckbox,
    isPlatform,
} from "@ionic/react";
import { close } from "ionicons/icons";
import { UserContext } from "./AppContextProvider";
import { fillFromLocalDB, getLocalUsers, login, saveLocalUser, saveUser, syncManual } from "../functions/LocalStorage";
import { STR_ERROR, STR_ErrorNull, STR_Info, STR_Login, STR_LoginPwd, STR_LoginUser, STR_Pwd8, STR_Register, STR_UseFingerprint, STR_UserNameNotAvailable, STR_UserSavedFailed, STR_WrongPassword } from "../lang/en";
import { BIAS, BUKALAPAK, DEV, DEVP, LAZADA, Sale, SHOPEE, TOKOPEDIA, UserL } from "../functions/DataModel";
import { isAdmin, MD5, offAllowed } from "../functions/DataFunctions";
import { FingerprintAIO } from "@ionic-native/fingerprint-aio";
import { profitBG } from "../functions/Log";

interface ContainerProps {
    rel: any;
}

export var users: UserL[] = [];
var savedUser = { id: '', fp: false };
export function setSavedUser(name: string, fingerprint: boolean) {
    savedUser.id = name;
    savedUser.fp = fingerprint;
}

export function setUsers(list: UserL[]) {
    users = list;
}

export function userNameNotAvailable(name: string) {
    for (var i = 0; i < users.length; i++) {
        if (users[i]._id.toLowerCase() === name.toLowerCase()) return true;
    }
    return false;
}

function getuserpass(user: string) {
    for (var i = 0; i < users.length; i++) {
        if (users[i]._id === user) return users[i].pwd;
    }
    return '';
}

function passwordMatched(user: string, pwd: string, fp: boolean) {
    for (var i = 0; i < users.length; i++) {
        if (users[i]._id === user) {
            if (fp) return users[i].pwd === pwd;
            else return users[i].pwd === MD5(pwd);
        }
    }
    return false;
}

const Login: React.FC<ContainerProps> = ({ rel }) => {

    const user = useContext(UserContext);

    const [neu, setNeu] = useState(true);
    const [error, setError] = useState("");

    const [usefp, setUsefp] = useState(false);

    const inputUser = useRef(null);
    const inputselectUser = useRef(null);
    const inputPwd = useRef(null);
    const inputInfo = useRef(null);
    const inputFingerprint = useRef(null);

    function showFingerprintAuthDlg() {

        FingerprintAIO.isAvailable().then((result: any) => {
            console.log(result)

            FingerprintAIO.show({
                cancelButtonTitle: 'Cancel',
                description: "Fingerprint",
                disableBackup: true,
                title: 'Fingerprint Authentication',
                fallbackButtonTitle: 'Back',
                subtitle: 'Login with Fingerprint'
            })
                .then((result: any) => {
                    if (savedUser.id.length > 1) {
                        (inputselectUser.current! as HTMLIonInputElement).value = savedUser.id;
                        (inputUser.current! as HTMLIonInputElement).value = savedUser.id;
                        (inputPwd.current! as HTMLIonInputElement).value = getuserpass(savedUser.id);
                        (inputFingerprint.current! as HTMLIonCheckboxElement).checked = savedUser.fp;
                        loginClick(true);
                    }
                    console.log(result);
                    setError("Successfully Authenticated!")
                })
                .catch((error: any) => {
                    console.log(error)
                    setError("Match not found!")
                });

        })
            .catch((error: any) => {
                console.log(error)
            });
    }

    const loginClick = (fp: boolean) => {
        try {
            let name = (inputUser.current! as HTMLInputElement).value;
            if (passwordMatched(name, (inputPwd.current! as HTMLInputElement).value, fp)) {
                user.setStaff(name);
                saveUser(name, (inputFingerprint.current! as HTMLIonCheckboxElement).checked);
                login(DEV, DEVP).then((response) => response.json())
                    .then((responseData) => {
                        if (responseData.ok) {
                            user.setIsLoggedIn(true);
                            closeModal();
                            syncManual();
                        }
                        else if (responseData.error) {
                            user.setIsLoggedIn(false);
                            setError(responseData.error + ': ' + responseData.reason);
                            if (offAllowed() /*isAdmin()*/) {
                                closeModal();
                                fillFromLocalDB();
                            }
                            else user.setLoading(false);
                        }
                    }).catch(err => {
                        user.setIsLoggedIn(false);
                        setError('Error: ' + err);
                        if (offAllowed()) {
                            closeModal();
                            fillFromLocalDB();
                        }
                        else user.setLoading(false);
                    });
            }
            else {
                setError(STR_WrongPassword);
            }
        } catch (error) {
            setError(STR_ERROR + ' : ' + error);
        }
    };

    const registerClick = () => {
        try {
            let userdata = {
                _id: (inputUser.current! as HTMLInputElement).value,
                pwd: (inputPwd.current! as HTMLInputElement).value,
                info: (inputInfo.current! as HTMLInputElement).value,
                books: [] as unknown as [{ name: string, cats: string[] }],
                savedList: [] as unknown as [{ name: string, list: Sale[] }],
                shopkonto: [TOKOPEDIA, SHOPEE, BUKALAPAK, BIAS, LAZADA, "TikTok"],
                invoices: [],
                isMGProvided: [] as string[],
                profitMG: profitBG
            };
            if (userdata._id === '') {
                setError(STR_LoginUser + ' ' + STR_ErrorNull);
                return;
            }
            if (userdata.pwd.length < 8) {
                setError(STR_LoginPwd + ' ' + STR_ErrorNull + ' & ' + STR_Pwd8);
                return;
            }
            if (!userNameNotAvailable(userdata._id)) {
                userdata.pwd = MD5(userdata.pwd);
                saveLocalUser(userdata).then(() => {
                    getLocalUsers().then(function () {
                        (inputselectUser.current! as HTMLIonInputElement).value = userdata._id;
                        setNeu(false);
                    })
                });
            }
            else {
                setError(STR_UserNameNotAvailable);
            }
        } catch (error) {
            alert(STR_UserSavedFailed);
            setError(STR_UserSavedFailed);
        }
    }

    function closeModal() {
        (rel.current as HTMLIonModalElement).dismiss();
    }

    function reset() {
        (inputUser.current! as HTMLInputElement).value = '';
        (inputPwd.current! as HTMLInputElement).value = '';
        try { (inputselectUser.current! as HTMLInputElement).value = ''; }
        catch (err) { }
        try { (inputInfo.current! as HTMLInputElement).value = ''; }
        catch (err) { }

    }

    useEffect(() => {
        getLocalUsers().then(function () {
            if (savedUser.id.length > 1) {
                (inputselectUser.current! as HTMLIonInputElement).value = savedUser.id;
                (inputUser.current! as HTMLIonInputElement).value = savedUser.id;
                (inputFingerprint.current! as HTMLIonCheckboxElement).checked = savedUser.fp;
                setUsefp(savedUser.fp);
            }
            setNeu(false);
            if (isPlatform('hybrid') && (inputFingerprint.current! as HTMLIonCheckboxElement).checked) {
                showFingerprintAuthDlg();
            }
        })
    }, []);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle class="ion-text-center">Login</IonTitle>
                    {offAllowed() ? <IonButton slot="end" fill="clear" onClick={() => closeModal()}><IonIcon slot="icon-only" icon={close} /></IonButton> : ''}
                </IonToolbar>
            </IonHeader>
            <IonContent class="ion-padding">
                <IonSegment value={neu ? "neu" : "login"}>
                    <IonSegmentButton value="login" layout="icon-start" onClick={e => { reset(); setNeu(false) }}>
                        <IonLabel>{STR_Login}</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton hidden={!isAdmin()} value="neu" layout="icon-start" onClick={e => { reset(); setNeu(true) }}>
                        <IonLabel>{STR_Register}</IonLabel>
                    </IonSegmentButton>
                </IonSegment>
                <IonList>
                    <IonItem>
                        <IonLabel position="floating">{STR_LoginUser}</IonLabel>
                        <IonInput ref={inputUser} hidden={isAdmin() && !neu}
                            required
                            clearInput
                        />
                        <IonSelect ref={inputselectUser} hidden={!isAdmin() || (isAdmin() && neu)} className="inputE fleft fullwidth" interface="action-sheet" onIonChange={e => { (inputUser.current! as HTMLIonInputElement).value = e.detail.value; (inputPwd.current! as HTMLIonInputElement).value = '' }}>
                            {users.map((u, idx) => <IonSelectOption key={idx} value={u._id}>{u._id}</IonSelectOption>)}
                        </IonSelect>
                    </IonItem>

                    <IonItem>
                        <IonLabel position="floating">{STR_LoginPwd}</IonLabel>
                        <IonInput ref={inputPwd}
                            //value={user.passwd}
                            //onIonChange={(e: any) => user.passwd = e.target.value}
                            required
                            type="password"
                            clearInput
                        />
                    </IonItem>

                    <IonItem hidden={isPlatform('desktop')}>
                        <IonCheckbox ref={inputFingerprint} className="marginlr marginr" checked={usefp} />
                        <IonLabel>{STR_UseFingerprint}</IonLabel>
                    </IonItem>

                    {neu ?
                        <IonItem>
                            <IonLabel position="floating">{STR_Info}</IonLabel>
                            <IonTextarea ref={inputInfo} placeholder={STR_Info}></IonTextarea>
                        </IonItem>
                        : ''}
                    <p />
                    <br />
                    {neu ?
                        <IonButton shape="round" expand="full" /*routerLink="/profile"*/ onClick={registerClick}>{STR_Register}</IonButton>
                        :
                        <IonButton shape="round" expand="full" /*routerLink="/profile"*/ onClick={() => loginClick(false)}>{STR_Login}</IonButton>}
                </IonList>
                <IonLabel className='warning center smaller'>{error!}</IonLabel>
            </IonContent>
        </IonPage>
    );
};

export default Login;