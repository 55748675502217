import { IonButton, IonButtons, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonLabel, IonList, IonMenuButton, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { add, eyeOffOutline, eyeOutline } from 'ionicons/icons';
import './Keys.css';
import React, { useRef, useState } from 'react';
import GheimCard, { reset } from '../components/GheimCard';
import { List } from '../components/List';
import { gheim } from '../functions/DataFunctions';
import { Gheim } from '../functions/DataModel';
import { STR_Key, STR_KEYS, STR_LoginUser, STR_Pwd, STR_To } from '../lang/en';

var gheimItem: Gheim | null = null;
export function setGheimCopy(item: Gheim | null) {
  gheimItem = item;
}

const Keys: React.FC = () => {

  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const neubutton = useRef(null);

  function fill(item: Gheim, idx: number) {
    return <GheimCard key={idx} item={item} copy={false} open={open} rel={neubutton} />;
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonButton className={open ? "paid" : "npaid"} slot="end" onKeyUp={e => { e.stopPropagation(); e.preventDefault() }} onClick={() => setOpen(!open)}><IonIcon icon={open ? eyeOffOutline : eyeOutline} /></IonButton>
          <IonButton ref={neubutton} className={show ? "paid" : "npaid"} slot="end" onKeyUp={e => { e.stopPropagation(); e.preventDefault() }} onClick={() => { reset(); setShow(!show) }}><IonIcon icon={add} /></IonButton>
          <IonTitle>{STR_KEYS}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid className="bggheim">
          <IonRow>
            <IonCol><IonLabel>{STR_Key + ' ' + STR_To}</IonLabel></IonCol>
            <IonCol><IonLabel>{STR_LoginUser}</IonLabel></IonCol>
            <IonCol hidden={!open}><IonLabel>{STR_Pwd}</IonLabel></IonCol>
          </IonRow>
        </IonGrid>
        <IonList>
          <div className='neugheim' hidden={!show}><GheimCard item={gheimItem} copy={gheimItem !== null} open={open} rel={neubutton} /></div>
          <List data={gheim} fill={fill} />
        </IonList>
      </IonContent>
      <IonFooter>
      </IonFooter>
    </IonPage>
  );
};

export default Keys;