import React, { useState, useRef } from "react";
import { IonHeader, IonToolbar, IonButton, IonIcon, IonTitle, IonContent, isPlatform, IonLoading, IonLabel, IonItemDivider, IonItem } from "@ionic/react";
import { STR_Backup, STR_Import, STR_Wait, STR_LoadCSV, STR_Export, STR_DownloadCSV, STR_Inventory, STR_Person, STR_Purchases, STR_Sales, STR_Images, STR_Fee, STR_Image, STR_ConvertImagetoMono } from "../lang/en";
import { openInventory, openContacts, openImages, getCSV, openFee, printpos } from "../functions/LocalStorage";
import { codeDownloadOutline, close, downloadOutline, imageOutline } from "ionicons/icons";
import { FEE, LIST_INVENTORY, LIST_PERSON, LIST_PURCHASES, LIST_SALES } from "../functions/DataFunctions";
import { useFilesystem } from "@ionic/react-hooks/filesystem";
import { downloadShare } from "../functions/Output";
import { SHOPEE, TOKOPEDIA } from "../functions/DataModel";

export const BackUpModal: React.FC = () => {

    const closeButton = useRef(null);
    const [showLoading, setShowLoading] = useState(false);
    const { writeFile } = useFilesystem();

    function closeModal(e: React.MouseEvent) {
        const modal = e.currentTarget.parentElement?.parentElement?.parentElement?.parentElement?.parentElement;
        (modal as HTMLIonModalElement).dismiss();
    }

    function loadCSV(e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>, id: number, shop: string) {
        try {
            if (isPlatform('hybrid') || id === LIST_INVENTORY) {
                setShowLoading(true);
                switch (id) {
                    case LIST_INVENTORY: {
                        openInventory(null).then(() => {
                            setShowLoading(false);
                            (closeButton.current as unknown as HTMLIonButtonElement).click()
                        })
                        break;
                    }
                    case LIST_PERSON: {
                        openContacts(null).then(() => {
                            setShowLoading(false);
                            (closeButton.current as unknown as HTMLIonButtonElement).click()
                        })
                        break;
                    }
                    case FEE: {
                        openFee(null, shop).then(() => {
                            setShowLoading(false);
                            (closeButton.current as unknown as HTMLIonButtonElement).click()
                        })
                        break;
                    }
                    default: break;
                }
            }
        }
        catch (error) {
            alert(error);
        }
    }

    function saveFile(file: { filename: string; blob: Blob; base64: string }) {
        let filename = file.filename;
        let blob = file.blob;
        let base64 = file.base64;
        downloadShare(base64, blob, filename);
    }

    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonButton ref={closeButton} slot="start" onClick={e => closeModal(e)}><IonIcon slot="icon-only" icon={close} /></IonButton>
                    <IonTitle>{STR_Backup}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="fullwidth textcenter">
                <IonLoading isOpen={showLoading} backdropDismiss={false} message={STR_Wait} />

                {isPlatform('hybrid') ? '' : <>
                    <IonHeader><IonTitle>{STR_Image}</IonTitle></IonHeader>
                    <IonItem>
                        <IonLabel>{STR_ConvertImagetoMono} : </IonLabel>
                        <IonButton>
                            <IonIcon slot="start" icon={imageOutline} />
                            <input id="file" type="file" accept="image/*" onChange={e => printpos(URL.createObjectURL(e.target.files?.item(0)!))} onClick={e => e.stopPropagation()} />
                        </IonButton>
                    </IonItem></>}

                <IonHeader><IonTitle>{STR_Fee}</IonTitle></IonHeader>
                <IonItem>
                    <IonLabel>{SHOPEE.toLocaleUpperCase()} {STR_Fee} : </IonLabel>
                    <IonButton onClick={e => loadCSV(e, FEE, SHOPEE)}>
                        <IonIcon slot="start" icon={codeDownloadOutline} />
                        {isPlatform('hybrid') ? '' : <input id="file" type="file" accept=".csv" onChange={e => { setShowLoading(true); openFee(e.target.files, SHOPEE).then(() => { setShowLoading(false); (closeButton.current as unknown as HTMLIonButtonElement).click() }) }} onClick={e => e.stopPropagation()} />}
                        <IonLabel>{STR_LoadCSV}</IonLabel>
                    </IonButton>
                </IonItem>
                <IonItem>
                    <IonLabel>{TOKOPEDIA.toLocaleUpperCase()} {STR_Fee} : </IonLabel>
                    <IonButton onClick={e => loadCSV(e, FEE, TOKOPEDIA)}>
                        <IonIcon slot="start" icon={codeDownloadOutline} />
                        {isPlatform('hybrid') ? '' : <input id="file" type="file" accept=".csv" onChange={e => { setShowLoading(true); openFee(e.target.files, TOKOPEDIA).then(() => { setShowLoading(false); (closeButton.current as unknown as HTMLIonButtonElement).click() }) }} onClick={e => e.stopPropagation()} />}
                        <IonLabel>{STR_LoadCSV}</IonLabel>
                    </IonButton>
                </IonItem>

                <IonHeader><IonTitle>{STR_Import}</IonTitle></IonHeader>
                <IonItem>
                    <IonLabel>{STR_Inventory} : </IonLabel>
                    <IonButton onClick={e => loadCSV(e, LIST_INVENTORY, '')}>
                        <IonIcon slot="start" icon={codeDownloadOutline} />
                        {isPlatform('hybrid') ? '' : <input id="file" type="file" accept=".csv" onChange={e => { setShowLoading(true); openInventory(e.target.files).then(() => { setShowLoading(false); (closeButton.current as unknown as HTMLIonButtonElement).click() }) }} onClick={e => e.stopPropagation()} />}
                        <IonLabel>{STR_LoadCSV}</IonLabel>
                    </IonButton>
                </IonItem>
                <IonItem>
                    <IonLabel>{STR_Inventory} {STR_Images} : </IonLabel>
                    <IonButton onClick={e => loadCSV(e, LIST_INVENTORY, '')}>
                        <IonIcon icon={imageOutline} slot="icon-only"></IonIcon>
                        {isPlatform('hybrid') ? '' : <input multiple id="fileImg" type="file" accept="image/*" onChange={e => { setShowLoading(true); openImages(e.target.files).then(() => { setShowLoading(false); (closeButton.current as unknown as HTMLIonButtonElement).click() }) }} onClick={e => e.stopPropagation()} />}
                    </IonButton>
                </IonItem>
                <IonItem>
                    <IonLabel>{STR_Person} : </IonLabel>
                    <IonButton onClick={e => loadCSV(e, LIST_PERSON, '')}>
                        <IonIcon slot="start" icon={codeDownloadOutline} />
                        {isPlatform('hybrid') ? '' : <input id="file" type="file" accept=".csv" onChange={e => { setShowLoading(true); openContacts(e.target.files).then(() => { setShowLoading(false); (closeButton.current as unknown as HTMLIonButtonElement).click() }) }} onClick={e => e.stopPropagation()} />}
                        <IonLabel>{STR_LoadCSV}</IonLabel>
                    </IonButton>
                </IonItem>
                <IonItemDivider></IonItemDivider>

                <IonHeader><IonTitle>{STR_Export}</IonTitle></IonHeader>
                <IonItem>
                    <IonLabel>{STR_Inventory} : </IonLabel>
                    <IonButton onClick={e => saveFile(getCSV(LIST_INVENTORY))}>
                        <IonIcon slot="start" icon={downloadOutline} />
                        <IonLabel>{STR_DownloadCSV}</IonLabel>
                    </IonButton>
                </IonItem>
                <IonItem>
                    <IonLabel>{STR_Person} : </IonLabel>
                    <IonButton onClick={e => saveFile(getCSV(LIST_PERSON))}>
                        <IonIcon slot="start" icon={downloadOutline} />
                        <IonLabel>{STR_DownloadCSV}</IonLabel>
                    </IonButton>
                </IonItem>
                <IonItem>
                    <IonLabel>{STR_Purchases} : </IonLabel>
                    <IonButton onClick={e => saveFile(getCSV(LIST_PURCHASES))}>
                        <IonIcon slot="start" icon={downloadOutline} />
                        <IonLabel>{STR_DownloadCSV}</IonLabel>
                    </IonButton>
                </IonItem>
                <IonItem>
                    <IonLabel>{STR_Sales} : </IonLabel>
                    <IonButton onClick={e => saveFile(getCSV(LIST_SALES))}>
                        <IonIcon slot="start" icon={downloadOutline} />
                        <IonLabel>{STR_DownloadCSV}</IonLabel>
                    </IonButton>
                </IonItem>
            </IonContent>
        </>
    );

}