import React, { useState, useEffect, useRef } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonIcon, IonSearchbar, IonButton, IonLabel, IonSegment, IonSegmentButton, IonBadge, IonFooter, IonFab, IonFabButton, IonModal, IonFabList, IonMenuButton, IonButtons, IonAlert, useIonViewWillEnter } from '@ionic/react';
import { barcodeOutline, add, ellipsisHorizontal, newspaperOutline, warning, colorWandOutline, listCircleOutline, arrowUp, arrowDown, imagesOutline, addOutline } from 'ionicons/icons'
import './Tab1.css';
import { ItemList } from '../components/ItemList';
import { initItem, ALL, Item, BIAS, DEFAULTSHOP } from '../functions/DataModel';
import { data, fillCategoryCount, LIST_INVENTORY, LIST_CATALOG, LIST_CATALOGQ, categories, LIST_CAT, getFilteredList, getShowList, setAll, resizeAllImages, biasdata, LIST_BIASINVENTORY, setInventoryID, isBias, sale, inventoryID, makeBiasCategory, getBookCats } from '../functions/DataFunctions';
import ItemModal from '../components/ItemModal';
import { STR_Inventory, STR_Name, STR_Key, STR_SetPPrice, STR_CANCEL, STR_OK, STR_Profit, STR_ProductChange, STR_AddProduct, STR_Catalog, STR_OverridePPrice, STR_OutOfStock, STR_Category, STR_Resize, STR_Items } from '../lang/en';
import ListModal from '../components/ListModal';
import { profitBG, setProfit } from '../functions/Log';
import { BarcodeScanner } from '@ionic-native/barcode-scanner';
import { BOOK_ABENG, setItemsImage } from '../functions/LocalStorage';

const Tab1: React.FC = () => {

  var limit = 10;
  const [items, setItems] = useState({ cat: ALL, catdata: isBias() ? biasdata : data, page: 0, data: [] as Item[] })

  const [reload, setReload] = useState(false);
  const [showItem, setShowItem] = useState(-1);
  const [showYNAlert, setShowYNAlert] = useState(-1);

  const [mode, setMode] = useState(isBias() ? DEFAULTSHOP[0] : BIAS);

  const searchEl = useRef(null);
  const updateEl = useRef(null);
  const modalEl = useRef(null);

  const updateList = () => {
    fillCategoryCount();
    setReload(!reload);
  }

  const updateBiasList = () => {
    setReload(!reload);
  }

  function setList(cat: string, data: any[], next: number, listlimit: any[]) {
    if (listlimit.length <= limit) {
      setItemsImage(listlimit).then(() =>
        setItems({ cat: cat, catdata: data, page: next, data: listlimit }));
    }
    else setItems({ cat: cat, catdata: data, page: next, data: listlimit });
  }

  const openScanner = async () => {
    const data = await BarcodeScanner.scan();
    (searchEl.current as unknown as HTMLInputElement).value = data.text;
  };

  function searchItem(text: string) {
    let filteredData = getFilteredList(data, items.cat, text, 0);
    setList(items.cat, filteredData, 1, getShowList(filteredData, limit, 1))
  }

  function searchBiasItem(text: string) {
    let filteredData = getFilteredList(biasdata, items.cat, text, 0);
    setList(items.cat, filteredData, 1, getShowList(filteredData, limit, 1))
  }

  function getMaxPage() {
    let m = Math.floor(items.catdata.length / limit);
    if (items.catdata.length % limit > 0) m = m + 1;
    return m;
  }

  function setCategory(catid: string, filter: string) {
    let idata = inventoryID === LIST_INVENTORY ? data : biasdata;
    let filteredData = getFilteredList(idata, catid, filter, 0);
    setList(catid, filteredData, 1, getShowList(filteredData, limit, 1))
  }

  function setInventory(mode: string) {
    let catid = mode === BIAS ? 'ALL' : makeBiasCategory('ALL');
    let idata = mode === BIAS ? data : biasdata;
    let filteredData = getFilteredList(idata, catid, '', 0);
    setList(catid, filteredData, 1, getShowList(filteredData, limit, 1));

    setInventoryID(mode === BIAS ? LIST_INVENTORY : LIST_BIASINVENTORY);
    setMode(mode);
    if (mode === DEFAULTSHOP[0]) updateBiasList();
    else updateList();
  }

  function scroll(down: boolean) {
    let next = down ? items.page + 1 : items.page - 1;
    if (next > getMaxPage() || next < 0) next = 0;
    if (next > 0) {
      setList(items.cat, items.catdata, next, getShowList(items.catdata, limit, next))
    }
  }

  useEffect(() => {
    fillCategoryCount();
    setList(items.cat, items.catdata, 1, getShowList(items.catdata, limit, 1));
  }, []);

  useIonViewWillEnter(() => {
    setInventory(!isBias() && inventoryID === LIST_INVENTORY ? BIAS : DEFAULTSHOP[0]);
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{mode === DEFAULTSHOP[0] ? BIAS + ' ' + STR_Inventory : STR_Inventory}</IonTitle>
          {mode === DEFAULTSHOP[0] ?
            <>
              <IonLabel slot="end" className="marginr">{biasdata.length} {STR_Items}</IonLabel>
              <IonButton slot='end' onClick={e => setShowItem(LIST_BIASINVENTORY)}><IonIcon icon={addOutline} /></IonButton>
            </>
            :
            ''}
          <IonButton hidden={isBias()} slot="end" color="warning" className="marginr" onClick={e => { mode === DEFAULTSHOP[0] ? setInventory(BIAS) : setInventory(DEFAULTSHOP[0]) }}><img className="icon marginr" alt={mode.toUpperCase()} src={"assets/img/" + mode + ".png"}></img></IonButton>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar ref={searchEl} slot="start" placeholder={STR_Name + "/" + STR_Key} onIonChange={e => mode === BIAS ? searchItem(e.detail.value!) : searchBiasItem(e.detail.value!)} ></IonSearchbar>
          <IonButton slot="end" fill="clear" size="large" onClick={openScanner}>
            <IonIcon icon={barcodeOutline} />
          </IonButton>
        </IonToolbar>
        <IonToolbar>
          <IonSegment scrollable color="success" value={items.cat}>
            {categories.map((cat, idx) =>
              <IonSegmentButton key={idx} value={cat._id} layout="icon-top" onClick={e => setCategory(cat._id, (searchEl.current as unknown as HTMLInputElement).value)}>
                <IonBadge hidden={cat.count === 0}>{cat.count}</IonBadge>
                <IonIcon color={getBookCats(BOOK_ABENG).includes(cat._id) ? "tertiary" : ''} icon={cat.img} />
                <IonLabel>{cat.name}</IonLabel>
              </IonSegmentButton>
            )}
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonButton hidden={items.page === 1} expand='full' onClick={() => scroll(false)}><IonIcon icon={arrowUp} /></IonButton>
        <ItemList uRel={updateEl} cat={items.cat} data={items.data} type={mode === BIAS ? LIST_INVENTORY : LIST_BIASINVENTORY} sale={sale} />
        <IonButton hidden={items.page === getMaxPage() || items.catdata.length === 0} expand='full' onClick={() => scroll(true)}><IonIcon icon={arrowDown} /></IonButton>
        <IonModal ref={modalEl} isOpen={showItem !== -1} cssClass='printModal' swipeToClose={true} backdropDismiss={false} onDidDismiss={() => { setAll(true); setShowItem(-1) }}>
          {(showItem === LIST_CATALOG) || (showItem === LIST_CATALOGQ) || (showItem === LIST_CAT) ? <ListModal type={showItem} shop='' /> :
            <ItemModal uRef={updateEl} modalRef={modalEl} copy={false} item={initItem()} mode={showItem} />
          }
        </IonModal>
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton>
            <IonIcon icon={ellipsisHorizontal} />
          </IonFabButton>
          <IonFabList side="top">
            <IonFabButton color="primary" data-desc={STR_AddProduct} onClick={() => setShowItem(inventoryID)}><IonIcon icon={add}></IonIcon></IonFabButton>
            <IonFabButton color="primary" data-desc={STR_Category} onClick={() => setShowItem(LIST_CAT)}><IonIcon icon={listCircleOutline}></IonIcon></IonFabButton>
            <IonFabButton color="primary" data-desc={STR_OverridePPrice} onClick={() => setShowYNAlert(0)}><IonIcon icon={colorWandOutline}></IonIcon></IonFabButton>
              <IonFabButton color="primary" data-desc={STR_Resize} onClick={() => setShowYNAlert(1)}><IonIcon icon={imagesOutline}></IonIcon></IonFabButton>
            <IonFabButton color="primary" data-desc={STR_Catalog} onClick={() => setShowItem(LIST_CATALOG)}><IonIcon icon={newspaperOutline}></IonIcon></IonFabButton>
            <IonFabButton color="primary" data-desc={STR_OutOfStock} onClick={() => setShowItem(LIST_CATALOGQ)}><IonIcon icon={warning}></IonIcon></IonFabButton>
          </IonFabList>
        </IonFab>
        <IonButton hidden ref={updateEl} disabled onClick={e => mode === BIAS ? updateList() : updateBiasList()} />
      </IonContent>
      <IonFooter>
        <IonAlert isOpen={showYNAlert > -1} onDidDismiss={() => setShowYNAlert(-1)} header={showYNAlert === 0 ? STR_ProductChange : ''} message={showYNAlert === 0 ? STR_SetPPrice : STR_Resize}
          inputs={showYNAlert === 0 ? [
            {
              name: 'profit',
              placeholder: STR_Profit,
              value: profitBG,
            }]
            : []
          }
          buttons={[
            {
              text: STR_CANCEL,
              role: 'cancel',
              cssClass: 'secondary',
            }, {
              text: STR_OK,
              handler: (data) => {
                try {
                  if (showYNAlert === 0) {
                    setProfit(data.profit);
                  }
                  else if (showYNAlert === 1) {
                    resizeAllImages();
                  }
                } catch (error) {
                  alert(error);
                }
              }
            }
          ]}></IonAlert>
      </IonFooter>
    </IonPage>
  );
};

export default Tab1;